import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ModalComponent = (props) => {
  const { dividers = false, children, onClose, open, title, maxWidth, closeButton, persist = false, ...rest } = props;
  const closeModel = () => {
    onClose?.();
  };
  const handleModelClose = (event, reason) => {
    if (!persist) {
      onClose?.();
    } else {
      if (reason === 'backdropClick') {
        return;
      }
      onClose?.();
    }
  };
  return (
    <Dialog
      scroll="paper"
      sx={{ zIndex: 1300 }}
      onClose={handleModelClose}
      open={open}
      fullWidth
      maxWidth={maxWidth || 'md'}
      {...rest}
    >
      <DialogTitle sx={{ mt: 1, display: 'flex', direction: 'row', justifyContent: 'space-between' }}>
        {/* <div style={{ display: 'flex' }}> */}
        <Typography variant="h5" sx={{ fontWeight: 700, color: '#3c4555' }}>
          {title}
        </Typography>
        {closeButton && (
          <Button onClick={closeModel} sx={{ paddingRight: '0' }} color="inherit">
            <CloseIcon />
          </Button>
        )}
        {/* </div> */}
      </DialogTitle>
      <DialogContent dividers={dividers}>{children}</DialogContent>
    </Dialog>
  );
};

ModalComponent.propTypes = {
  dividers: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeButton: PropTypes.bool,
  persist: PropTypes.bool,
};

export default ModalComponent;
