import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  industries: [],
  isLoading: false,
  isAdding: false,
};
export const getIndustries = createAsyncThunk('industries/getIndustries', async (role, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'industries/get',
      method: 'GET',
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addIndustry = createAsyncThunk('industry/addIndustry', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'industries/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const editIndustry = createAsyncThunk('industry/editIndustry', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `industries/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
const industrySlice = createSlice({
  name: 'industry',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getIndustries.pending, (state, actions) => {
      state.isLoading = true;
    });
    builder.addCase(getIndustries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.industries = action.payload.data;
    });
    builder.addCase(getIndustries.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addIndustry.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addIndustry.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addIndustry.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editIndustry.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(editIndustry.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editIndustry.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export default industrySlice.reducer;
