import { Checkbox, FormControlLabel, FormHelperText, Typography, colors } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const ThemeCheckbox = ({
  label,
  onChange,
  name,
  checked,
  control,
  InputProps,
  error,
  helperText,
  FormHelperTextProps,
  type,
  checkboxProps,
  color,
  fontWeight,
  description,
  ...rest
}) => {
  if (control) {
    return (
      <>
        <Controller
          name={name || InputProps?.name}
          control={control}
          render={({ field }) => {
            const { onChange: handleChange, ...restFields } = field;
            return (
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': { fontSize: '0.9375rem', fontWeight: fontWeight || 'unset' },
                  ...(color && { color }),
                }}
                control={
                  // <Checkbox sx={{ color: error && 'error.main' }} {...field} checked={field?.value} />
                  <Checkbox
                    {...restFields}
                    {...checkboxProps}
                    sx={{ color: error && 'error.main' }}
                    onChange={onChange || handleChange}
                    checked={checked || Boolean(field?.value)}
                  />
                }
                label={label}
              />
            );
          }}
        />
        {description && (
          <Typography color="#636c72" ml={4} mb={2} variant="body2">
            {description}
          </Typography>
        )}
        {helperText && (
          <FormHelperText {...FormHelperTextProps} sx={{ color: error && 'error.main' }}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }
  return (
    <FormControlLabel
      {...rest}
      // sx={{ '& .MuiFormControlLabel-label': getLabelStyles }}
      control={<Checkbox {...InputProps} checked={checked} onChange={onChange} name={name} />}
      label={label}
    />
  );
};

ThemeCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  checked: PropTypes.bool,
  control: PropTypes.object,
  InputProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.any,
  FormHelperTextProps: PropTypes.object,
  checkboxProps: PropTypes.object,
};

export default ThemeCheckbox;
