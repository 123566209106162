// TabsComponent.js
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

const ReusableTabs = ({ tabs, selectedTab, handleChange }) => (
  <Tabs variant="scrollable" scrollButtons="auto" value={selectedTab} onChange={handleChange}>
    {tabs.map((tab, index) => (
      <Tab sx={{ p: '18px' }} key={index} label={tab.label} value={tab.value} />
    ))}
  </Tabs>
);

ReusableTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTab: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ReusableTabs;
