import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

// eslint-disable-next-line react/prop-types
const Timer = ({ timeout, time, setTime, restartTimer, ...props }) => {
  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [setTime, time]);

  const formatTime = (rawTime) => {
    const minutes = Math.floor(rawTime / 60);
    const seconds = rawTime % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <Box {...props}>
      <Typography variant="label">{formatTime(time)}</Typography>
    </Box>
  );
};

export default Timer;
