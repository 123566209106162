import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Container, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../components/status/index';
import { Types } from '../../types';
import { APP_ROUTER_BASE_URL } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { getCustomers } from '../customer/customerSlice';
import { getProjects } from './projectSlice';
import ProjectTable from './ProjectTable';

const ProjectListPage = () => {
  const dispatch = useDispatch();
  const { isGroupViewToggled, projects, isLoading } = useSelector((state) => state.project);
  const { customers } = useSelector((state) => state.customer);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = React.useState(null);
  const headCells = [
    {
      sortBy: !isGroupViewToggled ? 'name' : 'projectCount',
      id: 'name',
      label: 'Project Name',
      // disablePadding: true,
      align: 'left',
      pl: 4,
      minWidth: 200,
      width: '25%',
      titleCase: true,
    },
    {
      // id: 'customerID',
      id: !isGroupViewToggled ? 'customer' : 'customer',
      label: 'Customer',
      width: '20%',
    },
    {
      // id: 'startingDate',
      id: 'startDate',
      label: 'Start Date',
      renderElement: (cell) => <Typography>{cell?.startingDate}</Typography>,
      width: '15%',
    },
    {
      // id: 'endingDate',
      id: 'endDate',
      label: 'End Date',
      renderElement: (cell) => <Typography>{cell?.endingDate}</Typography>,
      width: '15%',
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '15%',
    },
  ];
  const groupHeadCells = [
    {
      id: 'projectCountLabel',
      // label: 'Name'
      width: '30%',
    },
    {
      id: 'customer',
      // label: 'Customer'
      width: '30%',
    },
    {
      id: '',
      // label: 'Customer'
    },
    {
      id: '',
      // label: 'Customer'
    },
    {
      id: '',
      // label: 'Customer'
    },
    {
      id: '',
      // label: 'Customer'
    },
    // {
    //   id: '',
    //   // label: 'Customer'
    // },
  ];
  useEffect(() => {
    dispatch(getProjects());
    dispatch(getCustomers());
  }, [dispatch]);
  const getGroupWiseProjects = (rawProjects, customerOptions) => {
    const groupProject = [];
    for (let i = 0; i < rawProjects.length; i += 1) {
      const formattedStartDate = dayjs(rawProjects[i]?.startDate).format('MMMM DD, YYYY');
      const formattedEndDate = dayjs(rawProjects[i]?.endDate).format('MMMM DD, YYYY');
      const customerId = rawProjects[i]?.customerID;
      const customer = customerOptions?.find((field) => field?._id === rawProjects[i]?.customerID)?.name;
      const existedProject = groupProject.findIndex((proj) =>
        proj?.projects?.some((project) => project?.customerID === customerId)
      );
      if (existedProject >= 0) {
        const obj = groupProject[existedProject];
        obj.projects.push({ ...rawProjects[i], startingDate: formattedStartDate, endingDate: formattedEndDate, customer });
        obj.projectCountLabel = `${obj.projectCount + 1}  Project`;
        obj.projectCount += 1;
      } else {
        const obj = {
          _id: `${rawProjects[i]?.customerID}_1`,
          projectCount: 1,
          projectCountLabel: '1  Project',
          projects: [{ ...rawProjects[i], startingDate: formattedStartDate, endingDate: formattedEndDate, customer }],
          customerID: rawProjects[i]?.customerID,
          customer,
        };
        groupProject.push(obj);
      }
    }
    return groupProject;
  };

  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [projectRows, setProjectRows] = useState([]);
  const groupWiseProject = useMemo(() => getGroupWiseProjects(projects, customers), [projects, customers]); // get this projects from slice
  const regularProjects = useMemo(
    () =>
      projects.reduce(
        (acc, customer) => {
          const formattedStartDate = dayjs(customer.startDate).format('MMMM DD, YYYY');
          const formattedEndDate = dayjs(customer.endDate).format('MMMM DD, YYYY');

          const customerWithDates = {
            ...customer,
            startingDate: formattedStartDate,
            endingDate: formattedEndDate,
            customer: customers.find((field) => field?._id === customer?.customerID)?.name || '',
          };

          if (customer.status === 'active') {
            acc.active.push(customerWithDates);
          } else if (customer.status === 'archive') {
            // acc.archieve.push(customer);
            acc.archieve.push(customerWithDates);
          }
          acc.default.push(customerWithDates);
          return acc;
        },
        { active: [], archieve: [], default: [] }
      ),
    [customers, projects]
  );
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
    }
  };
  useEffect(() => {
    let filteredRows;
    let filteredGroupRows;
    if (!isGroupViewToggled) {
      if (Status === Types.DEFAULT) {
        filteredRows = regularProjects?.default;
      } else if (Status === Types.ARCHIVED) {
        filteredRows = regularProjects?.archieve;
      } else {
        filteredRows = regularProjects?.active;
      }
      setProjectRows(filteredRows);
    } else if (groupWiseProject && groupWiseProject.length) {
      if (Status === Types.DEFAULT) {
        filteredGroupRows = groupWiseProject;
      } else {
        filteredGroupRows = groupWiseProject.map((group) => {
          const filteredProjects = group.projects.filter((project) => project?.status === Status);
          return {
            ...group,
            // projects: group.projects.filter((project) => project?.status === Status),
            projects: filteredProjects,
            projectCount: filteredProjects && filteredProjects.length,
            projectCountLabel: filteredProjects && `${filteredProjects.length} Project`,
          };
        });
      }
      setProjectRows(filteredGroupRows);
    }
  }, [Status, isGroupViewToggled, groupWiseProject, regularProjects]);
  const toolbarProps = {
    status: Status,
    onStatusChange,
  };
  const navigate = useNavigate();
  const handleRowClick = (event, row) => {
    if (row && row?._id) {
      navigate(`${APP_ROUTER_BASE_URL}project/${row?._id}`);
    }
  };
  const createProject = () => {
    navigate(`${APP_ROUTER_BASE_URL}project/create`);
  };
  const onEditClick = (event, row) => {
    if (row && row?._id) {
      navigate(`${APP_ROUTER_BASE_URL}project/edit/${row?._id}`);
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: 'projects/update/status',
          method: 'PATCH',
          data: { projectIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Project`,
            })
          );
          dispatch(getProjects());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `projects/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getProjects());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';

  return (
    <Container maxWidth="xxl">
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Projects</Typography>
        {role !== '3' && (
          <ThemeButton onClick={createProject} variant="contained" color="success" startIcon={<AddCircleIcon fontSize="small" />}>
            Create Project
          </ThemeButton>
        )}
      </Box>
      <ProjectTable
        headCells={headCells}
        maxHeight={670}
        handleRowClick={handleRowClick}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        onRowDelete={onDelete}
        // rows={groupWiseProject}
        filterKeys={['name', 'customer']}
        // rows={regularProjects.default}
        isLoading={isLoading}
        defaultRowsPerPage={-1}
        rows={projectRows}
        groupHeadCells={groupHeadCells}
        childKey="projects"
        customToolbar="project"
        toolbarProps={toolbarProps}
        stickyHeader
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
    </Container>
  );
};

export default ProjectListPage;
