import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import DashboardLayout from './layout/dashboard/DashboardLayout';
import CustomerListPage from './pages/customer';
import ReportsListPage from './pages/reports/ReportsListPage';
import ProjectListPage from './pages/projectList/ProjectListPage';
import SuperUserListPage from './pages/user/super/SuperUserListPage';
import AdminUserListPage from './pages/user/admin/AdminUserListPage';
import FieldUserListPage from './pages/user/field/FieldUserListPage';
import CategoriesListListPage from './pages/admin/category/CategoriesListListPage';
import SectorsListListPage from './pages/sector/SectorsListListPage';
import AreasListListPage from './pages/admin/area/AreasListListPage';
import RolesListPage from './pages/admin/role/RolesListPage';
import ElementsListPage from './pages/admin/element/ElementsListPage';
import GroupsListPage from './pages/admin/group/GroupsListPage';
import TagsListPage from './pages/admin/tag/TagsListPage';
import CertificationsListPage from './pages/admin/certification/CertificationsListPage';
import LocationsListPage from './pages/admin/location/LocationsListPage';
import HoursMapping from './pages/data/HoursMapping';
import SettingsPage from './pages/appSettings/SettingsPage';
import RequireAuth from './components/requireAuth';
import Unauthorized from './pages/unauthorized';
import LoginPage from './pages/login';
import { APP_ROUTER_BASE_URL } from './utils';
import AddCustomer from './pages/customer/addCustomer';
import SingleProjectPage from './pages/projectList/singleProject';
import SingleCustomer from './pages/customer/SingleCustomer';
import IndustryListPage from './pages/industry/IndustryListPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ConfirmPasswordPage from './pages/login/ConfirmPasswordPage';
import EditCustomer from './pages/customer/editCustomer';
import AddProjectPage from './pages/projectList/AddProjectPage';

export default function Router() {
  // const role = useSelector((state) => state.dashboard.role);

  const routes = useRoutes([
    {
      path: `${APP_ROUTER_BASE_URL}`,
      element: <DashboardLayout />,
      children: [
        { index: true, element: <Navigate to="project" /> },
        {
          element: <RequireAuth allowedRoles={['1']} />,
          children: [
            { path: 'customer', element: <CustomerListPage /> },
            { path: 'customer/create', element: <AddCustomer /> },
            { path: 'customer/edit/:id', element: <EditCustomer /> },
            { path: 'customer/view/:customerID', element: <SingleCustomer /> },
            { path: 'user/super', element: <SuperUserListPage /> },
            // { path: 'user/admin', element: <AdminUserListPage /> },
            // { path: 'user/field', element: <FieldUserListPage /> },
            { path: 'data/hours-mapping', element: <HoursMapping /> },
            { path: 'app-settings', element: <SettingsPage /> },
          ],
        },
        {
          element: <RequireAuth allowedRoles={['1', '2']} />,
          children: [
            { path: 'reports', element: <ReportsListPage /> },
            // { path: 'project', element: <ProjectListPage /> },
            { path: 'admin/category', element: <CategoriesListListPage /> },
            { path: 'admin/sector', element: <SectorsListListPage /> },
            { path: 'admin/industry', element: <IndustryListPage /> },
            { path: 'admin/element', element: <ElementsListPage /> },
            { path: 'user/admin', element: <AdminUserListPage /> },
            { path: 'user/field', element: <FieldUserListPage /> },
          ],
        },
        {
          element: <RequireAuth allowedRoles={['1', '2', '3']} />,
          children: [
            { path: 'project', element: <ProjectListPage /> },
            { path: 'project/:id', element: <SingleProjectPage /> },
            { path: 'project/create', element: <AddProjectPage /> },
            { path: 'project/edit/:id', element: <AddProjectPage /> },
          ],
        },
        // { path: 'customer', element: <CustomerListPage /> },
      ],
    },
    {
      path: `${APP_ROUTER_BASE_URL}unAuthorized`,
      element: <Unauthorized />,
    },
    {
      path: `${APP_ROUTER_BASE_URL}login`,
      element: <LoginPage />,
    },
    { path: 'reset-password', element: <ResetPasswordPage /> },
    { path: 'reset-password/:token', element: <ConfirmPasswordPage /> },
  ]);
  return routes;
}
