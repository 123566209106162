import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const OtpInput = ({ otp, setOtp, length, onComplete }) => {
  // const [otp, setOtp] = useState(Array(length).fill(''));
  const otpBoxReference = useRef([]);

  function handleChange(value, index, event) {
    if (otpBoxReference.current[index].value && otpBoxReference.current[index].value?.length <= 1) {
      const newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);
      if (value && index < length - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    } else if (otpBoxReference.current[index].value && otpBoxReference.current[index].value?.length > 1) {
      const newValue = value.toString();
      const lastCharacter = newValue?.slice(-1);
      const newArr = otp.map((otpValue, otpIndex) => (otpIndex === index + 1 ? lastCharacter : otpValue));
      setOtp(newArr);
      if (value && index < length - 1) {
        otpBoxReference.current[index + 1].focus();
      }
    }
  }

  function handleBackspaceAndEnter(e, index) {
    // if (e.key === 'Backspace' && !e.target.value && index > 0) {
    if (e.key === 'Backspace' && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      otpBoxReference.current[index - 1].focus();

      // otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Backspace' && index === 0) {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
    if (e.key === 'Enter' && e.target.value && index < length - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  return (
    <div>
      {otp.map((digit, index) => (
        <input
          type="number"
          key={index}
          value={digit}
          className="circle-input"
          // maxLength={1}
          onChange={(e) => handleChange(e.target.value, index, e)}
          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
          // eslint-disable-next-line no-return-assign
          ref={(reference) => (otpBoxReference.current[index] = reference)}
          // ref={otpBoxReference.current[index]}
        />
      ))}
    </div>
  );
};

export default OtpInput;
OtpInput.propTypes = {
  otp: PropTypes.array,
  setOtp: PropTypes.func,
};
