import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Typography } from '@mui/material';

const CheckboxGroup = ({
  onValueChange,
  config,
  fontWeight,
  control,
  label,
  name,
  options,
  row,
  LabelProps,
  disableOptions,
  ...rest
}) => {
  let a;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, value, onChange, ...inputProps }, formState: { errors } }) => {
        const handleChange = (item) => {
          const newArray = [...value];
          if (onValueChange) {
            onValueChange(onChange, value, item);
          } else {
            if (newArray.length > 0) {
              const index = newArray.findIndex((x) => x === item);

              // If theres no match add item to the array
              if (index === -1) {
                newArray.push(item);
              } else {
                newArray.splice(index, 1);
              }
            } else {
              newArray.push(item);
            }
            onChange(newArray);
          }
        };
        return (
          <div>
            <FormControl className={rest?.className}>
              {label && (
                <FormLabel
                  sx={{
                    fontSize: '0.9375rem',
                    '&.MuiFormLabel-root': { color: '#212B36 !important' },
                    color: '#212B36',
                    fontWeight: 600,
                  }}
                  {...LabelProps}
                  component="legend"
                >
                  {label}
                </FormLabel>
              )}
              {/* '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
              '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
              '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
              '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' }, */}
              <FormGroup
                sx={{
                  '& .MuiCheckbox-root': { py: 0.5 },
                  '& .body2': { my: 0.6 },
                }}
                row={row}
              >
                {options.map((option) => (
                  <FormControlLabel
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9375rem' } }}
                    control={
                      <Checkbox
                        {...inputProps}
                        checked={value?.some((checked) => checked === option[config.value])}
                        inputRef={ref}
                        onChange={() => handleChange(option[config.value])}
                        disabled={
                          rest?.disabled ||
                          (disableOptions && disableOptions?.length > 0 && disableOptions?.includes(option?.value))
                        }
                      />
                    }
                    // label={<p className="body2">{option[config.label]}</p>}
                    label={
                      <Typography
                        sx={{ py: 0.5, fontWeight: fontWeight || 'unset' }}
                        color={
                          rest?.disabled ||
                          (disableOptions && disableOptions?.length > 0 && disableOptions?.includes(option?.value))
                            ? '#919eabcc'
                            : option?.color
                        }
                        fontWeight="500"
                        variant="body2"
                      >
                        {option[config.label]}
                      </Typography>
                    }
                    key={option[config.value]}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <FormHelperText error variant="outlined">
              {errors?.[name]?.message || ' '}
            </FormHelperText>
          </div>
        );
      }}
    />
  );
};

CheckboxGroup.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  fontWeight: PropTypes.string,
  labelPlacement: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disableOptions: PropTypes.array,
  row: PropTypes.bool,
  onValueChange: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  config: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  LabelProps: PropTypes.any,
};

CheckboxGroup.defaultProps = {
  config: {
    label: 'label',
    value: 'value',
  },
};

export default CheckboxGroup;
