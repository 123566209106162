import styled from '@emotion/styled';
import { OutlinedInput, alpha } from '@mui/material';
import dayjs from 'dayjs';
import { get, has } from 'lodash';

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export const emptyRows = (page, rowsPerPage, arrayLength) => (page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0);

export const descendingComparator = (a, b, orderBy) => {
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

export const applyFilter = ({
  inputData,
  comparator,
  filterName,
  filterCallback,
  filterKeys,
  customerFilters,
  filterColumns,
  filterBy,
  sortBy,
  sortOrder,
  fromDate,
  toDate,
}) => {
  const { Industry = '', Sector = '' } = customerFilters || {};
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  if (sortBy && sortOrder) {
    comparator = getComparator(sortOrder, sortBy);
  }
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);
  if (toDate || fromDate) {
    const selectedFrom = dayjs(fromDate);
    const selectedTo = dayjs(toDate);
    const filteredByDateData = inputData.reduce((acc, project) => {
      const startDate = project?.startDate && dayjs(project.startDate);
      const endDate = project?.endDate && dayjs(project.endDate);
      if (toDate && fromDate) {
        // if (startDate?.isAfter(selectedFrom) && endDate?.isBefore(selectedTo)) {
        if (startDate?.isAfter(selectedFrom) && endDate?.isBefore(selectedTo)) {
          acc.push(project);
        }
      } else if (!toDate) {
        if (startDate?.isAfter(selectedFrom)) {
          acc.push(project);
        }
      } else if (!fromDate) {
        if (endDate?.isBefore(selectedTo)) {
          acc.push(project);
        }
      }
      return acc;
    }, []);
    inputData = filteredByDateData;
  }

  if (filterName || filterName === '') {
    if (filterKeys?.length > 0) {
      inputData = inputData?.filter((user) =>
        filterKeys.some((filt) =>
          get(user, filt) ? get(user, filt)?.toString().toLowerCase().indexOf(filterName.toLowerCase()) !== -1 : filterName === ''
        )
      );
    } else if (filterCallback) {
      inputData = inputData.filter((user) => filterCallback(user, filterName));
    } else {
      console.warn('For Developer => No filterkeys or filterCallback has been passed');
    }
  }
  if (Sector) {
    inputData = inputData.filter(
      // eslint-disable-next-line eqeqeq
      (user) => user.sector == Sector
    );
  }
  if (Industry) {
    inputData = inputData.filter((user) => user.industry === Industry);
  }

  const doesContains = (columns, key) => {
    if (key && typeof key === 'object') {
      return key.some((element) => columns.includes(element));
    }
    return columns.includes(key);
  };
  if (filterBy && inputData?.length) {
    inputData =
      filterColumns?.length > 0
        ? inputData.filter((user) =>
            Object.prototype.hasOwnProperty.call(user, filterBy) ? doesContains(filterColumns, user[filterBy]) : true
          )
        : inputData;
  }

  return inputData;
};

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 46,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
