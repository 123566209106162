/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import Cookies from 'universal-cookie';
import { APP_ROUTER_BASE_URL } from '.';
// import { useDispatch } from "react-redux";
// import Swal from "sweetalert2";
// eslint-disable-next-line import/no-unresolved
// import '../Styles.css';

const cookies = new Cookies();

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:8000/api/v1/',
  baseURL: 'https://server106.absolutebyte.co.uk/api/v1/',
  // baseURL: 'https://retime-webapp.herokuapp.com/',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  // const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const token = jwtToken || '';
  // const token = '';
  if (token) {
    // config.headers.Authorization = `Bearer ${token}`;
    config.headers.Authorization = token;
  }
  config.headers.studyType = 99;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.log('error in 401');
      cookies.remove('token', { path: '/' });
      window.location = `${APP_ROUTER_BASE_URL}login`;
      // Swal.fire({
      //   title: 'Session Expired!',
      //   text: 'Please Login Again',
      //   icon: 'error',
      //   confirmButtonText: 'Log in'
      // }).then(()=>{
      //   window.location = `${APP_ROUTER_BASE_URL}login`;
      // })
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
