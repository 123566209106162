/* eslint-disable arrow-body-style */
import { IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FormInput from '../../components/formComponents/FormInput';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import Iconify from '../../components/Iconify';
import { getHoursMapping, updateHoursMapping } from './hoursMappingSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';

const HoursNestedRow = ({ row, closeNestedRow, handleOpenBackdrop, handleCloseBackdrop }) => {
  const [hourLabel, setHourLabel] = useState('');
  const [timeslotLabel, setTimeslotLabel] = useState('');
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    if (row?._id) {
      const payload = {
        _id: row?._id,
        timeslotLabel,
        hourLabel,
      };
      if (payload) {
        handleOpenBackdrop();
        dispatch(updateHoursMapping(payload))
          .then((response) => {
            if (get(response, 'payload.success')) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Row Updated Successfully'),
                })
              );
              closeNestedRow(row);
              dispatch(getHoursMapping());
            } else {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
              dispatch(getHoursMapping());
            }
          })
          .finally(() => handleCloseBackdrop());
      }
    }
  };
  useEffect(() => {
    if (row) {
      setHourLabel(get(row, 'hourLabel'));
      setTimeslotLabel(get(row, 'timeslotLabel'));
    }
  }, [row]);

  if (row?._id) {
    return (
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        <TableCell padding="checkbox" />
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Stack direction="column" justifyContent="space-between">
            <Typography sx={{ fontWeight: 600 }} variant="body2">
              Hour
            </Typography>
            <Typography component="span" variant="body2">
              {row?.hour}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="column">
            <Typography sx={{ fontWeight: 600 }} variant="body2">
              Hour Label
            </Typography>
            <FormInput
              InputProps={{ value: hourLabel, onChange: (event) => setHourLabel(event.target.value) }}
              fullWidth={false}
              height={36}
              width={150}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="column">
            <Typography sx={{ fontWeight: 600 }} variant="body2">
              Time Slot Label
            </Typography>
            <FormInput
              InputProps={{ value: timeslotLabel, onChange: (event) => setTimeslotLabel(event.target.value) }}
              fullWidth={false}
              height={36}
              width={150}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack alignItems="center" justifyContent="center" direction="row">
            <BootstrapTooltip enterDelay={500} title="Save">
              <IconButton onClick={handleSubmit} sx={{ p: 0.5, color: 'primary.main' }}>
                <Iconify icon="tabler:check" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip enterDelay={500} title="Cancel">
              <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                <Iconify icon="tabler:x" />
              </IconButton>
            </BootstrapTooltip>
            {/* <ThemeButton size="small" loading={false} onClick={handleSubmit} variant="contained">
              Save
            </ThemeButton>
            <ThemeButton
              size="small"
              sx={{ mx: 1 }}
              type="button"
              onClick={() => closeNestedRow(row)}
              color="error"
              variant="outlined"
            >
              Cancel
            </ThemeButton> */}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Typography sx={{ ml: 5, color: '#4444448f' }} variant="subtitle2">
          No Elements found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

HoursNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  closeNestedRow: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
};

export default HoursNestedRow;
