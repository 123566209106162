import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { get, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import DataTable from '../../components/table';
import { Types } from '../../types';
import FilterModal from '../admin/element/FilterModal';
import { getLocations } from '../projectList/location/locationSlice';
import { getRoles } from '../projectList/roles/rolesSlice';
import { getFieldUsers } from '../user/field/fieldUserSlice';
import SingleStudy from './SingleStudy';
import { getStudies, markStudyReviewed, updateSingleStudy } from './studiesSlice';

dayjs.extend(AdvancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

const StudyPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const todayStart = dayjs().startOf('day').toISOString();
  const todayEnd = dayjs().endOf('day').toISOString();
  const { studies, singleStudyData, isLoading, singleStudy } = useSelector((state) => state.studies);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { singleProjectData } = useSelector((state) => state.project);
  const [sortBy, setSortBy] = useState('');
  const getTimezoneAbbreviation = (timeZone) => moment.tz(timeZone).zoneAbbr();
  const openFilter = (key, orderBy) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const renderOsInfo = (os) => {
    if (os === 'android') {
      return <Iconify icon="flat-color-icons:android-os" />;
    }
    if (os === 'ios') {
      return <Iconify icon="devicon:apple" />;
    }
    return (
      <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
        Other
      </Typography>
    );
  };
  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Study Name',
      // minWidth: 160,
      align: 'left',
      onClick: () => openFilter('name', 'name'),
    },
    {
      id: 'locationName',
      numeric: false,
      label: 'Location',
      onClick: () => openFilter('locationName', 'locationName'),
    },
    {
      id: 'userName',
      disablePadding: false,
      label: 'Field User',
      // minWidth: 160,
      onClick: () => openFilter('userName', 'userName'),
      // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
    },
    {
      id: 'studyStartTime',
      // numeric: false,
      // disablePadding: true,
      label: 'Date/Timing',
      // minWidth: 200,
      // align: 'left',
      renderElement: (cell) => (
        <Stack direction="column">
          <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
            {cell?.studyStartTime}
          </Typography>
          <Typography sx={{ fontSize: '0.9375rem' }} variant="captions">
            {cell?.startHours} - {cell?.endHours}
          </Typography>
        </Stack>
      ),
      onClick: () => openFilter('studyStart', 'studyStart'),
    },
    {
      id: 'osInfo',
      disablePadding: false,
      label: 'OS Info',
      // minWidth: 70,
      align: 'center',
      renderElement: (cell) => (
        <Stack direction="row" alignItems="center">
          {renderOsInfo(cell?.osInfo)}
          <Typography sx={{ fontSize: '0.9375rem', pl: 1 }} variant="captions">
            {cell?.version}
          </Typography>
        </Stack>
      ),
      onClick: () => openFilter('osInfo', 'osInfo'),
    },
    {
      id: 'elapsedTime',
      disablePadding: false,
      label: 'Elapsed Time',
      // minWidth: 150,
      onClick: () => openFilter('elapsedTime', 'elapsedTime'),
    },
    {
      id: 'inFo',
      disablePadding: false,
      label: 'Info',
    },
    {
      id: 'isFieldUserReviewed',
      // disablePadding: false,
      label: 'Field User Reviewed',
      // minWidth: 190,
      align: 'center',
      renderElement: (cell) =>
        cell?.isFieldUserReviewed && (
          <Box sx={{ pl: 0 }}>
            <CheckCircleOutlineOutlinedIcon sx={{ ml: 0 }} fontSize="small" color="success" />
          </Box>
        ),
    },
    {
      id: 'isReviewed',
      disablePadding: false,
      label: 'Reviewed',
      align: 'centre',
      renderElement: (cell) =>
        cell?.isReviewed && (
          <Box sx={{ pl: 1 }}>
            <CheckCircleOutlineOutlinedIcon sx={{ ml: 1 }} fontSize="small" color="success" />
          </Box>
        ),
    },
    {
      id: 'studyType',
      disablePadding: false,
      label: 'Study Type',
      // minWidth: 180,
      renderElement: (cell) => (
        <Typography sx={{ color: getStudyColor(cell.studyType) }} variant="subtitle2">
          {getStudyLabel(cell.studyType)}
        </Typography>
      ),
      onClick: () => openFilter('studyType', 'studyType'),
    },
  ];
  // Initial state with startDate and endDate
  const [dates, setDates] = useState({
    startDate: dayjs('2000-01-01').toISOString(),
    endDate: todayEnd,
  });
  const [filterBy, setFilterBy] = useState('');
  const [filterConfig, setFilterConfig] = useState({
    filterBy: '',
    filterColumns: [],
    sortOrder: '',
    sortBy: '',
  });
  const [filterOptions, setFilterOptions] = useState([]);
  const studynameOptions = useMemo(() => {
    if (studies && studies?.length) {
      return studies?.map((study) => ({ label: study?.name, value: study?.name }));
    }
    return [];
  }, [studies]);
  const fetchStudies = () => {
    dispatch(getStudies({ projectID, ...dates }));
  };
  useEffect(() => {
    if (projectID) {
      dispatch(getStudies({ projectID, ...dates }));
      dispatch(getFieldUsers({ projectID }));
      dispatch(getRoles({ projectID }));
      // dispatch(getTasks({ projectID }));
      // dispatch(getElements({ projectID, defaultEntry: false }));
      dispatch(getLocations({ projectID }));
    }
  }, [dates, dispatch, projectID]);

  const { fieldUsers } = useSelector((state) => state?.fieldUser);
  const { locations } = useSelector((state) => state.location);
  const { roles } = useSelector((state) => state?.roles);
  const LocationOptions = useMemo(() => {
    if (locations && !!locations?.length) {
      return locations.map((location) => ({ label: location?.name, value: location?._id }));
    }
    return [];
  }, [locations]);
  const locationFilterOptions = useMemo(() => {
    if (locations && !!locations?.length) {
      return locations.map((location) => ({ label: location?.name, value: location?.name }));
    }
    return [];
  }, [locations]);
  const roleOptions = useMemo(() => {
    if (roles && !!roles?.length) {
      return roles
        ?.filter((role) => role?.projectSetting?.studyTypes?.includes(2))
        ?.map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles]);

  const getInfoName = (studyType, study) => {
    if (studyType === 2) {
      return study?.roleName;
    }
    if (studyType === 1 && study?.roundDuration) {
      const convertedRoundDuration = dayjs.duration(study?.roundDuration).asMinutes();
      return `${convertedRoundDuration} minutes`;
    }
    return '';
  };
  const studyData = useMemo(
    () =>
      studies?.length > 0
        ? studies.map((study) => {
            // const location = locations.find((element) => element?._id === study.locationID)?.name;
            const location = get(study, 'locationID', '');
            const locationName = get(study, 'locationName', '');
            const user = get(study, 'userID', '');
            const fieldUser = get(study, 'userID', '');
            // const fieldUser = `${user?.name || ''}  ${user?.lastName || ''}`;
            const start = get(study, 'studyStartTime', '');
            const end = get(study, 'studyEndTime', '');
            const timeZone = get(study, 'timeZone', '') || singleProjectData?.timezone || 'Europe/London';
            // const studyStartTime = start && dayjs.tz(start, timeZone)?.format('DD/MM/YYYY zzz');
            const studyStartTime = start && moment.tz(start, timeZone).format(`DD/MM/YYYY z`);
            const studyStart = start && dayjs.tz(start, timeZone)?.format('DD/MM/YYYY');
            const startHours = start && dayjs.tz(start, timeZone)?.format('HH:mm');
            const studyTypes = get(study, 'projectSetting.studyTypes', []);
            const inFo = getInfoName(get(study, 'studyType'), study);
            const endHours = end && dayjs.tz(end, timeZone)?.format('HH:mm');
            const studyEndTime = end && dayjs.tz(end, timeZone)?.format('DD/MM/YYYY Z');
            const endTime = dayjs(end);
            const startTime = dayjs(start);
            const diffHours = endTime.diff(startTime, 'hour');
            const diffMinutes = endTime.diff(startTime, 'minute') % 60;
            const elapsedTime = `${diffHours} hrs ${diffMinutes} mins`;
            // return { ...study, location, fieldUser, elapsedTime };
            return {
              ...study,
              studyTypes,
              locationName,
              studyStart,
              location,
              fieldUser,
              studyEndTime,
              studyStartTime,
              startHours,
              endHours,
              elapsedTime,
              inFo,
            };
          })
        : [],
    [singleProjectData?.timezone, studies]
  );
  const dateOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      // Extract unique startTime values using Set to avoid duplicates
      const uniqueStartTimes = [...new Set(studyData.map((study) => study.studyStart))];

      // Map them to the required format
      return uniqueStartTimes.map((startTime) => ({ label: startTime, value: startTime }));
    }
    return [];
  }, [studyData]);
  const osInfoOptions = useMemo(() => {
    if (studies && studies?.length) {
      const uniqueOsInfo = [...new Set(studies.map((study) => study.osInfo))];
      return uniqueOsInfo.map((osInfo) => ({ label: startCase(osInfo), value: osInfo }));
    }
    return [];
  }, [studies]);
  const elapsedTimeOptions = useMemo(() => {
    if (studyData && studyData?.length) {
      const uniqueElapsedTime = [...new Set(studyData.map((study) => study.elapsedTime))];
      return uniqueElapsedTime.map((osInfo) => ({ label: osInfo, value: osInfo }));
    }
    return [];
  }, [studyData]);
  const fieldUserOptions = useMemo(() => {
    if (fieldUsers && fieldUsers?.length) {
      return fieldUsers
        ?.filter((user) => user?.status && user?.status === 'active')
        ?.map((fieldUser) => ({
          label: `${get(fieldUser, 'name', '')} ${get(fieldUser, 'lastname', '')}`,
          value: `${get(fieldUser, 'name', '')} ${get(fieldUser, 'lastname', '')}`,
        }));
    }
    return [];
  }, [fieldUsers]);

  const studyOptions = useMemo(
    () => [
      { label: 'Efficiency Study', value: 1 },
      { label: 'Role Study', value: 2 },
      { label: 'Activity Study', value: 3 },
      // { label: 'Predetermined Study', value: 4 },
    ],
    []
  );
  useEffect(() => {
    switch (filterBy) {
      case 'name':
        setFilterOptions(studynameOptions);
        break;
      case 'userName':
        setFilterOptions(fieldUserOptions);
        break;
      case 'locationName':
        setFilterOptions(locationFilterOptions);
        break;
      case 'studyStart':
        setFilterOptions(dateOptions);
        break;
      case 'osInfo':
        setFilterOptions(osInfoOptions);
        break;
      case 'elapsedTime':
        setFilterOptions(elapsedTimeOptions);
        break;
      case 'studyType':
        setFilterOptions(studyOptions);
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    filterBy,
    studynameOptions,
    fieldUserOptions,
    dateOptions,
    osInfoOptions,
    elapsedTimeOptions,
    studyOptions,
    LocationOptions,
    locationFilterOptions,
  ]);

  const handleRowClick = (event, row) => row && dispatch(updateSingleStudy(row));
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  const nestedRowProps = {
    LocationOptions,
    fetchStudies,
    roleOptions,
  };
  const handleReviewClicked = (selected, handleClearSelection) => {
    if (selected?.length > 0) {
      const studiesToReview = studyData
        .filter((study) => selected.includes(study._id))
        .map((study) => ({
          projectID,
          studyID: study?._id,
          studyType: study?.studyType,
        }));
      dispatch(markStudyReviewed(studiesToReview))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Reviewed Successfully'),
              })
            );
            dispatch(getStudies({ projectID, ...dates }));
            handleClearSelection();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const toolbarProps = {
    handleReviewClicked,
  };
  return (
    <div>
      {!singleStudy ? (
        <>
          <ModalComponent
            persist
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '600px !important',
                maxHeight: '700px !important',
              },
            }}
            title="Filter"
            open={isFilterOpen}
            onClose={setIsFilterOpen}
          >
            <FilterModal
              setFilterConfig={setFilterConfig}
              filterConfig={filterConfig}
              filterOptions={filterOptions}
              closeFilter={closeFilter}
              filterBy={filterBy}
              sortBy={sortBy}
              isMultiFilter
            />
          </ModalComponent>
          <DataTable
            isMultiFilter
            headCells={headCells}
            rows={studyData}
            nestedRowKey={Types.nestedRowTypes.STUDIES}
            nestedRowProps={nestedRowProps}
            toolbarProps={toolbarProps}
            isGlobalFilter
            // handleSelectionClick={handleSelectionClick}
            maxHeight={580}
            filterKeys={['name', 'locationName', 'userName']}
            customToolbar={Types.toolbarTypes.STUDIES}
            defaultRowsPerPage={10}
            handleRowClick={handleRowClick}
            filterColumns={filterConfig?.filterColumns}
            filterBy={filterConfig?.filterBy}
            sortOrder={filterConfig?.sortOrder}
            sortBy={filterConfig?.sortBy}
            hideCompactViewButton
            // onRowDelete={onDelete}
            setElementFilterConfig={setFilterConfig}
            // handleDragEnd={handleDragEnd}
            onEditClick={onEditClick}
            disableNestedRowClick
            hideNestedArrow
            // disableCheckbox
            isLoading={isLoading}
          />
        </>
      ) : (
        <SingleStudy study={singleStudy} projectID={projectID} customerID={customerID} />
      )}
    </div>
  );
};

export default StudyPage;
StudyPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
