export const questionTypeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Radio', value: 'radio' },
  { label: 'Checkbox', value: 'checkbox' },
  { label: 'Number', value: 'number' },
];

export const showQuestionTypes = {
  element: 'SHOW_IN_TASK_ELEMENT',
  studyEnd: 'END_OF_STUDY',
  dateTime: 'SHOW_ON_DAYS',
  duration: 'TIME_IN_STUDY',
  timeOfDay: 'TIME_OF_DAY',
};

export const studyTypes = {
  efficiencyStudy: 1,
  roleStudy: 2,
  activityStudy: 3,
};

export const checkboxStyling = {
  color: '#d1d5db',
  '&.Mui-checked': {
    color: 'primary.main',
  },
};

export const getLabelStyles = (type) => {
  if (!type) {
    return { fontSize: '0.9375rem' };
  }
  if (type === studyTypes.efficiencyStudy) {
    return { fontSize: '0.9375rem', color: '#f59d0f', fontWeight: 600 };
  }
  if (type === studyTypes.activityStudy) {
    return { fontSize: '0.9375rem', color: '#0ba0ef', fontWeight: 600 };
  }
  if (type === studyTypes.roleStudy) {
    return { fontSize: '0.9375rem', color: '#784fc2', fontWeight: 600 };
  }
  return { fontSize: '0.9375rem' };
};

export const top100Films = [
  { label: 'The Shawshank Redemption', value: 1994 },
  { label: 'The Godfather', value: 1972 },
  { label: 'The Godfather: Part II', value: 1974 },
  { label: 'The Dark Knight', value: 2008 },
  { label: '12 Angry Men', value: 1957 },
  { label: "Schindler's List", value: 1993 },
  { label: 'Pulp Fiction', value: 1994 },
];
