/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import dashboardSlice from '../layout/dashboard/dashboardSlice';
import superUserSlice from '../pages/user/super/superUserSlice';
import snackbarSlice from '../components/snackbar/snackbarSlice';
import projectSlice from '../pages/projectList/projectSlice';
import customerSlice from '../pages/customer/customerSlice';
// import taskSlice from '../pages/tasksPage/taskSlice';
// import studiesSlice from '../pages/studiesPage/studiesSlice';
// import customerSlice from '../pages/customer/customerSlice';
// import projectSlice from '../pages/projectList/projectSlice';
import loginSlice from '../pages/login/loginSlice';
import adminUserSlice from '../pages/user/admin/adminUserSlice';
import fieldUserSlice from '../pages/user/field/fieldUserSlice';
import industrySlice from '../pages/industry/industrySlice';
import sectorsSlice from '../pages/sector/sectorsSlice';
import categoriesSlice from '../pages/admin/category/categoriesSlice';
import hoursMappingSlice from '../pages/data/hoursMappingSlice';
import settingsSlice from '../pages/appSettings/settingsSlice';
import projectUsersSlice from '../pages/projectList/fieldUser/fieldUserSlice';
import locationSlice from '../pages/projectList/location/locationSlice';
import tagsSlice from '../pages/projectList/tags/tagsSlice';
import areasSlice from '../pages/projectList/areas/areaSlice';
import rolesSlice from '../pages/projectList/roles/rolesSlice';
import elementsSlice from '../pages/admin/element/elementsSlice';
import projectElementSlice from '../pages/projectList/elements/elementsSlice';
import remindersSlice from '../pages/reminder/remindersSlice';
import projectCategoriesSlice from '../pages/projectList/category/projectCategoriesSlice';
import taskSlice from '../pages/tasksPage/taskSlice';
import groupSlice from '../pages/groupRole/groupSlice';
import questionsSlice from '../pages/question/questionsSlice';
import studiesSlice from '../pages/studiesPage/studiesSlice';
import projectLogsSlice from '../pages/projectLogs/projectLogsSlice';
import dataSummarySlice from '../pages/projectList/dataSummary/dataSummarySlice';
import studyLogsSlice from '../pages/studieslogs/studyLogsSlice';
import notesSlice from '../pages/notes/notesSlice';

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    superUser: superUserSlice,
    adminUser: adminUserSlice,
    fieldUser: fieldUserSlice,
    industry: industrySlice,
    sectors: sectorsSlice,
    category: categoriesSlice,
    element: elementsSlice,
    hoursMapping: hoursMappingSlice,
    settings: settingsSlice,
    projectUsers: projectUsersSlice,
    location: locationSlice,
    tags: tagsSlice,
    areas: areasSlice,
    roles: rolesSlice,
    reminders: remindersSlice,
    questions: questionsSlice,
    projectElements: projectElementSlice,
    projectCategories: projectCategoriesSlice,
    tasks: taskSlice,
    groups: groupSlice,
    studies: studiesSlice,
    notes: notesSlice,
    customer: customerSlice,
    project: projectSlice,
    login: loginSlice,
    snackbar: snackbarSlice,
    projectLogs: projectLogsSlice,
    dataSummaries: dataSummarySlice,
    studyLogs: studyLogsSlice,
  },
});
