import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  addRoleLoading: false,
  roles: [],
};
export const getRoles = createAsyncThunk('roles/getRoles', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'roles/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addRole = createAsyncThunk('roles/addRole', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'roles/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateRole = createAsyncThunk('roles/updateRole', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `roles/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state, action) => {
      state.isLoading = true;
      state.roles = [];
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.roles = action.payload?.data;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addRole.pending, (state, action) => {
      state.addRoleLoading = true;
    });
    builder.addCase(addRole.fulfilled, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(addRole.rejected, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(updateRole.pending, (state, action) => {
      state.addRoleLoading = true;
    });
    builder.addCase(updateRole.fulfilled, (state, action) => {
      state.addRoleLoading = false;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.addRoleLoading = false;
    });
  },
});

export default rolesSlice.reducer;
