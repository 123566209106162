/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StatusIndicator from '../../components/status';
import Datatable from '../../components/table';
import { Types } from '../../types';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Location',
    align: 'left',
    titleCase: true,
  },
  {
    id: 'contactName',
    numeric: false,
    label: 'Contact Name ',
  },
  {
    id: '',
    disablePadding: false,
    label: 'Tag',
    // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
  },
  {
    id: 'status',
    // numeric: false,
    // disablePadding: true,
    label: 'Status',
    // align: 'left',
    renderElement: (cell) => <StatusIndicator cell={cell} />,
  },
];

const CustomerLocations = (props) => {
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const onStatusChange = (value) => {
    setStatus(value);
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Customer name',
  };
  const locations = useSelector((state) => state.customer.customerLocations);
  return (
    <div>
      <Datatable
        toolbarProps={toolbarProps}
        customToolbar={Types?.toolbarTypes?.CUSTOMER_LOCATIONS_TOOLBAR}
        headCells={headCells}
        rows={locations}
        filterKeys={['name', 'contactName']}
      />
    </div>
  );
};

CustomerLocations.propTypes = {};

export default CustomerLocations;
