import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  superUsers: [],
  isLoading: false,
  isUserAdding: false,
};
export const getSuperUsers = createAsyncThunk('superUser/getSuperUsers', async (role, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/getByRole',
      url: 'users/get',
      method: 'POST',
      data: role,
    });
    // localdata set
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addSuperUser = createAsyncThunk('superUser/addSuperUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'users/register',
      method: 'POST',
      data,
    });
    // localdata set
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const editSuperUser = createAsyncThunk('superUser/editSuperUser', async (data, { rejectWithValue }) => {
  if (get(data, '_id')) {
    try {
      const response = await axiosInstance({
        url: `users/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      // localdata set
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
export const superUserSlice = createSlice({
  name: 'superUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuperUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSuperUsers.fulfilled, (state, action) => {
      state.superUsers = get(action, 'payload.data', []);
      state.isLoading = false;
    });
    builder.addCase(getSuperUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addSuperUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(addSuperUser.fulfilled, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(addSuperUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(editSuperUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(editSuperUser.fulfilled, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(editSuperUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
  },
});
// export const {} = superUserSlice.actions;
export default superUserSlice.reducer;
