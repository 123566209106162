import { Container, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { useNavigate } from 'react-router-dom';
import AddCustomerStepper from './AddCustomerStepper';

const AddCustomer = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  return (
    <Container maxWidth="xxl">
      <Stack direction="ror" gap={2} alignItems="center">
        <IconButton
          size="small"
          sx={{
            color: 'primary.main',
            bgcolor: 'white',
            boxShadow: '0px 0px 6px rgba(0,0,0,0.2)',
            '&:hover': { bgcolor: '#f4f9ffcc' },
          }}
          onClick={handleBack}
        >
          <ArrowBackSharpIcon />
        </IconButton>
        <Typography variant="h4">Add Customer</Typography>
      </Stack>
      <AddCustomerStepper />
    </Container>
  );
};

export default AddCustomer;
