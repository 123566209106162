import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const GroupCheckbox = (props) => {
  const {
    required,
    error,
    variant = 'standard',
    label,
    InputProps,
    control = null,
    helperText,
    FormHelperTextProps,
    children,
    component = 'fieldset',
    LabelProps,
    ...rest
  } = props;

  return (
    <FormControl sx={{ mb: 2 }} {...rest} required={required} error={error} component={component} variant="standard">
      {label && (
        <FormLabel sx={{ fontSize: '0.9375rem' }} {...LabelProps} component="legend">
          {label}
        </FormLabel>
      )}
      {children}
      {helperText && (
        <FormHelperText {...FormHelperTextProps} sx={{ color: error && 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

GroupCheckbox.propTypes = {
  required: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.string,
  label: PropTypes.string,
  InputProps: PropTypes.object, // Replace with the correct type if possible
  control: PropTypes.object, // Replace with the correct type if possible
  helperText: PropTypes.string,
  FormHelperTextProps: PropTypes.object, // Replace with the correct type if possible
  children: PropTypes.node,
  component: PropTypes.string,
  LabelProps: PropTypes.object, // Replace with the correct type if possible
};

export default GroupCheckbox;
