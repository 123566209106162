import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { Types } from '../../types';

const StatusButtons = ({ size, status, onStatusChange, disableAll, ...rest }) => {
  const { ALL, DEFAULT, ACTIVE, ARCHIVED } = Types;
  const handleChange = (event, value) => {
    onStatusChange?.(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={status}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      {...rest}
      size={size}
    >
      {!disableAll && (
        <ToggleButton sx={{ borderRadius: 25, borderColor: 'primary.main' }} fullWidth value={DEFAULT}>
          All
        </ToggleButton>
      )}
      <ToggleButton sx={{ borderRadius: 25, borderColor: 'primary.main' }} fullWidth value={ACTIVE}>
        Active
      </ToggleButton>
      <ToggleButton sx={{ borderRadius: 25, borderColor: 'primary.main' }} fullWidth value={ARCHIVED}>
        Archived
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

StatusButtons.propTypes = {
  size: PropTypes.string, // Adjust the type as needed
  status: PropTypes.string.isRequired, // Adjust the type as needed
  onStatusChange: PropTypes.func.isRequired,
  disableAll: PropTypes.bool,
};

export default StatusButtons;
