import { Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getMainCategoryColor, getMainCategoryLabel } from '../../../utils';

const MainCategoryLabel = ({ category }) => {
  if (category) {
    return (
      <Typography color={getMainCategoryColor(category)} variant="overline">
        {getMainCategoryLabel(category)}
      </Typography>
    );
  }
  return null;
};

MainCategoryLabel.propTypes = {
  category: PropTypes.any,
};

export default MainCategoryLabel;
