import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const FormRadioGroup = ({ name, control, options, fontSize, ...rest }) => {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field} {...rest}>
            {options.map((option) => (
              <>
                <FormControlLabel
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9375rem' } }}
                  value={option?.value}
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: fontSize && fontSize,
                        },
                        py: '6px',
                      }}
                    />
                  }
                  label={option?.label}
                />
                {option?.description && (
                  <Typography sx={{ pl: 3, mb: 2, color: '#636c72' }} variant="body2">
                    {option?.description}
                  </Typography>
                )}
              </>
            ))}
          </RadioGroup>
        )}
      />
    );
  }
  return null;

  // return (
  // <RadioGroup
  //     aria-labelledby="demo-controlled-radio-buttons-group"
  //     name="controlled-radio-buttons-group"
  //     value={value}
  //     onChange={handleChange}
  // >
  //     {options.map((option) =>
  //         <FormControlLabel value={option?.value} control={<Radio />} label={option?.label} />
  //     )}
  // </RadioGroup>
  // )
};

FormRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  control: PropTypes.object, // Assuming it's a react-hook-form control object
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FormRadioGroup;
