import { yupResolver } from '@hookform/resolvers/yup';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box, Card, Container, DialogActions, Icon, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { get, has, startCase } from 'lodash';
import DataTable from '../../../components/table';
import ModalComponent from '../../../components/ModalComponent';
import { ThemeButton } from '../../../components/button';
import FormInput from '../../../components/formComponents/FormInput';
import { editSuperUserSchema, superUserSchema } from '../../../utils/schema';
import StatusIndicator from '../../../components/status';
import { Types } from '../../../types';
import { addSuperUser, editSuperUser, getSuperUsers } from './superUserSlice';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import axiosInstance from '../../../utils/axiosInstance';
import Iconify from '../../../components/Iconify';
import ConfirmDialog from '../../../components/ConfirmDialog';

const SuperUserListPage = () => {
  const headCells = [
    {
      id: 'fullName',
      label: 'Name',
      // disablePadding: true,
      align: 'left',
      // pl: 4,
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'email',
      label: 'Email',
      width: '20%',
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
      width: '15%',
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      name: '',
      lastname: '',
      email: '',
      password: '',
    },
    resolver: rowToEdit ? yupResolver(editSuperUserSchema) : yupResolver(superUserSchema),
  });
  const { register, handleSubmit, formState, reset, getValues, setValue } = form;
  const { errors } = formState;
  const [isModalopen, setisModalopen] = useState(false);
  const { superUsers, isLoading, isUserAdding } = useSelector((state) => state.superUser);
  const regularUsers = useMemo(
    () =>
      !!superUsers?.length &&
      superUsers.reduce(
        (acc, user) => {
          const fullUser = { ...user, fullName: `${startCase(user?.name)} ${startCase(user?.lastname)}` };
          if (user?.status === 'active') {
            acc.active.push(fullUser);
          } else if (user?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullUser);
          }
          acc.default.push(fullUser);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [superUsers]
  );
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [showPassword, setShowPassword] = useState(false);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [statusToChange, setStatusToChange] = useState('');
  const [userRows, setUserRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (regularUsers) {
      if (Status === DEFAULT) {
        setUserRows(regularUsers?.default);
      } else if (Status === ARCHIVED) {
        setUserRows(regularUsers?.archive);
      } else if (Status === ACTIVE) {
        setUserRows(regularUsers?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularUsers]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setUserRows(regularUsers.default);
      } else if (value === ACTIVE) {
        setUserRows(regularUsers.active);
      } else if (value === ARCHIVED) {
        setUserRows(regularUsers.archive);
      }
    }
  };
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search user name',
  };
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset({
      name: '',
      lastname: '',
      email: '',
      password: '',
    });
    setShowPassword(false);
    // if (rowToEdit) {
    setTimeout(() => {
      setRowToEdit('');
    }, 200);
    // }
  };
  useEffect(() => {
    dispatch(getSuperUsers({ role: 1 }));
  }, [dispatch]);
  const onSubmit = (data) => {
    if (data) {
      // role: 1, status: Types.ACTIVE
      if (rowToEdit) {
        dispatch(editSuperUser({ ...data, role: 1, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            dispatch(getSuperUsers({ role: 1 }));
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addSuperUser({ ...data, role: 1 }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              dispatch(getSuperUsers({ role: 1 }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };

  // useEffect(() => {
  //   dispatch(getSuperUsers({ role: 1 }));
  // }, [dispatch]);
  const onEditClick = async (event, row) => {
    // setRowToEdit((row?._id));
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `users/get/${row?._id}`,
          method: 'GET',
          data: { id: row?._id },
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
          setValue('lastname', get(response, 'data.data.lastname', ''));
          setValue('email', get(response, 'data.data.email', ''));
          // setValue('status', get(response, 'data.data.status', ''));
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'users/update/status',
          method: 'PATCH',
          data: { userIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} User`,
            })
          );
          dispatch(getSuperUsers({ role: 1 }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `users/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getSuperUsers({ role: 1 }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onConfirmStatusChange = () => {};
  const handleSelectionClick = (selected, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setSelectedUsers(selected);
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  return (
    <Container maxWidth="xxl">
      {/* <Typography variant="h4">Super Users</Typography> */}
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit User' : 'Create Super User'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <FormInput
              label="First Name"
              placeholder="Enter First Name"
              InputProps={{
                ...register('name'),
              }}
              error={!!errors?.name}
              helperText={errors?.name && errors?.name?.message}
            />
            <FormInput
              label="Last Name"
              placeholder="Enter Last Name"
              InputProps={{ ...register('lastname') }}
              error={!!errors?.lastname}
              helperText={errors?.lastname && errors?.lastname?.message}
            />
            <FormInput
              label="Email Address"
              placeholder="Enter Email Address"
              InputProps={{ ...register('email'), autoComplete: 'off' }}
              error={!!errors?.email}
              helperText={errors?.email && errors?.email?.message}
            />
            <FormInput
              label={rowToEdit ? 'Change Password' : 'Password'}
              placeholder="Enter Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                ...register('password'),
                // type: 'password',
                autoComplete: 'off',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      edge="end"
                    >
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors?.password}
              helperText={errors?.password && errors?.password?.message}
            />
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isUserAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Super User'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Super Users</Typography>
        <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<PersonAddAltIcon />}>
          Create Super User
        </ThemeButton>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        // handleArchieve={onConfirmArchieved}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        // rows={groupWiseProject}
        filterKeys={['fullName', 'email']}
        // rows={regularProjects.default}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        handleSelectionClick={handleSelectionClick}
        rows={userRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default SuperUserListPage;
