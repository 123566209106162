import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  logs: [],
  isLoading: false,
  isRestoring: false,
};
export const getStudyLogs = createAsyncThunk('studyLogs/getStudyLogs', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studylog/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const restoreStudyLog = createAsyncThunk('studyLogs/restoreStudyLog', async (payload, { rejectWithValue }) => {
  try {
    const url = `studylog/restore/${get(payload, 'ID')}`;
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: { studyID: payload?.studyID },
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

const studyLogsSlice = createSlice({
  name: 'studyLogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getStudyLogs.pending, (state, actions) => {
      state.isLoading = true;
      state.logs = [];
    });
    builder.addCase(getStudyLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = action.payload.data;
    });
    builder.addCase(getStudyLogs.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(restoreStudyLog.pending, (state, actions) => {
      state.isRestoring = true;
    });
    builder.addCase(restoreStudyLog.fulfilled, (state, action) => {
      state.isRestoring = false;
      state.logs = action.payload.data;
    });
    builder.addCase(restoreStudyLog.rejected, (state, action) => {
      state.isRestoring = false;
    });
  },
});

export default studyLogsSlice.reducer;
