import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  adminUsers: [],
  isLoading: false,
  isUserAdding: false,
};
export const getAdminUsers = createAsyncThunk('adminUsers/getAdminUsers', async (role, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      // url: 'users/getByRole',
      url: 'users/get',
      method: 'POST',
      data: role,
    });
    // localdata set
    const data = await response.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addAdminUser = createAsyncThunk('superUser/addAdminUser', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'users/register',
      method: 'POST',
      data,
    });
    // localdata set
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const editAdminUser = createAsyncThunk('superUser/editAdminUser', async (data, { rejectWithValue }) => {
  if (get(data, '_id')) {
    try {
      const response = await axiosInstance({
        url: `users/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      // localdata set
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminUsers.fulfilled, (state, action) => {
      state.adminUsers = get(action, 'payload.data', []);
      state.isLoading = false;
    });
    builder.addCase(getAdminUsers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addAdminUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(addAdminUser.fulfilled, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(addAdminUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(editAdminUser.pending, (state, action) => {
      state.isUserAdding = true;
    });
    builder.addCase(editAdminUser.fulfilled, (state, action) => {
      state.isUserAdding = false;
    });
    builder.addCase(editAdminUser.rejected, (state, action) => {
      state.isUserAdding = false;
    });
  },
});
// export const {} = superUserSlice.actions;
export default adminUserSlice.reducer;
