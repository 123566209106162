import React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { has } from 'lodash';

const ThemeSwitch = ({
  justifyContent,
  width,
  description,
  cursor,
  label,
  InputProps,
  control,
  checked,
  onChange,
  name = '',
  ...rest
}) => {
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      margin: 9,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#98cb4f',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    })
  );

  // return <FormControlLabel labelPlacement="start" control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} label="iOS style" />;
  if (control) {
    return (
      <FormGroup>
        <Typography component="div">
          <Stack
            width={width}
            direction="row"
            spacing={4}
            component="label"
            alignItems="center"
            justifyContent={justifyContent || 'space-between'}
          >
            <Box>
              <Typography fontSize="0.9375rem" fontWeight={400} variant="body1">
                {label}
              </Typography>
              {description && <Typography variant="body2">{description}</Typography>}
            </Box>
            <Box>
              {control ? (
                <Controller
                  control={control}
                  name={name}
                  render={({ field }) => (
                    <IOSSwitch
                      disabled={field.disabled}
                      name={field.fieldName}
                      onBlur={field.onBlur}
                      onChange={onChange || field.onChange}
                      inputRef={field.ref}
                      value={field.value}
                      // checked={checked && checked()}
                      checked={checked !== undefined ? checked() : field.value}
                    />
                  )}
                />
              ) : (
                <IOSSwitch />
              )}
            </Box>
          </Stack>
        </Typography>
      </FormGroup>
    );
  }
  return (
    <FormGroup sx={{ cursor: 'grab' }}>
      <Typography sx={{ cursor }} component="div">
        <Stack direction="row" spacing={4} component="label" alignItems="center" justifyContent="space-between">
          {label && (
            <Box>
              <Typography variant="body2">{label}</Typography>
            </Box>
          )}
          <Box>
            <IOSSwitch
              {...rest}
              name={name}
              onChange={onChange}
              // inputRef={field.ref}
              // value={field.value}
              checked={checked}
              // checked={checked !== undefined ? checked() : field.value}
            />
          </Box>
        </Stack>
      </Typography>
    </FormGroup>
  );
};

export default ThemeSwitch;

ThemeSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  InputProps: PropTypes.object,
  control: PropTypes.any,
  checked: PropTypes.func,
  onChange: PropTypes.func,
  cursor: PropTypes.string,
  justifyContent: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.any,
};
