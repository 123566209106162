import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  notes: [],
  isLoading: false,
};

export const getNotes = createAsyncThunk('notes/getNotes', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/get/notes';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    updateSingleStudy: (state, action) => {
      state.singleStudy = action.payload && action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state, action) => {
      state.isLoading = true;
      state.notes = [];
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notes = action.payload.data;
    });
    builder.addCase(getNotes.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default notesSlice.reducer;
