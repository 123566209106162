import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  categories: [],
  isLoading: false,
  isAdding: false,
};
export const getCategories = createAsyncThunk('category/getCategories', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: payload?.status ? `categories/get?status=${payload?.status}` : 'categories/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addCategory = createAsyncThunk('category/addCategory', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'categories/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
// eslint-disable-next-line consistent-return
export const editCategory = createAsyncThunk('category/editCategory', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `categories/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
const categorySlice = createSlice({
  name: 'category',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state, actions) => {
      state.isLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload.data;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addCategory.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addCategory.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addCategory.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editCategory.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(editCategory.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editCategory.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export default categorySlice.reducer;
