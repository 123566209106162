import { FormControl, FormHelperText, OutlinedInput } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const FormInput = (props) => {
  const {
    height,
    label,
    width,
    helperText,
    error,
    bgcolor,
    InputProps,
    InputLabelProps,
    FormHelperTextProps,
    placeholder,
    type,
    fontWeight,
    fontSize,
    fullWidth = true,
    mb,
    className,
    ...rest
  } = props;
  return (
    <FormControl className={className} sx={{ mb: mb || 2, width: width && width }} fullWidth={fullWidth}>
      {label && (
        //  eslint-disable-next-line jsx-a11y/label-has-for
        <label
          style={{ fontSize: fontSize || '0.8600rem', marginBottom: 8, fontWeight: fontWeight || 'unset' }}
          {...InputLabelProps}
        >
          {label}
        </label>
      )}
      <OutlinedInput
        {...rest}
        {...InputProps}
        // size='small'
        placeholder={placeholder || ''}
        fullWidth={fullWidth}
        error={error}
        type={type}
        autoComplete="off"
        inputProps={{ autoComplete: 'new-password' }}
        sx={{
          borderRadius: 25,
          height: height || 44,
          fontSize: '0.8600rem',
          bgcolor: bgcolor && bgcolor,
        }}
      />

      {helperText && (
        <FormHelperText {...FormHelperTextProps} sx={{ color: error && 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  InputProps: PropTypes.object, // Replace with the correct type if possible
  InputLabelProps: PropTypes.object, // Replace with the correct type if possible
  FormHelperTextProps: PropTypes.object, // Replace with the correct type if possible
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  bgcolor: PropTypes.string,
  mb: PropTypes.number,
  height: PropTypes.any,
  width: PropTypes.any,
  fontSize: PropTypes.any,
  fontWeight: PropTypes.any,
};

export default FormInput;
