import { Autocomplete, FormControl, FormHelperText, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

const MultiSelect = ({ placeholder, label, name, control, options, helperText, error, ...rest }) => {
  if (control) {
    return (
      <FormControl sx={{ mb: 2 }} fullWidth>
        {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
        {label && <label style={{ fontSize: '0.8600rem', marginBottom: '8px' }}>{label}</label>}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Autocomplete
              placeholder="select "
              options={options}
              // getOptionLabel={(option) => option.label ?? option}
              {...rest}
              id="controlled-demo"
              // value={value}
              value={options.filter((option) => field?.value?.includes(option.value))}
              ref={field.ref}
              onChange={(event, newValue) => {
                field.onChange(newValue?.map((value) => value?.value));
              }}
              ListboxProps={{ sx: { paddingY: '1px', fontSize: '0.86rem' } }}
              ChipProps={{ size: 'small', color: 'primary' }}
              sx={{
                '& .MuiOutlinedInput-root ': {
                  borderRadius: '25px',
                  padding: '6px 14px',
                },
              }}
              renderInput={(params) => (
                <TextField
                  error={error}
                  placeholder={placeholder}
                  sx={{ '& .MuiOutlinedInput-input ': { fontSize: '0.86rem', height: '1.4375em' } }}
                  {...params}
                />
              )}
            />
          )}
        />
        {helperText && <FormHelperText sx={{ color: error && 'error.main' }}>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
  return null;
};

MultiSelect.propTypes = {
  control: PropTypes.object,
  options: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

export default MultiSelect;
