import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActionButton, ThemeButton } from '../../components/button';
import Iconify from '../../components/Iconify';

const SingleStudyToolbar = (props) => {
  const {
    setFilterName,
    selected,
    handleSelectionClick,
    filterName,
    onFilterName,
    handleClearSelection,
    toolbarProps,
    visibleRows,
  } = props;
  const numSelected = selected?.length;
  const { onAddClick, handleEcElement, isEcValid } = toolbarProps;
  const { addEcLoading } = useSelector((state) => state.studies);
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null} */}

      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type="archive"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            variant="outlined"
            color="error"
            // onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete', handleClearSelection)}
          >
            {`Delete ${numSelected > 1 ? 'all' : ''}`}
          </ActionButton>
          {isEcValid && (
            <ActionButton
              type=""
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              disabled={!isEcValid(selected, visibleRows)}
              variant="outlined"
              loading={addEcLoading}
              onClick={() => handleEcElement && handleEcElement(selected, visibleRows, handleClearSelection)}
            >
              EC Element
            </ActionButton>
          )}
        </Stack>
      ) : (
        <Grid p={1} container justifyContent="space-between">
          <Grid item lg={3} md={4.5} xs={12}>
            <FormControl fullWidth>
              <OutlinedInput
                // size='small'
                sx={{
                  fontSize: '0.86rem',
                  // mt: '5px',
                  // mb: '16px',
                  borderRadius: 25,
                  lineHeight: '1.9375em',
                  '.MuiInputBase-input': {
                    height: '0.660em',
                    py: '15px',
                  },
                }}
                fullWidth
                // size='small'
                value={filterName}
                onChange={onFilterName}
                placeholder={get(toolbarProps, 'searchLable') || 'Search Studies'}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} xl={5} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ThemeButton
              onClick={() => {
                toolbarProps?.setFilterConfig({
                  filterBy: '',
                  filterColumns: [],
                  sortOrder: '',
                  sortBy: '',
                });
                setFilterName('');
              }}
              size="small"
              variant="contained"
            >
              Clear Filter
            </ThemeButton>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

SingleStudyToolbar.propTypes = {
  selected: PropTypes.array,
  visibleRows: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  setFilterName: PropTypes.func,
  toolbarProps: PropTypes.object,
};

export default SingleStudyToolbar;
