import { Button, CircularProgress, styled } from '@mui/material';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

export const ThemeButton = styled(LoadingButton)({
  color: 'primary.main',
  // padding: 8,
  // borderRadius: 4,
  borderRadius: '1.625rem',
  borderWidth: 2,
  fontWeight: 600,
  textTransform: 'none',
  '&:hover': { borderWidth: 2 },
});

export const ActionButton = (props) => {
  const { children, type, ...rest } = props;
  return (
    <ThemeButton {...rest} startIcon={type && (type === 'activate' ? <PlayArrowOutlinedIcon /> : <Inventory2OutlinedIcon />)}>
      {children}
    </ThemeButton>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};
