import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  studies: [],
  fieldUsers: [],
  locations: [],
  roles: [],
  singleStudy: null,
  singleStudyData: null,
  isLoading: false,
  singleStudyLoading: false,
  addEcLoading: false,
  markStudyLoading: false,
  updateStudyLoading: false,
  updateObservationLoading: false,
  addObservationLoading: false,
  isAdding: false,
  globalFilterName: '',
  globalOrder: 'asc',
  globalOrderBy: '',
};

export const getStudies = createAsyncThunk('studies/getStudies', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `studies/get?status=${get(payload, 'status', '')}` : 'studies/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addMissingObservations = createAsyncThunk('studies/addMissingObservations', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/obs';
    await axios
      .all(
        payload.map((data) =>
          axiosInstance({
            url,
            method: 'POST',
            data,
          })
        )
      )
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const responesThree = responses[2];
          // use/access the results
        })
      )
      .catch((errors) => {
        console.log(errors);
      });

    // const response = await axiosInstance({
    //   url,
    //   method: 'POST',
    //   data: payload,
    // });
    // const data = await response?.data;
    return null;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getSingleStudyData = createAsyncThunk('studies/getSingleStudyData', async (values, { rejectWithValue }) => {
  const { _id, ...rest } = values;
  const payload = rest;
  try {
    const url = `studies/get/${get(values, '_id', '')}`;
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateStudy = createAsyncThunk('studies/updateStudy', async (values, { rejectWithValue }) => {
  const { _id, ...rest } = values;
  const payload = rest;
  try {
    const url = `studies/update/${_id}`;
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const markStudyReviewed = createAsyncThunk('studyLogs/markStudyReviewed', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studylog/review';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addToObservation = createAsyncThunk('studyLogs/addToObservation', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/obs';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addEc = createAsyncThunk('studyLogs/addEc', async (payload, { rejectWithValue }) => {
  try {
    const url = 'studies/add/ec';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateObservation = createAsyncThunk('studies/updateObservation', async (values, { rejectWithValue }) => {
  const { _id, ...rest } = values;
  const payload = rest;
  try {
    const url = `studies/update/obs/${_id}`;
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

const studiesSlice = createSlice({
  name: 'studies',
  initialState,
  reducers: {
    updateSingleStudy: (state, action) => {
      state.singleStudy = action.payload && action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudies.pending, (state, action) => {
      state.isLoading = true;
      state.studies = [];
      state.singleStudy = null;
      state.singleStudyData = null;
    });
    builder.addCase(getStudies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.studies = action.payload.data;
    });
    builder.addCase(getStudies.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSingleStudyData.pending, (state, action) => {
      state.singleStudyLoading = true;
      state.singleStudyData = null;
    });
    builder.addCase(getSingleStudyData.fulfilled, (state, action) => {
      state.singleStudyLoading = false;
      state.singleStudyData = action.payload.data;
    });
    builder.addCase(getSingleStudyData.rejected, (state, action) => {
      state.singleStudyLoading = false;
    });
    builder.addCase(updateStudy.pending, (state, action) => {
      state.updateStudyLoading = true;
    });
    builder.addCase(updateStudy.fulfilled, (state, action) => {
      state.updateStudyLoading = false;
    });
    builder.addCase(updateStudy.rejected, (state, action) => {
      state.updateStudyLoading = false;
    });
    builder.addCase(updateObservation.pending, (state, action) => {
      state.updateObservationLoading = true;
    });
    builder.addCase(updateObservation.fulfilled, (state, action) => {
      state.updateObservationLoading = false;
    });
    builder.addCase(updateObservation.rejected, (state, action) => {
      state.updateObservationLoading = false;
    });
    builder.addCase(markStudyReviewed.pending, (state, action) => {
      state.markStudyLoading = true;
    });
    builder.addCase(markStudyReviewed.fulfilled, (state, action) => {
      state.markStudyLoading = false;
    });
    builder.addCase(markStudyReviewed.rejected, (state, action) => {
      state.markStudyLoading = false;
    });
    builder.addCase(addEc.pending, (state, action) => {
      state.addEcLoading = true;
    });
    builder.addCase(addEc.fulfilled, (state, action) => {
      state.addEcLoading = false;
    });
    builder.addCase(addEc.rejected, (state, action) => {
      state.addEcLoading = false;
    });
    builder.addCase(addToObservation.pending, (state, action) => {
      state.addObservationLoading = true;
    });
    builder.addCase(addToObservation.fulfilled, (state, action) => {
      state.addObservationLoading = false;
    });
    builder.addCase(addToObservation.rejected, (state, action) => {
      state.addObservationLoading = false;
    });
  },
});
export const { updateSingleStudy } = studiesSlice.actions;
export default studiesSlice.reducer;
