import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  tasks: [],
  isLoading: false,
  isAdding: false,
  isReorderLoding: false,
};
export const getTasks = createAsyncThunk('tasks/getTasks', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `tasks/get?status=${get(payload, 'status', '')}` : 'tasks/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addTask = createAsyncThunk('tasks/addTask', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'tasks/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateTask = createAsyncThunk('tasks/updateTask', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `tasks/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const reorderTasks = createAsyncThunk('tasks/reorder', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'tasks/reorder',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
const taskslice = createSlice({
  name: 'tasks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state, actions) => {
      state.isLoading = true;
      state.tasks = [];
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data && action.payload.data.length > 0) {
        state.tasks = action.payload.data;
      } else {
        state.tasks = [];
      }
    });
    builder.addCase(getTasks.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(reorderTasks.pending, (state, actions) => {
      state.isReorderLoding = true;
    });
    builder.addCase(reorderTasks.fulfilled, (state, action) => {
      state.isReorderLoding = false;
    });
    builder.addCase(reorderTasks.rejected, (state, action) => {
      state.isReorderLoding = false;
    });
    builder.addCase(addTask.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addTask.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addTask.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateTask.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
  reducers: {
    updateTasks: (state, action) => {
      if (action.payload && action.payload?.length > 0) {
        state.tasks = action.payload;
      }
    },
  },
});
export const { updateTasks } = taskslice.actions;
export default taskslice.reducer;
