import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import dayjs from 'dayjs';
import { FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

const FormDatePicker = ({
  range,
  value,
  onChange,
  language,
  control,
  name,
  formLabel,
  placeholder,
  InputLabelProps,
  onClear,
  rootStyles,
  showClear,
  height,
  mb,
  error,
  helperText,
}) => {
  const [open, setOpen] = React.useState(false);
  const clearField = (event) => {
    event.stopPropagation();
    onClear?.();
  };
  const getBorderRadius = () => {
    if (!range) {
      return '25px';
    }
    if (range === 'left') {
      return '25px 0px 0px 25px';
    }
    if (range === 'right') {
      return '0px 25px 25px 0px';
    }
    return '';
  };
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange: onValueChange, value: dateValue },
          fieldState: { invalid, isDirty }, // optional
          formState: { errors }, // optional, but necessary if you want to show an error message
        }) => {
          // dateValue = (dateValue && dateValue?.jsdate && dayjs(dateValue.jsdate)) || null;
          dateValue = dateValue && dayjs(dateValue);
          return (
            <FormControl
              className="custom-datepicker"
              sx={{ mb: 2, '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset !important' } }}
              fullWidth
            >
              {formLabel && (
                // eslint-disable-next-line jsx-a11y/label-has-for
                <label style={{ fontSize: '0.8600rem', marginBottom: 8 }} {...InputLabelProps}>
                  {formLabel}
                </label>
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{ padding: '0px', width: '100%' }} components={['DatePicker']}>
                  <DatePicker
                    renderInput={(params) => <TextField helperText={'helperText' || ''} {...params} error={error || false} />}
                    sx={{
                      width: '100%',
                      '.MuiInputBase-root': {
                        borderRadius: range ? getBorderRadius : '25px',
                        borderWidth: 1,
                        fontSize: '0.8600rem',
                        mt: '1px',
                        // height:'2em'
                      },
                      '.MuiInputBase-input': {
                        height: height || '2.0em',
                      },
                    }}
                    value={dateValue || null}
                    onChange={(date) => {
                      onValueChange(date?.isValid ? date?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '');
                    }}
                    format="DD/MM/YYYY"
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    // slots={{
                    //     actionBar: ActionList,
                    // }}
                    slotProps={{
                      field: { setOpen },
                      textField: {
                        error: !!errors?.expiryDate || !!errors?.expiryDate?.jsdate,
                        helperText: get(errors, 'expiryDate.message') || get(errors, 'expiryDate.jsdate.message'),
                        onClick: () => setOpen(true),
                        size: 'small',
                        ...(placeholder && { placeholder }),
                      },
                      layout: {
                        sx: {
                          '.MuiDateCalendar-root': {
                            // color: '#1565c0',
                          },
                        },
                      },
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {helperText && (
                <Typography color={error && 'error'} mt={0.5} mx={0.5} variant="caption">
                  {helperText}
                </Typography>
              )}
            </FormControl>
          );
        }}
      />
    );
  }
  return (
    <FormControl
      sx={{
        mb: mb || 2,
        width: '100%',
        '& .MuiTextField-root': { minWidth: 'unset !important' },
        '&.custom-datepicker .MuiTextField-root': { minWidth: 'unset !important' },
        ...rootStyles,
      }}
    >
      {formLabel && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label style={{ fontSize: '0.8600rem', marginBottom: 8 }} {...InputLabelProps}>
          {formLabel}
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer sx={{ padding: '0px', width: '100%' }} components={['DatePicker']}>
          <DatePicker
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: '100%',
              '.MuiInputBase-root': {
                borderRadius: getBorderRadius,
                borderWidth: 1,
                fontSize: '0.8600rem',
                mt: '1px',
                pr: 0,
                // height:'2em'
              },
              '.MuiInputBase-input': {
                height: height || '2.0em',
              },
            }}
            value={value}
            orientation="portrait"
            onChange={onChange}
            // format={language === 'en' ? 'MM/DD/YYYY' : 'YYYY/MM/DD'}
            format="DD/MM/YYYY"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            slotProps={{
              openPickerIcon: { sx: { opacity: 0 } },
              field: { setOpen },
              textField: {
                size: 'small',
                onClick: () => setOpen(true),
                InputProps: {
                  endAdornment: (value || showClear) && (
                    <InputAdornment position="start">
                      <ClearIcon
                        fontSize="small"
                        sx={{
                          '&:hover': {
                            bgcolor: '#00c0f314',
                          },
                          cursor: 'pointer',
                          borderRadius: '50%',
                        }}
                        onClick={(event) => clearField(event)}
                      />
                    </InputAdornment>
                  ),
                },
                ...(placeholder && { placeholder }),
              },
              layout: {
                sx: {
                  '.MuiDateCalendar-root': {
                    // color: '#1565c0',
                  },
                },
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </FormControl>
  );
};

FormDatePicker.propTypes = {
  range: PropTypes.string,
  helperText: PropTypes.string,
  mb: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.string,
  control: PropTypes.object,
  rootStyles: PropTypes.object,
  name: PropTypes.string,
  formLabel: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  onClear: PropTypes.func,
  showClear: PropTypes.bool,
  error: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormDatePicker;
