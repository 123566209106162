import { Box, Container, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../components/formComponents/FormInput';
import { resetPasswordSchema } from '../utils/schema';
import { resetPassword } from './login/loginSlice';
import { setSnackbar } from '../components/snackbar/snackbarSlice';
import { ThemeButton } from '../components/button';
import { APP_ROUTER_BASE_URL } from '../utils';
import { ReactComponent as RetimeSvg } from '../logo/retime_logo.svg';

const ResetPasswordPage = () => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });
  const {
    register,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = form;
  const dispatch = useDispatch();
  const { isResetPasswordLoading } = useSelector((state) => state.login);
  const onSubmit = async (values) => {
    if (values) {
      dispatch(resetPassword({ email: values?.email })).then((response) => {
        if (response.payload?.success === false && response.payload?.message) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: response.payload?.message || 'Something went wrong',
            })
          );
        } else if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: 'An email has been sent to your account',
            })
          );
          setIsOtpSent(true);
        }
      });
    }
  };
  const navigate = useNavigate();
  return (
    <Container maxWidth="xxl" sx={{ bgcolor: '#e5e5e5' }}>
      <Box sx={{ width: '100%', height: '100vh', display: 'flex' }}>
        {isOtpSent ? (
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              width: 446,
              height: 380,
              // marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flexStart',
              my: 'auto',
              maxWidth: 600,
              bgcolor: '#fff',
              mx: 'auto',
            }}
          >
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <RetimeSvg style={{ width: '99px', height: '72px', marginBottom: '24px' }} />
            </Box>
            <Typography component="h1" variant="h5">
              Well done!
            </Typography>
            <Typography component="p" variant="p">
              We've sent you an email. Follow the instructions to reset your password.
            </Typography>
            <Typography component="p" variant="p">
              Don't forget to check your Junk or Spam folder!
            </Typography>
            <ThemeButton
              onClick={() => navigate(`${APP_ROUTER_BASE_URL}login`)}
              loading={isResetPasswordLoading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              BACK TO LOGIN
            </ThemeButton>
          </Box>
        ) : (
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 6,
              width: 446,
              height: 380,
              // marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flexStart',
              my: 'auto',
              maxWidth: 600,
              bgcolor: '#fff',
              mx: 'auto',
            }}
          >
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <RetimeSvg style={{ width: '99px', height: '72px', marginBottom: '24px' }} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography component="h1" variant="h4">
                Reset Password
              </Typography>
              <Box noValidate sx={{ mt: 1 }}>
                <FormInput
                  label="Email Address"
                  placeholder="Enter Your Email"
                  InputProps={{ ...register('email') }}
                  error={!!errors?.email}
                  helperText={errors?.email && errors?.email?.message}
                />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Link component={RouterLink} to={`${APP_ROUTER_BASE_URL}login`} variant="body2">
                    Return to login
                  </Link>
                  <ThemeButton loading={isResetPasswordLoading} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Send link to reset
                  </ThemeButton>
                </Stack>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
