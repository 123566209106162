import { useEffect } from 'react';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/dist/simplebar.min.css';
import './App.css';
import { store } from './app/store';
import CustomSnackbar from './components/snackbar/CustomSnackbar';
import Router from './routes';
import ThemeProvider from './theme';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <CustomSnackbar />
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
