/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import CustomSelect from '../../components/select';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import { studiesSchema } from '../../utils/schema';
import { updateStudy } from './studiesSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';

const StudiesNestedRow = ({
  row,
  closeNestedRow,
  setNestedCollapsedRows,
  handleOpenBackdrop,
  handleCloseBackdrop,
  nestedRowProps,
}) => {
  const [hourLabel, setHourLabel] = useState('');
  const [timeslotLabel, setTimeslotLabel] = useState('');
  const { updateStudyLoading } = useSelector((state) => state.studies);
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      studyName: get(row, 'name', ''),
      locationID: get(row, 'locationID', ''),
      roleID: get(row, 'roleID', ''),
    },
    resolver: yupResolver(studiesSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  // const handleSubmit = async () => {};
  const locationOptions = get(nestedRowProps, 'LocationOptions', []);
  const roleOptions = get(nestedRowProps, 'roleOptions', []);
  const { id: projectID } = useParams();
  const onSubmit = (values) => {
    if (get(row, '_id')) {
      dispatch(
        updateStudy({
          _id: get(row, '_id'),
          studyType: get(row, 'studyType'),
          projectID,
          ...values,
        })
      )
        .then((response) => {
          if (response.payload?.success) {
            setNestedCollapsedRows([]);
            nestedRowProps?.fetchStudies();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Updated Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  if (row?._id) {
    return (
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        {/* <TableCell padding="checkbox" /> */}
        <TableCell padding="checkbox" />
        <TableCell colSpan={12} sx={{ verticalAlign: 'top' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DevTool control={control} />
            <Stack gap={4} direction="row">
              <Stack gap={2} width="40%" direction="row" justifyContent="space-between">
                <FormInput
                  label="Study Name"
                  placeholder="Enter study name"
                  InputProps={{
                    ...register('studyName'),
                  }}
                  error={!!errors?.studyName}
                  helperText={errors?.studyName && errors?.studyName?.message}
                />
                <Stack width="100%" direction="column">
                  <label style={{ fontSize: '0.8600rem' }}>Location</label>
                  <CustomSelect
                    name="locationID"
                    label="Select location"
                    control={control}
                    options={locationOptions}
                    error={!!errors?.locationID}
                    helperText={errors?.locationID && errors?.locationID?.message}
                  />
                </Stack>
                {row?.studyType === 2 && (
                  <Stack width="100%" direction="column">
                    <label style={{ fontSize: '0.8600rem' }}>Role</label>
                    <CustomSelect
                      name="roleID"
                      label="Select Role"
                      control={control}
                      options={roleOptions}
                      error={!!errors?.roleID}
                      helperText={errors?.roleID && errors?.roleID?.message}
                    />
                  </Stack>
                )}
              </Stack>
              <Stack alignItems="center" justifyContent="center" direction="row">
                <BootstrapTooltip enterDelay={500} title="Save">
                  {updateStudyLoading ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <IconButton type="submit" sx={{ p: 0.5, color: 'primary.main' }}>
                      <Iconify icon="tabler:check" />
                    </IconButton>
                  )}
                </BootstrapTooltip>
                <BootstrapTooltip enterDelay={500} title="Cancel">
                  <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                    <Iconify icon="tabler:x" />
                  </IconButton>
                </BootstrapTooltip>
              </Stack>
            </Stack>
          </form>
        </TableCell>
        {/* <TableCell>
          <Stack alignItems="center" justifyContent="center" direction="row">
            <BootstrapTooltip enterDelay={500} title="Save">
              <IconButton onClick={handleSubmit} sx={{ p: 0.5, color: 'primary.main' }}>
                <Iconify icon="tabler:check" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip enterDelay={500} title="Cancel">
              <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                <Iconify icon="tabler:x" />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
        </TableCell> */}
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Typography sx={{ ml: 5, color: '#4444448f' }} variant="subtitle2">
          No Elements found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

StudiesNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  closeNestedRow: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  setNestedCollapsedRows: PropTypes.func,
  nestedRowProps: PropTypes.object,
};

export default StudiesNestedRow;
