import { Container, Typography } from '@mui/material';
import React from 'react';

const ReportsListPage = () => (
  <Container maxWidth="xxl">
    <Typography variant="h4">Reports</Typography>
  </Container>
);

export default ReportsListPage;
