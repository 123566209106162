import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  logs: [],
  isLoading: false,
  isRestoring: false,
};
export const getProjectLogs = createAsyncThunk('projectLogs/getProjectLogs', async (payload, { rejectWithValue }) => {
  try {
    const url = 'projectlog/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const restoreLog = createAsyncThunk('projectLogs/restoreLog', async (payload, { rejectWithValue }) => {
  try {
    const url = `projectlog/restore/${get(payload, 'ID')}`;
    const response = await axiosInstance({
      url,
      method: 'PATCH',
      data: { projectID: payload?.projectID },
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

const projectLogsSlice = createSlice({
  name: 'projectLogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProjectLogs.pending, (state, actions) => {
      state.isLoading = true;
      state.logs = [];
    });
    builder.addCase(getProjectLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = action.payload.data;
    });
    builder.addCase(getProjectLogs.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(restoreLog.pending, (state, actions) => {
      state.isRestoring = true;
    });
    builder.addCase(restoreLog.fulfilled, (state, action) => {
      state.isRestoring = false;
      state.logs = action.payload.data;
    });
    builder.addCase(restoreLog.rejected, (state, action) => {
      state.isRestoring = false;
    });
  },
});

export default projectLogsSlice.reducer;
