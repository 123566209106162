import { Box, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const UserStats = ({ main, label, icon, value, ...rest }) => (
  <Stack gap={2} direction="row">
    <Card
      className="icon-card"
      sx={{
        width: 60,
        aspectRatio: '1:1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: main && 'primary.main',
      }}
    >
      {icon && <img src={icon} alt="clock" />}
    </Card>
    <Stack justifyContent="space-between">
      <Typography sx={{ fontWeight: 500, color: '#808285', fontSize: { xl: 17, lg: 12, sm: 11, xs: 11 } }}>
        {label && label}
      </Typography>
      <Typography sx={{ fontSize: 23, fontWeight: 600, color: '#3c4555' }}>{value && value}</Typography>
    </Stack>
  </Stack>
);

UserStats.propTypes = {
  icon: PropTypes.func.isRequired,
  main: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default UserStats;
