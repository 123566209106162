import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  locations: [],
  isLoading: false,
  isAdding: false,
};
export const getLocations = createAsyncThunk('locations/getLocations', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `locations/get?status=${get(payload, 'status', '')}` : 'locations/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addLocation = createAsyncThunk('locations/addLocation', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'locations/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateLocation = createAsyncThunk('location/updateLocation', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `locations/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
const locationSlice = createSlice({
  name: 'location',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state, actions) => {
      state.locations = [];
      state.isLoading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locations = action.payload.data;
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addLocation.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addLocation.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addLocation.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateLocation.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(updateLocation.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateLocation.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export default locationSlice.reducer;
