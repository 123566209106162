import { FormHelperText, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { ThemeButton } from '../../components/button';
import FormDatePicker from '../../components/formComponents/formDatePicker';

const Datetime = ({ disableDate, label, control, setValue, dateError }) => {
  const { append, remove } = useFieldArray({
    control,
    name: 'showOnDays',
  });
  const getDayObject = (date) => {
    const dayObject = date && {
      date: {
        year: date.year(),
        month: date.month() + 1, // Month is 0-indexed in dayjs
        day: date.date(),
      },
      jsdate: date.toISOString(),
      formatted: date.format('DD/MM/YYYY'),
      epoc: date.unix(),
    };
    // return dayObject || null;
    return date.toISOString() || null;
  };
  return (
    <Stack direction={{ md: 'row', sm: 'column' }} spacing={{ lg: 10, sm: 2, md: 10, xs: 2 }} alignItems="start">
      <Stack>
        {label && <Typography textAlign="start">{label}</Typography>}
        <Controller
          name="triggerTime"
          control={control}
          render={({ field }) => {
            field.value = field?.value
              ? dayjs().set('hour', field?.value.hours).set('minute', field?.value.minutes).set('second', 0)
              : null;
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{
                    '& .css-1o1ohr1-MuiTypography-root': {
                      textAlign: 'center',
                      // fontWeight: 'bold',
                      whiteSpace: 'pre',
                    },
                    '& .css-11a8txn-MuiStack-root': { flexDirection: 'column-reverse' },
                  }}
                  components={['MultiSectionDigitalClock']}
                >
                  <DemoItem label="   Hours    Minutes">
                    <MultiSectionDigitalClock
                      {...field}
                      timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                      sx={{
                        '& .css-14mb0cu-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item': {
                          minHeight: 'unset',
                        },
                        '& ul': {
                          // Your styles for the ul tag
                          minHeight: 'unset',
                          maxHeight: '100px',
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                        },
                        '& .MuiMultiSectionDigitalClock-root': {
                          minHeight: 'unset !important',
                          maxHeight: '100px',
                          '&::-webkit-scrollbar': {
                            width: '0.4em',
                          },
                          '&::-webkit-scrollbar-track': {
                            // background: '#f1f1f1',
                            borderRadius: '25px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            // backgroundColor: '#888',
                            backgroundColor: '#919eab3d',
                            borderRadius: '25px',
                          },
                          '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                          },
                        },
                        '& .MuiMenuItem-root': {
                          fontSize: '1rem',
                          lineHeight: 0.9,
                          // color: '#fff',
                          width: '40px',
                          marginInline: '8px',
                          height: '30px',
                          borderRadius: '4px',
                        },
                        '& .MuiTouchRipple-root': {
                          maxHeight: '30px !important' /* Use !important to ensure the override */,
                        },
                      }}
                      onChange={(value, state) => {
                        const timeValue = dayjs(value);
                        field.onChange({ hours: timeValue.hour(), minutes: timeValue.minute() });
                      }}
                      ampm={false}
                      views={['hours', 'minutes']}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            );
          }}
        />
      </Stack>
      {!disableDate && (
        <Stack>
          <Typography sx={{ mb: 1 }}>Trigger on</Typography>
          {/* {fields.length > 0 &&
          fields.map((field, index) => {
            const dateProps = register?.(`showOnDays.${index}.day`);
            return <FormDatePicker {...dateProps} value={dayjs(field.day.jsdate)} />;
          })} */}
          <Controller
            name="showOnDays"
            control={control}
            render={({ field }) => (
              <>
                {field.value.map((item, index) => (
                  <>
                    <FormDatePicker
                      key={index}
                      showClear
                      rootStyles={{ mb: 0.5 }}
                      inputRef={field.ref}
                      // value={item.day?.jsdate ? dayjs(item.day.jsdate) : null}
                      // value={item}
                      value={item ? dayjs(item) : null}
                      onClear={() => remove(index)}
                      onChange={(date) => {
                        const updatedDates = [...field.value];
                        const currentDate = getDayObject(date);
                        // updatedDates[index].day.jsdate = date.toISOString();
                        updatedDates[index] = currentDate;
                        // field.onChange(updatedDates);
                        setValue(`showOnDays[${index}]`, currentDate);
                      }}
                    />
                    {get(dateError, `${index}.message`) && (
                      <FormHelperText sx={{ color: get(dateError, `${index}.message`) && 'error.main' }}>
                        {get(dateError, `${index}.message`)}
                      </FormHelperText>
                    )}
                  </>
                ))}
              </>
            )}
          />
          <ThemeButton
            variant="outlined"
            size="small"
            sx={{ fontSize: '0.94rem', cursor: 'pointer', my: 1 }}
            fullWidth={false}
            // onClick={() => append({ day: null }, { focusIndex: 1 })}
            onClick={() => append('', { focusIndex: 1 })}
          >
            Add date
          </ThemeButton>
          {get(dateError, 'message') && (
            <FormHelperText sx={{ color: get(dateError, 'message') && 'error.main' }}>{get(dateError, 'message')}</FormHelperText>
          )}
        </Stack>
      )}
    </Stack>
  );
};

Datetime.propTypes = {
  control: PropTypes.any,
  register: PropTypes.any,
  setValue: PropTypes.any,
  label: PropTypes.string,
  disableDate: PropTypes.bool,
  dateError: PropTypes.bool,
};

export default Datetime;
