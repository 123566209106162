import { Icon } from '@iconify/react/dist/iconify';
import { Box, Card, Container, Grid, Skeleton, Typography } from '@mui/material';
import { get, has } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeButton } from '../../components/button';
import { ReusableTabs } from '../../components/reusableTabs';
import { APP_ROUTER_BASE_URL } from '../../utils';
import GroupRulesPage from '../groupRole';
import QuestionPage from '../question';
import RemindersPage from '../reminder/RemindersPage';
import StudyPage from '../studiesPage';
import TasksListPage from '../tasksPage/TasksListPage';
import OverViewPage from './OverViewPage';
import AreasPage from './areas/AreasPage';
import CategoriesPage from './category/CategoriesPage';
import DataSummaryPage from './dataSummary/DataSummaryPage';
import ElementsPage from './elements/ElementsPage';
import FieldUser from './fieldUser/FieldUser';
import LocationsPage from './location/LocationsPage';
import { getSingleProject } from './projectSlice';
import RolesPage from './roles/RolesPage';
import ProjectLogsPage from '../projectLogs/projectLogsPage';
import StudyLogsPage from '../studieslogs/studyLogsPage';
import NotesPage from '../notes/notesPage';

const SingleProjectPage = () => {
  const { singleProjectData, isSingleProjectLoading } = useSelector((state) => state.project);
  const tabs = [
    { label: 'Overviews', value: 'overview' },
    { label: 'Field Users', value: 'fieldUsers' },
    { label: 'Locations', value: 'location' },
    { label: 'Categories', value: 'categories' },
    { label: 'Areas', value: 'areas' },
    { label: 'Roles', value: 'roles' },
    { label: 'Elements', value: 'elements' },
    { label: 'Tasks', value: 'tasks' },
    { label: 'Studies', value: 'studies' },
    { label: 'Notes', value: 'notes' },
    { label: 'Data Summary', value: 'dataSummary' },
    { label: 'Groups', value: 'groupRules' },
    { label: 'Questions', value: 'question' },
    { label: 'Reminders', value: 'reminders' },
    { label: 'Logs', value: 'logs' },
    { label: 'Study Logs', value: 'studyLogs' },
  ];
  const predeterminedTabs = [
    { label: 'Overviews', value: 'overview' },
    { label: 'Field Users', value: 'fieldUsers' },
    { label: 'Areas', value: 'areas' },
    { label: 'Studies', value: 'studies' },
    { label: 'Notes', value: 'notes' },
    { label: 'Data Summary', value: 'dataSummary' },
    // { label: 'Roles', value: 'roles' },
    { label: 'Questions', value: 'question' },
    { label: 'Reminders', value: 'reminders' },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getSingleProject(id));
    }
  }, [dispatch, id]);
  const [selectedTab, setSelectedTab] = useState('overview');
  const onTabChange = (event, newValue) => setSelectedTab(newValue);
  const navigate = useNavigate();
  const navigateToEditPage = () => {
    if (id) {
      navigate(`${APP_ROUTER_BASE_URL}project/edit/${id}`);
    }
  };
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <OverViewPage projectID={singleProjectData?._id} />;
      case 'fieldUsers':
        return (
          <FieldUser
            projectID={singleProjectData?._id}
            customerID={singleProjectData?.customerID}
            customerName={singleProjectData?.customerName}
            project={singleProjectData}
          />
        );
      case 'tasks':
        return <TasksListPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studies':
        return <StudyPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'groupRules':
        return <GroupRulesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'question':
        return <QuestionPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'reminders':
        return <RemindersPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'categories':
        return <CategoriesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'areas':
        return <AreasPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'roles':
        return <RolesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'elements':
        return <ElementsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'dataSummary':
        return (
          <DataSummaryPage
            singleProjectData={singleProjectData}
            customerID={singleProjectData?.customerID}
            projectID={singleProjectData?._id}
          />
        );
      case 'notes':
        return <NotesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'logs':
        return <ProjectLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studyLogs':
        return <StudyLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'location':
        return <LocationsPage projectID={singleProjectData?._id} customerID={singleProjectData?.customerID} />;
      default:
        return null;
    }
  };
  return (
    <Container maxWidth="xxl">
      <Grid sx={{ width: '100%', mb: 2 }} alignItems="center" spacing={2} container justifyContent="space-between">
        <Grid item lg={3} xs={12}>
          <Typography variant="h4">
            {isSingleProjectLoading ? <Skeleton width={300} /> : has(singleProjectData, 'name') && get(singleProjectData, 'name')}
          </Typography>
        </Grid>
        <Grid sx={{}} item lg={9} md={8} xs={12}>
          <Grid justifyContent="flex-end" spacing={2} container>
            <Grid item lg={2.6} md={6} sm={6} xs={12}>
              <ThemeButton
                onClick={navigateToEditPage}
                size="small"
                startIcon={<Icon icon="eva:edit-fill" />}
                fullWidth
                variant="contained"
              >
                Edit Project
              </ThemeButton>
            </Grid>
            <Grid item lg={2.6} md={6} sm={6} xs={12}>
              <ThemeButton size="small" startIcon={<Icon width={16} icon="fa-solid:file-export" />} fullWidth variant="outlined">
                Get Statistics
              </ThemeButton>
            </Grid>
            <Grid item lg={2.6} md={6} sm={6} xs={12}>
              <ThemeButton size="small" startIcon={<Icon width={16} icon="fa-solid:file-export" />} fullWidth variant="outlined">
                Coverage Report
              </ThemeButton>
            </Grid>
            <Grid item lg={2.6} md={6} sm={6} xs={12}>
              <ThemeButton size="small" startIcon={<Icon width={16} icon="fa-solid:file-export" />} fullWidth variant="outlined">
                Import Project
              </ThemeButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card>
        <Card sx={{ borderBottom: '1px solid #eee' }}>
          <ReusableTabs
            tabs={singleProjectData?.studyTypes?.includes(4) ? predeterminedTabs : tabs}
            selectedTab={selectedTab}
            handleChange={onTabChange}
          />
        </Card>
        <Box sx={{ p: 2, bgcolor: '#f9fafb', width: '100%' }}>
          {isSingleProjectLoading ? <Skeleton variant="rectangular" width="100%" height={300} /> : renderTabContent()}
        </Box>
      </Card>
    </Container>
  );
};

export default SingleProjectPage;
