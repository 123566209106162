import { Navigate, Outlet, useLocation } from 'react-router-dom';
// import RolewiseNavigation from "./RolewiseNavigation";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { APP_ROUTER_BASE_URL } from '../../utils';

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  // const role = useSelector((state) => state.dashboard.role);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';
  // return (
  //     allowedRoles?.includes(role)
  //         ? <Outlet />
  //         // : <RolewiseNavigation role={role} />
  //     : <Navigate to={`${APP_ROUTER_BASE_URL}login`} state={{ from: location }} replace />
  // )
  if (!Role?.role) {
    return <Navigate to={`${APP_ROUTER_BASE_URL}login`} state={{ from: location }} replace />;
  }
  if (allowedRoles?.includes(role)) {
    return <Outlet />;
  }
  return <Navigate to={`${APP_ROUTER_BASE_URL}unAuthorized`} state={{ from: location }} replace />;
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RequireAuth;
