import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Container, IconButton, InputAdornment, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import { ReactComponent as RetimeSvg } from '../../logo/retime_logo.svg';
import { confirmPasswordSchema } from '../../utils/schema';
import { updatePassword } from './loginSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { APP_ROUTER_BASE_URL } from '../../utils';

const CoinformPasswordPage = () => {
  const form = useForm({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(confirmPasswordSchema),
  });
  const {
    register,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = form;
  const navigate = useNavigate();
  const { isUpdatePasswordLoading } = useSelector((state) => state.login);
  const { token = '' } = useParams();
  const dispatch = useDispatch();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onSubmit = (data) => {
    if (data && token) {
      const credentials = { ...data, token, isResetPassword: true };
      // const credentials = { password: data?.newPassword, confirmPassword: data?.newPassword, token, isResetPassword: true };
      dispatch(updatePassword(credentials))
        .then((response) => {
          if (response?.payload.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: response.payload.message || 'Password updated successfully',
              })
            );
            navigate(`${APP_ROUTER_BASE_URL}login`);
          } else if (get(response, 'payload.message')) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: response.payload.message || 'Something went wrong',
              })
            );
          }
          return '';
        })
        .catch((error) => console.log('error==>', error));
    }
  };

  return (
    <Container maxWidth="xxl" sx={{ bgcolor: '#e5e5e5' }}>
      <Box sx={{ width: '100%', height: '100vh', display: 'flex' }}>
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 2,
            width: 424,
            // marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            my: 'auto',
            maxWidth: 446,
            bgcolor: '#fff',
            mx: 'auto',
          }}
        >
          <RetimeSvg style={{ width: '99px', height: '72px', marginBottom: '24px' }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <Typography component="h1" variant="h5">
                Sign in
              </Typography> */}
            <Box noValidate sx={{ mt: 1, width: 360 }}>
              <Typography component="h1" variant="h4" mb={2}>
                Reset Password
              </Typography>
              <FormInput
                label="New Password"
                placeholder="Enter New Password"
                InputProps={{
                  ...register('newPassword'),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                        edge="end"
                      >
                        <Iconify icon={showNewPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={showNewPassword ? 'text' : 'password'}
                error={!!errors?.newPassword}
                helperText={errors?.newPassword && errors?.newPassword?.message}
              />
              <FormInput
                label="Confirm Password"
                placeholder="Repeat Password"
                InputProps={{
                  ...register('confirmPassword'),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        edge="end"
                      >
                        <Iconify icon={showConfirmPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={showConfirmPassword ? 'text' : 'password'}
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword && errors?.confirmPassword?.message}
              />
              <ThemeButton loading={isUpdatePasswordLoading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                Reset Password
              </ThemeButton>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default CoinformPasswordPage;
