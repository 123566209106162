import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Iconify from '../../components/Iconify';
import { ActionButton, ThemeButton } from '../../components/button';
import StatusButtons from '../../components/buttonGroup';

const TasksToolbar = (props) => {
  const { selected, handleSelectionClick, filterName, handleClearSelection, onFilterName, searchLable, toolbarProps, setPage } =
    props;
  const numSelected = selected?.length;
  const { onCancel, onSave } = toolbarProps;
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type="activate"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'activate')}
          >
            Activate all
          </ActionButton>
          <ActionButton
            type="archive"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            color="error"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archive')}
          >
            Archive all
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={1} container alignItems="center" justifyContent="space-between" spacing>
          <Grid item lg={5} xl={4} md={6} xs={12}>
            <Stack
              gap={2}
              width="100%"
              sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
              direction="row"
              alignItems="center"
            >
              <FormControl fullWidth>
                <OutlinedInput
                  // size='small'
                  sx={{
                    fontSize: '0.86rem',
                    // mt: '5px',
                    // mb: '16px',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  // size='small'
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || 'Search Tasks'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {!toolbarProps?.isReording ? (
                <ThemeButton
                  onClick={toolbarProps?.hadleReorderClick}
                  sx={{ width: { sm: '50%', xs: '40%' } }}
                  variant="outlined"
                >
                  Re Order Tasks
                </ThemeButton>
              ) : (
                <Stack direction="row" spacing>
                  <ThemeButton sx={{ px: 3 }} onClick={onSave} variant="contained">
                    save
                  </ThemeButton>
                  <ThemeButton onClick={onCancel} variant="outlined" color="error">
                    Cancel
                  </ThemeButton>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={4} lg={6}>
            <Stack
              gap={2}
              width="100%"
              sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
              direction="row"
              alignItems="center"
            >
              {/* <Typography>Status</Typography> */}
              <ThemeButton
                onClick={toolbarProps?.openModal}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ width: { sm: '50%', xs: '50%' } }}
                variant="outlined"
              >
                Create Task
              </ThemeButton>
              <StatusButtons
                status={toolbarProps?.status}
                onStatusChange={handleStatusChange}
                fullWidth
                size="small"
                // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                sx={{ width: '90%' }}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

TasksToolbar.propTypes = {
  selected: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  handleClearSelection: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  searchLable: PropTypes.string,
  setPage: PropTypes.func,
  toolbarProps: PropTypes.object,
};

export default TasksToolbar;
