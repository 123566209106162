import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  settings: null,
  isLoading: false,
  isUpdating: false,
};
export const getSettings = createAsyncThunk('setting/getSettings', async (role, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'settings/get',
      method: 'GET',
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateSettings = createAsyncThunk('setting/updateSettings', async (payload, { rejectWithValue }) => {
  const { _id, ...rest } = payload;
  if (_id) {
    try {
      const response = await axiosInstance({
        url: `settings/update/${_id}`,
        method: 'PATCH',
        data: rest,
      });
      const data = await response?.data;
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response?.data);
    }
  }
  return null;
});
const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state, actions) => {
      state.isLoading = true;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settings = action.payload.data;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateSettings.pending, (state, actions) => {
      state.isUpdating = true;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.isUpdating = false;
    });
    builder.addCase(updateSettings.rejected, (state, action) => {
      state.isUpdating = false;
    });
  },
});

export default settingsSlice.reducer;
