/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Card, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import ThemeSwitch from '../../components/switch';
import { getTasks, updateTask } from './taskSlice';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axiosInstance';
import { getElements } from '../projectList/elements/elementsSlice';

const TasksNestedRow = ({ row, nestedRowProps }) => {
  const { setValue, rowToEdit, setRowToEdit, openElementModal, setSelectedRowToDelete, setDeleteModelOpen } = nestedRowProps;
  const [controllingElement, setControllingElement] = useState(get(row, 'projectSetting.controllingElementID'));
  const [isReording, setIsReording] = useState(false);
  const [sortedElements, setSortedElements] = useState([]);
  const [elementRows, setElementRows] = useState([]);
  const params = useParams();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const rowElements = useMemo(
    () => (row?.elements && !!row?.elements?.length ? row?.elements?.map((element) => ({ ...element, id: element?._id })) : []),
    [row?.elements]
  );
  useEffect(() => {
    setElementRows(rowElements);
  }, [rowElements]);

  const dispatch = useDispatch();

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      // setActiveId(active.id);
      // setOverId(over.id);
      // here we want to do temporary sorting

      const activeTempIndex = elementRows.findIndex((element) => element._id === active.id);
      const overTempIndex = elementRows.findIndex((element) => element._id === over.id);
      const activeIndex =
        sortedElements?.length > 0
          ? sortedElements.findIndex((element) => element._id === active.id)
          : elementRows?.findIndex((element) => element._id === active.id);
      const overIndex =
        sortedElements?.length > 0
          ? sortedElements.findIndex((element) => element._id === over.id)
          : elementRows?.findIndex((element) => element._id === over.id);

      const sortedTempArray = arrayMove(elementRows, activeTempIndex, overTempIndex);
      setElementRows(sortedTempArray);
      const sortedArray =
        sortedElements?.length > 0
          ? arrayMove(sortedElements, activeIndex, overIndex)
          : arrayMove(rowElements, activeIndex, overIndex);

      setSortedElements(sortedArray);
    }
  };
  const handleSaveOrder = () => {
    setIsReording(!isReording);
    if (row && params?.id) {
      const payload = {
        _id: row?._id,
        name: row?.name,
        projectID: params?.id,
        customerID: row?.projectSetting?.customerID,
        controllingElementID: row?.projectSetting?.controllingElementID,
        studyTypes: row?.projectSetting?.studyTypes,
        groups: row?.projectSetting?.groups,
        elements:
          sortedElements && sortedElements?.length
            ? sortedElements?.map((el) => el?._id)
            : get(row, 'projectSetting.elements', []),
      };
      dispatch(updateTask(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
              })
            );
            dispatch(getTasks({ projectID: params?.id }));
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const cancelReorder = () => {
    setElementRows(rowElements);
    setSortedElements([]);
    setIsReording(!isReording);
  };
  const controllingElment = useMemo(
    () =>
      row.projectSetting.controllingElementID
        ? elementRows.find((el) => el?._id === row.projectSetting.controllingElementID)?.name
        : '',
    [elementRows, row]
  );
  const controlElementChange = async (elementID) => {
    if (row && params?.id) {
      const payload = {
        _id: row?._id,
        name: row?.name,
        projectID: params?.id,
        customerID: row?.projectSetting?.customerID,
        controllingElementID: elementID,
        studyTypes: row?.projectSetting?.studyTypes,
        groups: row?.projectSetting?.groups,
        elements:
          sortedElements && sortedElements?.length
            ? sortedElements?.map((el) => el?._id)
            : get(row, 'projectSetting.elements', []),
      };
      dispatch(updateTask(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
              })
            );
            dispatch(getTasks({ projectID: params?.id }));
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const onEditElement = async (event, elementRow) => {
    if (elementRow?._id) {
      setRowToEdit(elementRow?._id);
      const elementToEdit = elementRows.find((element) => element?._id === elementRow?._id);
      if (elementToEdit) {
        setValue('name', get(elementToEdit, 'name', ''));
        setValue('categoryID', get(elementToEdit, 'projectSetting.categoryID', ''));
        if (get(elementToEdit, 'projectSetting.unitOfMeasure')) {
          setValue('unitOfMeasure', get(elementToEdit, 'projectSetting.unitOfMeasure', ''));
        }
        if (get(elementToEdit, 'projectSetting.rating')) {
          setValue('rating', get(elementToEdit, 'projectSetting.rating', ''));
        }
        if (get(elementToEdit, 'projectSetting.studyTypes')) {
          setValue('studyTypes', get(elementToEdit, 'projectSetting.studyTypes', ''));
        }
        if (get(elementToEdit, 'projectSetting.contingencyAllowance')) {
          setValue('contingencyAllowance', get(elementToEdit, 'projectSetting.contingencyAllowance', ''));
        }
        if (get(elementToEdit, 'projectSetting.relaxationAllowance')) {
          setValue('relaxationAllowance', get(elementToEdit, 'projectSetting.relaxationAllowance', ''));
        }
        if (get(elementToEdit, 'projectSetting.count')) {
          setValue('count', get(elementToEdit, 'projectSetting.count', ''));
        }
        if (get(elementToEdit, 'projectSetting.type')) {
          setValue('type', get(elementToEdit, 'projectSetting.type', ''));
        }
      }
      openElementModal(row);
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleDeleteElementClick = (event, element) => {
    setSelectedRowToDelete(element?._id);
    setDeleteModelOpen(true);
  };
  if (!rowElements || !rowElements?.length > 0) {
    return (
      <TableRow>
        <TableCell align="center" colSpan={12}>
          <Typography variant="body2" sx={{ color: '#84909C' }}>
            No elements found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Grid container spacing={2} p={2}>
          <Grid xs={4.1} container spacing={2}>
            <Grid item xs={12} p={2}>
              <Box>
                <Typography variant="subtitle2">Control</Typography>
                <Typography variant="body2">{controllingElment}</Typography>
                <ThemeButton onClick={() => openElementModal(row)} sx={{ mt: 2 }} variant="outlined">
                  Create Element
                </ThemeButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={7.9}>
            <Grid display="flex" alignItems="flex-start" container>
              <Grid item lg={4}>
                <Typography
                  sx={{
                    color: '#3c4555',
                    fontSize: ' 0.9rem',
                    fontWeight: 700,
                  }}
                >
                  Controlling Element
                </Typography>
              </Grid>
              <Grid item lg={8}>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                  <Typography
                    sx={{
                      color: '#3c4555',
                      // verticalAlign: 'middle',
                      fontSize: ' 0.9rem',
                      fontWeight: 700,
                    }}
                  >
                    Elements
                  </Typography>
                  {!isReording ? (
                    <ThemeButton onClick={() => setIsReording(!isReording)} variant="outlined">
                      Re-order elements
                    </ThemeButton>
                  ) : (
                    <Stack direction="row" spacing>
                      <ThemeButton sx={{ px: 3 }} onClick={handleSaveOrder} variant="contained">
                        save
                      </ThemeButton>
                      <ThemeButton onClick={cancelReorder} variant="outlined" color="error">
                        Cancel
                      </ThemeButton>
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <DndContext
              modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
              // autoScrol={false}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={rowElements} strategy={verticalListSortingStrategy}>
                <Box py={1.6} sx={{ overflowY: 'auto' }}>
                  {elementRows &&
                    elementRows?.length > 0 &&
                    elementRows?.map((element) => {
                      if (!isReording) {
                        return (
                          <Grid alignItems="center" container key={element?.id}>
                            <Grid item xs={4.1}>
                              <ThemeSwitch
                                checked={element?._id === controllingElement}
                                // onChange={(event, value) => !!value && setControllingElement(element?._id)}
                                onChange={(event, value) => !!value && controlElementChange(element?._id)}
                              />
                            </Grid>
                            <Grid item xs={7.9}>
                              <Card sx={{ p: 2, my: 1 }}>
                                <Stack justifyContent="space-between" alignItems="center" direction="row">
                                  <Typography variant="body2">{element?.name}</Typography>
                                  <Stack spacing direction="row">
                                    <BootstrapTooltip enterDelay={500} title="Edit">
                                      <IconButton onClick={(event) => onEditElement(event, element)} sx={{ p: 0.5 }}>
                                        <Iconify icon="iconamoon:edit-duotone" />
                                      </IconButton>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip enterDelay={500} title="Remove">
                                      <IconButton
                                        sx={{ p: 0.5, color: 'error.main' }}
                                        // onClick={(event) => handleDeleteClick(event, row)}
                                        onClick={(event) => handleDeleteElementClick(event, element)}
                                      >
                                        <Iconify icon="ant-design:delete-twotone" />
                                      </IconButton>
                                    </BootstrapTooltip>
                                    {/* <BorderColorOutlinedIcon fontSize="small" />
                                    <DeleteOutlineOutlinedIcon fontSize="small" /> */}
                                  </Stack>
                                </Stack>
                              </Card>
                            </Grid>
                          </Grid>
                        );
                      }
                      return (
                        <SortableElement sx={{ cursor: 'grab !important' }} id={element?.id} key={element?.id}>
                          <Grid item xs={4.1}>
                            <ThemeSwitch
                              checked={element?._id === controllingElement}
                              // onChange={(event, value) => console.log('eventtttt', event)}
                              cursor="grab"
                            />
                          </Grid>
                          <Grid item xs={7.9}>
                            <Card sx={{ px: 2, py: 1, my: 1 }}>
                              <Stack justifyContent="space-between" alignItems="center" direction="row">
                                <Typography variant="body2">{element?.name}</Typography>
                                <Stack spacing direction="row">
                                  <BorderColorOutlinedIcon fontSize="small" />
                                  <DeleteOutlineOutlinedIcon fontSize="small" />
                                </Stack>
                              </Stack>
                            </Card>
                          </Grid>
                        </SortableElement>
                      );
                    })}
                </Box>
              </SortableContext>
            </DndContext>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

TasksNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  nestedRowProps: PropTypes.object,
};

export default TasksNestedRow;
export function SortableElement({ id, children, ...rest }) {
  // eslint-disable-next-line react/destructuring-assignment, react/prop-types
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // padding: '8px',
  };
  return (
    <Grid {...rest} container ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </Grid>
  );
}
