import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';
import PropTypes from 'prop-types';
import { ColorlibConnector, ColorlibStepIcon } from './utils';

const CustomizedSteppers = ({ steps, activeStep, children }) => (
  <Stack sx={{ width: '100%' }} spacing={4}>
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps && steps?.length > 0
        ? steps.map((step) => (
            <Step key={step?.label}>
              <StepLabel StepIconComponent={(props) => ColorlibStepIcon(props, step?.icon)}>{step?.label}</StepLabel>
            </Step>
          ))
        : null}
    </Stepper>
  </Stack>
);

CustomizedSteppers.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
    })
  ),
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node,
};

export default CustomizedSteppers;

// activeStep, setActiveState
