import { createSlice } from '@reduxjs/toolkit';

const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState: {
    snackbarOpen: false,
    snackbarType: '',
    snackbarMessage: '',
    snackbarPosition: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  reducers: {
    setSnackbar: (state, action) => {
      const { snackbarOpen, snackbarMessage, snackbarType } = action.payload;
      state.snackbarOpen = snackbarOpen;
      state.snackbarType = snackbarType;
      state.snackbarMessage = snackbarMessage;
      if (action.payload.snackbarPosition && action.payload.snackbarPosition !== null) {
        const { vertical, horizontal } = action.payload.snackbarPosition;
        state.snackbarPosition.vertical = vertical;
        state.snackbarPosition.horizontal = horizontal;
      }
    },
  },
});
export const { setSnackbar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
