import React from 'react';
import { alpha } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import { Types } from '../../types';
import Iconify from '../Iconify';

const DraggableTableRow = ({ id, children, ...rest }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
  };
  return (
    <TableRow {...rest} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </TableRow>
  );
};

const ReorderTableBody = ({
  visibleRows,
  isSelected,
  isRowCollapsed,
  selectedParent,
  groupHeadCells,
  handleClick,
  headCells,
  parentRowClick,
  childKey,
}) => {
  const cells = groupHeadCells && groupHeadCells.length > 0 ? groupHeadCells : headCells;
  return (
    <SortableContext items={visibleRows} strategy={verticalListSortingStrategy}>
      {visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row?._id);
        const isParentRowSelected = selectedParent.indexOf(row?._id) !== -1;
        const isCollapsed = isRowCollapsed(row?._id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const isArchieved = row?.status ? Boolean(row?.status === Types.ARCHIVED) : false;
        return (
          <DraggableTableRow
            hover
            // onClick={(event) => handleClick(event, row?._id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row?.id}
            id={row?.id}
            selected={isItemSelected}
            sx={{
              bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
              '&:hover': {
                bgcolor: isArchieved && 'rgba(255, 72, 66, 0.05) !important',
              },
              // cursor: 'grab',
              '&.Mui-selected': {
                bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
              },
            }}
          >
            <TableCell padding="checkbox" align="center">
              {/* <Checkbox
                          onClick={(event) => handleClick(event, row?._id)}
                          size='small'
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
              <DragIndicatorIcon fontSize="small" />
            </TableCell>
            {cells.map((cell, cellIndex) => {
              if (cell?.renderElement) {
                return <TableCell>{cell?.renderElement(row)}</TableCell>;
              }
              return (
                <TableCell padding={cellIndex === 0 ? 'none' : 'inherit'} align={cell.align || 'left'}>
                  <Stack direction="row" alignItems="center">
                    {cellIndex === 0 && groupHeadCells && (
                      <ChevronRightIcon
                        className={`side-icon ${isCollapsed && 'rotate-icon'}`}
                        fontSize="small"
                        sx={{ mr: 2, color: '#212B36' }}
                      />
                    )}
                    {row[`${cell?.id}`]}
                  </Stack>
                  {/* {row[`${cell.id}`]} */}
                </TableCell>
              );
            })}
            {!groupHeadCells && (
              <TableCell>
                <Stack direction="row">
                  <IconButton>
                    <Iconify icon="iconamoon:edit-duotone" />
                  </IconButton>
                  <IconButton>
                    <Iconify icon="tabler:archive" />
                  </IconButton>
                </Stack>
              </TableCell>
            )}
          </DraggableTableRow>
        );
        //   : (
        //     <>
        //       <TableRow
        //         hover
        //         // onClick={(event) => handleClick(event, row?._id)}
        //         onClick={(event) => parentRowClick(event, row)}
        //         role="checkbox"
        //         aria-checked={isParentRowSelected}
        //         tabIndex={-1}
        //         key={row.id}
        //         selected={isParentRowSelected}
        //         sx={{
        //           bgcolor: 'aqua',
        //           bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
        //           cursor: 'pointer',
        //           '&.Mui-selected': {
        //             bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2)
        //           },
        //         }}
        //       >
        //         <TableCell padding="checkbox">
        //           {/* <Stack direction='row' alignItems='center'> */}
        //           <Checkbox
        //             onClick={(event) => handleClick(event, row?._id, row[childKey] || null)}
        //             size='small'
        //             color="primary"
        //             checked={isParentRowSelected}
        //             inputProps={{
        //               'aria-labelledby': labelId,
        //             }}
        //           />
        //           {/* </Stack> */}
        //         </TableCell>
        //         {
        //           groupHeadCells?.map((cell, index) => {
        //             if (cell?.renderElement) {
        //               return (
        //                 <TableCell>
        //                   {cell?.renderElement(row)}
        //                 </TableCell>
        //               )
        //             }
        //             return (
        //               <TableCell
        //                 colSpan={cell?.colSpan && cell?.colSpan}
        //                 sx={{ pl: index === 0 ? 3 : 'none', bgcolor: cell?.colSpan && 'red' }}
        //                 padding={index === 0 ? 'none' : 'inherit'} align={cell.align || 'left'}>
        //                 <Stack direction='row' alignItems='center'>
        //                   {index === 0 && <ChevronRightIcon className={`side-icon ${isCollapsed && 'rotate-icon'}`} fontSize='small' sx={{ mr: 2, color: '#212B36' }} />}
        //                   {row[`${cell?.id}`]}
        //                 </Stack>
        //               </TableCell>
        //             )
        //           })
        //         }
        //       </TableRow>
        //       {/* {
        //          row[childKey]?.map(row => {
        //           return (
        //             <TableRow
        //               hover
        //               // onClick={(event) => handleClick(event, row?._id)}
        //               role="checkbox"
        //               aria-checked={isItemSelected}
        //               tabIndex={-1}
        //               key={row._id}
        //               selected={isSelected(row?._id)}
        //               sx={{
        //                 bgcolor: (row?.status && row?.status === Types.ARCHIVED) ? ((theme) => alpha(theme.palette.error.main, 0.07)) :
        //                   ((theme) => alpha(theme.palette.action.active, 0.06)),
        //                 cursor: 'pointer',
        //                 '&.Mui-selected': {
        //                   bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2)
        //                 },
        //                 // display: isCollapsed ? 'visible' : 'none',
        //                 // visibility: 0
        //               }}
        //             >
        //               <TableCell padding="checkbox">
        //                 <Checkbox
        //                   onClick={(event) => handleClick(event, row?._id)}
        //                   size='small'
        //                   color="primary"
        //                   checked={isSelected(row?._id)}
        //                   inputProps={{
        //                     'aria-labelledby': labelId,
        //                   }}
        //                 />
        //               </TableCell>
        //               {
        //                 headCells.map((cell, index) => {
        //                   if (cell?.renderElement) {
        //                     return (<TableCell>
        //                       {cell?.renderElement(row)}
        //                     </TableCell>)
        //                   }
        //                   return (
        //                     <TableCell sx={{ pl: index === 0 ? 5 : 'none' }} padding={index === 0 ? 'none' : 'inherit'} align={cell.align || 'left'}>{row[`${cell.id}`]}</TableCell>
        //                   )
        //                 })
        //               }
        //               <TableCell>
        //                 <Stack direction='row'>
        //                   <IconButton >
        //                     <Iconify icon="iconamoon:edit-duotone" />
        //                   </IconButton>
        //                   <IconButton >
        //                     <Iconify icon="tabler:archive" />
        //                   </IconButton>

        //                 </Stack>
        //               </TableCell>
        //             </TableRow>
        //           )
        //         })
        //       } */}
        //       {
        //         isCollapsed && (
        //           <TableRow>
        //             <TableCell colSpan={5}>
        //               <TaskComponent />
        //             </TableCell>
        //           </TableRow>
        //         )
        //       }
        //       {/* </CollapsableRow> */}
        //     </>
        //   )
      })}
    </SortableContext>
  );
};

DraggableTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
ReorderTableBody.propTypes = {
  visibleRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSelected: PropTypes.func.isRequired,
  isRowCollapsed: PropTypes.func.isRequired,
  selectedParent: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupHeadCells: PropTypes.array,
  handleClick: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  parentRowClick: PropTypes.func.isRequired,
  childKey: PropTypes.string.isRequired,
};

export default ReorderTableBody;
