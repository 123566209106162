export const APP_ROUTER_BASE_URL = '/';

export const getStatusWiseData = (rawData) => {
  const result = rawData.reduce(
    (acc, customer) => {
      if (customer.status === 'active') {
        acc.active.push(customer);
      } else if (customer.status === 'archived') {
        acc.archieved.push(customer);
      }
      acc.default.push(customer);
      return acc;
    },
    { active: [], archieved: [], default: [] }
  );
  return result;
};

export const filterEmptyValues = (obj) => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== ''));

export const getUserPermissionLabel = (permissionType) => {
  switch (permissionType) {
    case 1:
      return 'Project Manager';
    case 2:
      return 'View Only/Field User';
    case 3:
      return 'View Only';
    default:
      return '';
  }
};
export const getRatingLabel = (rating) => {
  switch (rating) {
    case 1:
      return 'Not Rated';
    case 2:
      return '100';
    case 3:
      return 'Field User Input';
    default:
      return '';
  }
};

export const analysisTypeLabel = (permissionType) => {
  switch (permissionType) {
    case 1:
      return 'Value Analysis';
    case 2:
      return 'Timeline Only';
    case 3:
      return 'View Only';
    default:
      return '';
  }
};
export const getTypeLabel = (Type) => {
  switch (Type) {
    case 1:
      return 'Fixed';
    case 2:
      return 'Variable';
    default:
      return '';
  }
};

export const getMainCategoryColor = (permissionType) => {
  switch (permissionType) {
    case 1:
      return '#15803d';
    case 2:
      return '#29417c';
    case 3:
      return '#da1e4e';
    default:
      return '';
  }
};

export const getMainCategoryLabel = (permissionType) => {
  switch (permissionType) {
    case 1:
      return 'CUSTOMER';
    case 2:
      return 'TASK & PROCESS';
    case 3:
      return 'NVA';
    default:
      return '';
  }
};

export const systemCategoryIDs = [
  '5bd871cd9165e4f2459ac21c',
  '5bd871d59165e4f2459ac21d',
  '5bd871df9165e4f2459ac21e',
  '5a9e9dcacba8ec63ac9ebf8a',
  '5c52f83ece22ab39727c59d5',
  '5cda7f492087d74c4fda1449',
];

export const capitalizeWords = (str) =>
  str
    .split(' ')
    .map((word) => {
      if (/^[a-zA-Z]/.test(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(' ');
