/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { visuallyHidden } from '@mui/utils';
import { get, has, startCase } from 'lodash';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomerLocationsToolbar from '../../pages/customer/CustomerLocationsToolbar';
// import GroupRuleToolbar from '../../pages/groupRole/GroupRuleToolbar';
// import NestedGroupRoleRow from '../../pages/groupRole/NestedGroupRoleRow';
// import SingleStudyToolbar from '../../pages/studiesPage/SingleStudyToolbar';
// import StudyToolbar from '../../pages/studiesPage/StudyToolbar';
// import TasksNestedRow from '../../pages/tasksPage/TasksNestedRow';
// import TasksToolbar from '../../pages/tasksPage/TasksToolbar';
import { Types } from '../../types';
import Iconify from '../Iconify';
import FieldUSerToolbar from './FieldUSerToolbar';
import ReorderTableBody from './ReorderTableBody';
import ProjectToolbar from './projectToolbar';
import { StyledSearch, applyFilter } from './utils';
import ConfirmDialog from '../ConfirmDialog';
import { BootstrapTooltip } from '../ThemeTooltip';
import HoursNestedRow from '../../pages/data/HoursNestedRow';
import HoursMappingToolbar from '../../pages/data/HoursMappingToolbar';
import CustomersToolbar from '../../pages/customer/CustomersToolbar';
import { systemCategoryIDs } from '../../utils';
import LocationsToolbar from '../../pages/projectList/location/LocationsToolbar';
import AreasToolbar from '../../pages/projectList/areas/AreasToolbar';
import TasksToolbar from '../../pages/tasksPage/TasksToolbar';
import TasksNestedRow from '../../pages/tasksPage/TasksNestedRow';
import RemindersNestedRow from '../../pages/reminder/RemindersNestedRow';
import GroupsToolbar from '../../pages/groupRole/GroupsToolbar';
import StudiesToolbar from '../../pages/studiesPage/studiesToolbar';
import SingleStudyToolbar from '../../pages/studiesPage/singleStudyToolbar';
import ProjectLogsToolbar from '../../pages/projectLogs/ProjectLogsToolbar';
import StudiesNestedRow from '../../pages/studiesPage/studiesNestedRow';
import SingleStudyNestedRow from '../../pages/studiesPage/singleStudyNestedRow';
import AddObsToolbar from '../../pages/studiesPage/addObsToolbar';
import AddObsAfterToolbar from '../../pages/studiesPage/addObsAfterToolbar';
import NotesToolbar from '../../pages/notes/NotesToolbar';
import NotesNestedRow from '../../pages/notes/NotesNestedRow';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order = '',
    orderBy = '',
    numSelected = '',
    rowCount = '',
    onRequestSort = '',
    headCells = '',
    noActions = '',
    isAllChecked,
    disableCheckbox,
    setElementFilterConfig,
    setOrderBy,
    setOrder,
    customColumnType,
    onSettingsClick,
  } = props;
  const createSortHandler = (property) => (event) => {
    if (setElementFilterConfig) {
      setElementFilterConfig((prev) => ({ filterBy: '', filterColumns: [], sortOrder: '', sortBy: '' }));
    }
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {!disableCheckbox && (
          <TableCell sx={{ bgcolor: '#F9FAFB' }} padding="checkbox">
            <Checkbox
              size="small"
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              // checked={rowCount > 0 && numSelected === rowCount}
              checked={isAllChecked}
              onChange={onSelectAllClick}
              onClick={(event) => onSelectAllClick(event)}
              disabled={rowCount <= 0}
            />
          </TableCell>
        )}
        {headCells?.map((headCell) => {
          let a;
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              align={headCell.align || 'left'}
              sx={{ pl: headCell.pl || 'none', bgcolor: '#F9FAFB', minWidth: headCell?.minWidth, maxWidth: headCell?.maxWidth }}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              colSpan={headCell?.colSpan}
              style={{ width: headCell?.width, minWidth: headCell?.width }}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : 'asc'}
                // onClick={() => handleSortClick(headCell)}
                // onClick={headCell?.disableSort ? null : headCell?.onClick || createSortHandler(headCell?.id)}
                onClick={(event) => {
                  if (!headCell?.disableSort) {
                    // Check if there's a custom onClick function defined for this head cell
                    if (headCell?.onClick) {
                      setOrder('');
                      setOrderBy('');
                      headCell.onClick();
                    } else {
                      createSortHandler(headCell?.id)(event);
                    }
                  }
                }}
                sx={{ fontWeight: 'bold' }}
              >
                {headCell?.label || ''}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden && visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {!noActions && (
          <TableCell align="center" sx={{ bgcolor: '#F9FAFB', fontWeight: 'bold' }}>
            Actions{' '}
            {customColumnType && onSettingsClick && (
              <IconButton onClick={(event) => onSettingsClick(event)}>
                <SettingsIcon />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object),
  noActions: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  setElementFilterConfig: PropTypes.func,
  setOrder: PropTypes.func,
  setOrderBy: PropTypes.func,
  customColumnType: PropTypes.string,
  onSettingsClick: PropTypes.func,
};

const EnhancedTableToolbar = (props) => {
  const { selected = '', handleSelectionClick = null, filterName = '', onFilterName = '', searchLable = '' } = props;
  const numSelected = selected.length;
  return (
    <Toolbar
      sx={{
        height: 76,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        // <Tooltip title="Delete">
        //   <IconButton>
        //     <DeleteIcon />
        //   </IconButton>
        // </Tooltip>
        <Stack gap direction="row" whiteSpace="nowrap">
          <Button variant="outlined" onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archieve')}>
            Archieve All
          </Button>
          <Button variant="outlined" onClick={() => handleSelectionClick && handleSelectionClick(selected, 'activate')}>
            Activate All
          </Button>
        </Stack>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder={searchLable || 'Search user...'}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string.isRequired,
  searchLable: PropTypes.string.isRequired,
  onFilterName: PropTypes.func.isRequired,
};

const EnhancedTable = (props) => {
  const {
    isGlobalFilter,
    noToolbar,
    enableDelete,
    singleNestedRow,
    addObservation,
    sortOrder,
    sortBy,
    isLoading,
    disableActions,
    hideCompactViewButton,
    childKey,
    groupHeadCells,
    headCells,
    rows,
    minWidth,
    filterCallback,
    filterKeys,
    customToolbar,
    nestedRowKey,
    toolbarProps = {},
    maxHeight,
    stickyHeader,
    handleDragEnd,
    defaultRowsPerPage = 5,
    handleRowClick,
    onEditClick,
    onRowStatusChange,
    onRowDelete,
    filterColumns,
    filterBy,
    disableNestedRowClick,
    hideNestedArrow,
    nestedRowProps,
    setElementFilterConfig,
    hideNestedFunc,
    disableCheckbox,
    customColumnType,
    onSettingsClick,
  } = props; // either filter by filterCallback or filterKeys

  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [CollapsedRows, setCollapsedRows] = useState([]);
  const [nestedCollapsedRows, setNestedCollapsedRows] = useState([]);
  const [selectedRowToChangeStatus, setSelectedFieldToChangeStatus] = useState(null);
  const [selectedRowToDelete, setSelectedFieldToDelete] = useState(null);
  const [isStatusModelOpen, setStatusModelOpen] = useState(false);
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);
  const [statusType, setStatusType] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [statusToChange, setStatusToChange] = useState('');
  const { isGroupViewToggled } = useSelector((state) => state.project);
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: rows || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterCallback,
    filterKeys,
    filterColumns,
    filterBy,
    sortBy,
    sortOrder,
    // customerFilters: {
    //   Industry,
    //   Sector
    // }
  });
  const handleStatusButtonClick = (event, row, type) => {
    if (row && row?._id && onRowStatusChange) {
      setStatusType(type);
      setSelectedFieldToChangeStatus(row);
      setStatusModelOpen(true);
    }
  };
  const handleSelectionClick = (selectedUsers, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  const handleDeleteClick = (event, row) => {
    if (row && row?._id && onRowDelete) {
      setSelectedFieldToDelete(row);
      setDeleteModelOpen(true);
    }
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const onConfirmStatusChange = (selectedField) => {
    if (statusType) {
      // setFilterName('');
      onRowStatusChange(selectedField, statusType, handleOpenBackdrop, handleCloseBackdrop);
      setStatusModelOpen(false);
    }
  };
  const onConfirmDelete = (selectedField) => {
    if (selectedField) {
      onRowDelete(selectedField, handleOpenBackdrop, handleCloseBackdrop);
      setDeleteModelOpen(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };
  const visibleRows = useMemo(
    () =>
      // stableSort(rows, getComparator(order, orderBy)).slice(
      rowsPerPage === -1
        ? stableSort(dataFiltered, getComparator(order, orderBy))
        : stableSort(dataFiltered, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, dataFiltered, order, orderBy, page, sortBy, sortOrder]
  );

  useEffect(() => {
    if (selected?.length > 0 && visibleRows.every((element) => selected.includes(element?._id))) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selected, visibleRows]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!visibleRows[0]?._id) {
        return null;
      }
      // check duplicacy
      if (!groupHeadCells || !isGroupViewToggled) {
        setSelected((currentSelected) => {
          const uniqueSelectedRows = visibleRows.filter((row) => !selected.includes(row?._id));
          return [...selected, ...uniqueSelectedRows.map((row) => row?._id)];
        });
      } else {
        const newSelectedParentRows = [];
        let newSelected = [];
        visibleRows.forEach((row) => {
          const Ids = row[childKey]?.map((n) => n?._id);
          newSelected = [...newSelected, ...Ids];
          newSelectedParentRows.push(row?._id);
        });
        setSelected(newSelected);
        setSelectedParent(newSelectedParentRows);
        setCollapsedRows(newSelectedParentRows);
      }
      return '';
    }
    setSelected((currentSelected) => {
      const selectedRows = visibleRows.map((row) => row?._id);
      const filteredSelectedRows = selected.filter((row) => !selectedRows.includes(row));
      return [...filteredSelectedRows];
    });
    // setSelected([]);
    // setSelectedParent([]);
    // setCollapsedRows([]);

    return '';
  };
  const handleClearSelection = () => {
    setSelected([]);
    setSelectedParent([]);
    setCollapsedRows([]);
  };
  const handleClick = (event, id, childRows) => {
    event.stopPropagation();
    if (!id) {
      return null;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (childRows) {
      if (selectedParent.indexOf(id) === -1) {
        setSelectedParent([...selectedParent, id]);
        setCollapsedRows([...CollapsedRows, id]);
        childRows.forEach((childRow, childIndex) => {
          if (selected.indexOf(childRow?._id) === -1) {
            newSelected.push(childRow?._id);
          }
        });
        setSelected([...selected, ...newSelected]);
      } else {
        setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== id));
        setSelectedParent(selectedParent.filter((parentRow) => parentRow !== id));
        setSelected(selected.filter((rowId) => !childRows.some((childRow) => childRow?._id === rowId)));
      }
    } else {
      if (selectedIndex === -1 && !childRows) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    }
    return null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderToolbar = (toolbarType) => {
    switch (toolbarType) {
      case 'project':
        return (
          <ProjectToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
          />
        );
      case 'fieldUser':
        return (
          <FieldUSerToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
          />
        );
      case Types.toolbarTypes.SINGLE_STUDY:
        return (
          <SingleStudyToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
          />
        );
      case Types.toolbarTypes.NOTES:
        return (
          <NotesToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
          />
        );
      case Types.toolbarTypes.ADD_OBS:
        return (
          <AddObsToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
          />
        );
      case Types.toolbarTypes.ADD_OBS_AFTER:
        return (
          <AddObsAfterToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
          />
        );
      case Types.toolbarTypes.STUDIES:
        return (
          <StudiesToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.TASKS:
        return (
          <TasksToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.PROJECT_LOGS:
        return (
          <ProjectLogsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      // case Types.toolbarTypes.GROUP_ROLES:
      //   return (
      //     <GroupRuleToolbar
      //       handleClearSelection = { handleClearSelection };
      //       selected={selected}
      //       filterName={filterName}
      //       onFilterName={handleFilterByName}
      //       toolbarProps={toolbarProps}
      // setPage = { setPage };
      //     />
      //   );
      // case Types.toolbarTypes.TASKS:
      //   return (
      //     <TasksToolbar
      //       handleClearSelection = { handleClearSelection };
      //       selected={selected}
      //       filterName={filterName}
      //       onFilterName={handleFilterByName}
      //       toolbarProps={toolbarProps}
      // setPage = { setPage };
      //     />
      //   );
      // case Types.toolbarTypes.STUDY:
      //   return (
      //     <StudyToolbar
      //       handleClearSelection = { handleClearSelection };
      //       selected={selected}
      //       filterName={filterName}
      //       onFilterName={handleFilterByName}
      //       toolbarProps={toolbarProps}
      //  setPage = { setPage };
      //     />
      //   );
      // case Types.toolbarTypes.SINGLE_STUDY:
      //   return (
      //     <SingleStudyToolbar
      //       handleClearSelection = { handleClearSelection };
      //       selected={selected}
      //       filterName={filterName}
      //       onFilterName={handleFilterByName}
      //       toolbarProps={toolbarProps}
      //  setPage = { setPage };
      //     />
      //   );
      case Types.toolbarTypes.CUSTOMER_LOCATIONS_TOOLBAR:
        return (
          <CustomerLocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleClearSelection={handleClearSelection}
            handleSelectionClick={handleSelectionClick}
          />
        );
      case Types.toolbarTypes.SUPER_USER:
        return (
          <CustomerLocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.LOCATIONS:
        return (
          <LocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.AREAS:
        return (
          <AreasToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.GROUPS:
        return (
          <GroupsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.CUSTOMER:
        return (
          <CustomersToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
          />
        );
      case Types.toolbarTypes.HOURS_MAPPING:
        return (
          <HoursMappingToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleClearSelection={handleClearSelection}
            handleSelectionClick={handleSelectionClick}
          />
        );
      default:
        return (
          <EnhancedTableToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            handleSelectionClick={handleSelectionClick}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
        );
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isRowCollapsed = (id) => CollapsedRows.indexOf(id) !== -1;
  const isNestedRowCollapsed = (id) => nestedCollapsedRows.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const parentRowClick = (event, row) => {
    if (isRowCollapsed(row?._id)) {
      setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setCollapsedRows((prevRows) => [...prevRows, row?._id]);
    }
    // handleClick(event, row?._id)
  };
  const nestedRowClick = (event, row) => {
    if (!event.target.closest('button')) {
      if (isNestedRowCollapsed(row?._id)) {
        setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
      } else if (singleNestedRow) {
        setNestedCollapsedRows((prevRows) => [row?._id]);
      } else {
        setNestedCollapsedRows((prevRows) => [...prevRows, row?._id]);
      }
    }
    // handleClick(event, row?._id)
  };
  const onRowClick = (event, row) => {
    if (!event.target.closest('button')) {
      handleRowClick(event, row);
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const visibleReorderRow = visibleRows.map((row) => ({
    ...row,
    id: row?._id,
  }));
  const closeNestedRow = (rowToRemove) => {
    const filteredRows = nestedCollapsedRows.filter((row) => row !== rowToRemove?._id);
    setNestedCollapsedRows(filteredRows);
  };
  const renderNestedRow = ({ rowType, row }) => {
    switch (rowType) {
      case Types.nestedRowTypes.HOURS_MAPPING:
        return (
          <HoursNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.nestedRowTypes.STUDIES:
        return (
          <StudiesNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.STUDY:
        return (
          <SingleStudyNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.NEST_ROW:
        return (
          <NotesNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.REMINDERS:
        return (
          <RemindersNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      // case Types?.nestedRowTypes?.GROUP_ROLES:
      //   return <NestedGroupRoleRow row={row} />;
      case Types?.nestedRowTypes?.TASKS:
        return <TasksNestedRow nestedRowProps={nestedRowProps} row={row} />;
      default:
        return null;
    }
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const onConfirmAllStatusChange = () => {
    handleClearSelection();
    setAllStatusModelOpened(false);
  };
  const isPaginationDisable = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!dataFiltered?.length) {
      if (dataFiltered?.length <= rowsPerPage) {
        // return true;
        return false;
      }
      return false;
    }
    return true;
  }, [dataFiltered, rowsPerPage]);
  const totalRowsHeight = rowsPerPage && rowsPerPage * (dense ? 41 : 61) + (dense ? 37.56 : 57.56); // +1
  const visibleRowsHeight = !!visibleRows?.length && visibleRows.length * (dense ? 41 : 61) + (dense ? 37.56 : 57.56);
  // const visibleRowsHeight = !!visibleRows?.length && visibleRows.length * (dense ? 41 : 61) + (dense ? 37.56 : 57.56);
  const calculateHeight = () => {
    if (rowsPerPage === -1) {
      // case when ALL option is selected
      if (!!rows?.length && rows.length * (dense ? 41 : 61) > maxHeight) {
        // case when total rows height is greater than maxHeight
        if (visibleRowsHeight < maxHeight) {
          // case when visibleRowsHeight is less than maxHeight (no scrollbar)
          if (visibleRowsHeight > 0) {
            // case for filtered rows and empty spaces
            return maxHeight - visibleRowsHeight;
          }
          // case for no data found
          return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
        }
        return 0;
      }
      if (visibleRowsHeight > 0) {
        // case for filtered rows and empty spaces
        return maxHeight - visibleRowsHeight;
      }
      // case for no data found
      return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
      // return maxHeight - visibleRowsHeight; // make codnditions as well
    }
    if (maxHeight && visibleRows?.length <= 0) {
      // case for no data found
      if (totalRowsHeight > maxHeight) {
        // case for no data found and when total rows height is greater than max height
        return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 37.56 : 57.56);
      }
      return rowsPerPage * (dense ? 41 : 61) - (dense ? 113 : 133);
    }
    if (!maxHeight || visibleRowsHeight > totalRowsHeight) {
      return 0;
    }
    if (totalRowsHeight > maxHeight) {
      if (maxHeight && visibleRowsHeight < maxHeight) {
        return maxHeight - visibleRowsHeight;
      }
      return 0;
    }
    // return 0;
    return rowsPerPage * (dense ? 41 : 61) - visibleRows.length * (dense ? 41 : 61);
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={openBackdrop}
        onClick={handleOpenBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmAllStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <Box sx={{ width: '100%' }}>
        <Card>
          <ConfirmDialog
            title="Change Status"
            open={isStatusModelOpen}
            setOpen={setStatusModelOpen}
            confirmText="Change Status"
            selectedField={selectedRowToChangeStatus}
            onConfirm={onConfirmStatusChange}
          >
            <Typography variant="body1">
              Are you sure you want to <b>{statusType === 'active' ? 'activate' : 'archive'}</b> this?
            </Typography>
          </ConfirmDialog>
          <ConfirmDialog
            title="Delete"
            open={isDeleteModelOpen}
            setOpen={setDeleteModelOpen}
            selectedField={selectedRowToDelete}
            onConfirm={onConfirmDelete}
            confirmText="Delete"
          >
            <Typography variant="body1">
              Are you sure you want to <b>delete</b> this?
            </Typography>
          </ConfirmDialog>
          {customToolbar ? (
            renderToolbar(customToolbar)
          ) : noToolbar ? null : (
            <EnhancedTableToolbar
              selected={selected}
              handleSelectionClick={handleSelectionClick}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          )}
          {isLoading && <LinearProgress />}
          {/* <TableContainer sx={{ overflow: 'unset' }}> */}
          {/* <ScrollBar> */}
          <TableContainer
            sx={{
              // height: maxHeight,
              maxHeight: maxHeight && maxHeight,
              minHeight: !(visibleRows?.length > 0) && 100,
            }}
          >
            {/* {visibleRows?.length > 0 ? ( */}
            <Table
              stickyHeader={stickyHeader}
              sx={{ minWidth: minWidth || 800 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                customColumnType={customColumnType}
                numSelected={groupHeadCells && isGroupViewToggled ? selectedParent.length : selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                isAllChecked={isAllChecked}
                disableCheckbox={disableCheckbox}
                setElementFilterConfig={setElementFilterConfig}
                onSettingsClick={onSettingsClick}
                // rowCount={rows.length}
                noActions={!!disableActions}
                rowCount={visibleRows?.length}
                headCells={headCells}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
              />
              <TableBody>
                {!toolbarProps?.isReording ? (
                  visibleRows &&
                  !!visibleRows?.length &&
                  visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row?._id);
                    const isParentRowSelected = selectedParent.indexOf(row?._id) !== -1;
                    const isCollapsed = isRowCollapsed(row?._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isArchieved = row?.status ? Boolean(row?.status === Types.ARCHIVED) : false;
                    return !groupHeadCells || !isGroupViewToggled ? (
                      <>
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row?._id)}
                          onClick={(event) =>
                            handleRowClick
                              ? onRowClick(event, row)
                              : nestedRowKey && !disableNestedRowClick && nestedRowClick(event, row)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{
                            bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
                            cursor: 'pointer',
                            '&.Mui-selected': {
                              bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                            },
                          }}
                        >
                          {!disableCheckbox && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row?._id)}
                                size="small"
                                color="primary"
                                checked={isItemSelected}
                              />
                            </TableCell>
                          )}
                          {headCells.map((cell, cellIndex) => {
                            if (cell?.renderElement) {
                              return (
                                <TableCell
                                  align={cell.align || 'left'}
                                  sx={{ minWidth: cell?.minWidth, maxWidth: cell?.maxWidth }}
                                  colSpan={cell?.colSpan}
                                >
                                  {cell?.renderElement(row)}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                // colSpan={cell?.colSpan}
                                // padding={cellIndex === 0 ? 'none' : 'inherit'}
                                align={cell.align || 'left'}
                              >
                                <Stack direction="row">
                                  {cellIndex === 0 && nestedRowKey && !hideNestedArrow && (
                                    <ChevronRightIcon
                                      className={`side-icon ${isNestedRowCollapsed(row?._id) && 'rotate-icon'}`}
                                      fontSize="small"
                                      sx={{
                                        mr: 2,
                                        color: '#212B36',
                                        opacity: (hideNestedFunc ? hideNestedFunc(row) : false) && 0,
                                      }}
                                    />
                                  )}
                                  <Typography sx={{ fontSize: '0.9375rem', fontWeight: 400 }} variant="captions">
                                    {has(row, `${cell.id}`)
                                      ? cell?.titleCase
                                        ? startCase(get(row, `${cell.id}`))
                                        : get(row, `${cell.id}`)
                                      : ''}
                                  </Typography>
                                </Stack>
                                {/* {get(row, `${cell.id}`) || row[`${cell.id}`]} */}
                              </TableCell>
                            );
                          })}
                          {!disableActions && (
                            <TableCell>
                              <Stack alignItems="center" justifyContent="center" direction="row">
                                {addObservation && get(row, 'studyType') && row?.studyType === 1 && (
                                  <BootstrapTooltip enterDelay={500} title="Add to Round">
                                    <IconButton
                                      sx={{ p: 0.5 }}
                                      onClick={(event) => !!row && addObservation && addObservation(row, event)}
                                    >
                                      <Iconify icon="ant-design:file-add-twotone" style={{ color: ' #00c0f3' }} />
                                    </IconButton>
                                  </BootstrapTooltip>
                                )}
                                <BootstrapTooltip enterDelay={500} title="Edit">
                                  <IconButton
                                    sx={{ p: 0.5 }}
                                    onClick={(event) =>
                                      !!row && onEditClick && onEditClick(event, row, nestedCollapsedRows, setNestedCollapsedRows)
                                    }
                                  >
                                    <Iconify icon="iconamoon:edit-duotone" />
                                  </IconButton>
                                </BootstrapTooltip>
                                {onRowStatusChange &&
                                  row?.status &&
                                  (row?.status === Types.ACTIVE ? (
                                    row?._id &&
                                    systemCategoryIDs?.indexOf(row?._id) === -1 && (
                                      <BootstrapTooltip enterDelay={500} title="Archive">
                                        <IconButton
                                          sx={{ p: 0.5 }}
                                          onClick={(event) => handleStatusButtonClick(event, row, 'archive')}
                                        >
                                          <Iconify icon="tabler:archive" />
                                        </IconButton>
                                      </BootstrapTooltip>
                                    )
                                  ) : (
                                    <BootstrapTooltip enterDelay={500} title="Activate">
                                      <IconButton
                                        sx={{ p: 0.5, color: 'success.darker' }}
                                        onClick={(event) => handleStatusButtonClick(event, row, 'active')}
                                      >
                                        <Iconify icon="ph:play-duotone" />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ))}
                                {(row?.status === Types.ARCHIVED || enableDelete) && onRowDelete && (
                                  <BootstrapTooltip enterDelay={500} title="Remove">
                                    <IconButton
                                      sx={{ p: 0.5, color: 'error.main' }}
                                      onClick={(event) => handleDeleteClick(event, row)}
                                    >
                                      <Iconify icon="ant-design:delete-twotone" />
                                    </IconButton>
                                  </BootstrapTooltip>
                                )}
                              </Stack>
                            </TableCell>
                          )}
                        </TableRow>
                        {!!nestedRowKey && isNestedRowCollapsed(row?._id) && renderNestedRow({ rowType: nestedRowKey, row })}
                      </>
                    ) : (
                      <>
                        {!!row[childKey]?.length && (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row?._id)}
                            onClick={(event) => parentRowClick(event, row)}
                            role="checkbox"
                            aria-checked={isParentRowSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isParentRowSelected}
                            sx={{
                              bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
                              cursor: 'pointer',
                              '&.Mui-selected': {
                                bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                              },
                            }}
                          >
                            <TableCell padding="checkbox">
                              {/* <Stack direction='row' alignItems='center'> */}
                              <Checkbox
                                onClick={(event) => handleClick(event, row?._id, row[childKey] || null)}
                                size="small"
                                color="primary"
                                checked={isParentRowSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                              {/* </Stack> */}
                            </TableCell>
                            {groupHeadCells?.map((cell, cellIndex) => {
                              if (cell?.renderElement) {
                                return <TableCell>{cell?.renderElement(row)}</TableCell>;
                              }
                              return (
                                <TableCell
                                  colSpan={cell?.colSpan && cell?.colSpan}
                                  sx={{
                                    pl: cellIndex === 0 ? 3 : 'none',
                                    bgcolor: cell?.colSpan && 'red',
                                  }}
                                  padding={cellIndex === 0 ? 'none' : 'inherit'}
                                  align={cell.align || 'left'}
                                >
                                  <Stack direction="row" alignItems="center">
                                    {cellIndex === 0 && (
                                      <ChevronRightIcon
                                        className={`side-icon ${isCollapsed && 'rotate-icon'}`}
                                        fontSize="small"
                                        sx={{
                                          mr: 2,
                                          color: '#212B36',
                                        }}
                                      />
                                    )}
                                    {row[`${cell?.id}`]}
                                  </Stack>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                        {isCollapsed &&
                          childKey &&
                          (row[childKey]?.length ? (
                            row[childKey]?.map((cellRow) => (
                              <TableRow
                                hover
                                role="checkbox"
                                isItemSelected
                                tabIndex={-1}
                                key={cellRow._id}
                                selected={isSelected(cellRow?._id)}
                                sx={{
                                  bgcolor:
                                    cellRow?.status && cellRow?.status === Types.ARCHIVED
                                      ? (theme) => alpha(theme.palette.error.main, 0.07)
                                      : (theme) => alpha(theme.palette.action.active, 0.06),
                                  cursor: 'pointer',
                                  '&.Mui-selected': {
                                    bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                                  },
                                  // display: isCollapsed ? 'visible' : 'none',
                                  // visibility: 0
                                }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onClick={(event) => handleClick(event, cellRow?._id)}
                                    size="small"
                                    color="primary"
                                    checked={isSelected(cellRow?._id)}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>
                                {headCells.map((cell, cellIndex) => {
                                  if (cell?.renderElement) {
                                    return <TableCell>{cell?.renderElement(cellRow)}</TableCell>;
                                  }
                                  return (
                                    <TableCell
                                      sx={{
                                        pl: cellIndex === 0 ? 5 : 'none',
                                      }}
                                      padding={cellIndex === 0 ? 'none' : 'inherit'}
                                      align={cell.align || 'left'}
                                    >
                                      {cellRow[`${cell.id}`]}
                                    </TableCell>
                                  );
                                })}
                                <TableCell>
                                  <Stack direction="row">
                                    <IconButton>
                                      <Iconify icon="iconamoon:edit-duotone" />
                                    </IconButton>
                                    <IconButton>
                                      <Iconify icon="tabler:archive" />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    );
                  })
                ) : (
                  <DndContext
                    modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToParentElement]}
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    {/* <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                      >
                        {items.map(id => <SortableItem key={id} id={id} />)}
                      </SortableContext> */}
                    <ReorderTableBody
                      visibleRows={visibleReorderRow}
                      isSelected={isSelected}
                      isRowCollapsed={isRowCollapsed}
                      selectedParent={selectedParent}
                      childKey={childKey}
                      parentRowClick={parentRowClick}
                      headCells={headCells}
                      handleClick={handleClick}
                      groupHeadCells={groupHeadCells}
                    />
                  </DndContext>
                )}

                {visibleRows?.length <= 0 && (
                  <TableRow>
                    <TableCell sx={{ borderBottom: '1px solid transparent' }} colSpan={12}>
                      {' '}
                      <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 100, width: '100%' }}>
                        {!isLoading && !visibleRows?.length && (
                          <Typography variant="subtitle1" textAlign="center">
                            {`No data found ${filterName && 'for '} `} <b>{filterName && filterName}</b>
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {/* {emptyRows > 0 && ( */}
                {maxHeight && (
                  <TableRow
                    sx={{ '& .MuiTableCell-root': { p: 0 } }}
                    style={{
                      height: calculateHeight(),
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* ) : (
              <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 200 }}>
                {!isLoading && (
                  <Typography variant="h5" textAlign="center">
                    No data found
                  </Typography>
                )}
              </Box>
            ) */}
          </TableContainer>
          {/* </ScrollBar> */}
          <TablePagination
            sx={{
              backgroundClip: 'red',
              boxShadow:
                '-webkit-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);-moz-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);',
            }}
            rowsPerPageOptions={[5, 10, 15, 18, 25, { label: 'All', value: -1 }]}
            component="div"
            // count={rows?.length}
            count={dataFiltered?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            disabled={isPaginationDisable}
          />
        </Card>
        {!hideCompactViewButton && (
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Compact View" />
        )}
      </Box>
    </>
  );
};

EnhancedTable.propTypes = {
  onSettingsClick: PropTypes.func,
  headCells: PropTypes.array.isRequired, // Add this line
  childKey: PropTypes.string,
  nestedRowKey: PropTypes.string,
  sortOrder: PropTypes.string,
  customColumnType: PropTypes.string,
  sortBy: PropTypes.string,
  maxHeight: PropTypes.number,
  defaultRowsPerPage: PropTypes.oneOf([5, 10, 25, -1]),
  groupHeadCells: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  minWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  filterCallback: PropTypes.func,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  customToolbar: PropTypes.node,
  toolbarProps: PropTypes.object,
  nestedRowProps: PropTypes.object,
  stickyHeader: PropTypes.bool,
  handleDragEnd: PropTypes.func,
  handleRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
  hideCompactViewButton: PropTypes.bool,
  disableNestedRowClick: PropTypes.bool,
  hideNestedArrow: PropTypes.bool,
  singleNestedRow: PropTypes.bool,
  enableDelete: PropTypes.bool,
  noToolbar: PropTypes.bool,
  isGlobalFilter: PropTypes.bool,
  disableActions: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  onEditClick: PropTypes.func,
  onRowStatusChange: PropTypes.func,
  hideNestedFunc: PropTypes.func,
  onRowDelete: PropTypes.func,
  setElementFilterConfig: PropTypes.func,
  addObservation: PropTypes.func,
  filterBy: PropTypes.any,
  filterColumns: PropTypes.any,
};

export default EnhancedTable;
