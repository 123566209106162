import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  obsLoading: false,
  dataSummaries: [],
  obsCount: 0,
};
export const getDataSummaries = createAsyncThunk('dataSummaries/getdataSummaries', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'studies/get/summary',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getObservationCount = createAsyncThunk('dataSummaries/getdataSummaruCount', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'studies/get/obs/count',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const dataSummarySlice = createSlice({
  name: 'dataSummaries',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDataSummaries.pending, (state, action) => {
      state.isLoading = true;
      state.dataSummaries = [];
    });
    builder.addCase(getDataSummaries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dataSummaries = action.payload?.data;
    });
    builder.addCase(getDataSummaries.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getObservationCount.pending, (state, action) => {
      state.obsLoading = true;
      state.obsCount = 0;
    });
    builder.addCase(getObservationCount.fulfilled, (state, action) => {
      state.obsLoading = false;
      state.obsCount = action.payload?.data;
    });
    builder.addCase(getObservationCount.rejected, (state, action) => {
      state.obsLoading = false;
    });
  },
});

export default dataSummarySlice.reducer;
