export const Types = {
  ACTIVE: 'active',
  ARCHIVED: 'archive',
  ALL: 'all',
  DEFAULT: 'default',
  nestedRowTypes: {
    GROUP_ROLES: 'groupRoles',
    TASKS: 'tasks',
    REMINDERS: 'reminders',
    HOURS_MAPPING: 'hoursMapping',
    STUDIES: 'studies',
    STUDY: 'study',
    NEST_ROW: 'nestedNotes',
  },
  toolbarTypes: {
    GROUP_ROLES: 'groupRoles',
    TASKS: 'tasks',
    STUDY: 'study',
    SINGLE_STUDY: 'singleStudy',
    ADD_OBS: 'addObs',
    ADD_OBS_AFTER: 'addObsAfter',
    CUSTOMER_LOCATIONS_TOOLBAR: 'customerLocations',
    SUPER_USER: 'superUser',
    PROJECT_LOGS: 'projectLogs',
    HOURS_MAPPING: 'hoursMapping',
    CUSTOMER: 'customer',
    LOCATIONS: 'locations',
    AREAS: 'areas',
    GROUPS: 'groups',
    STUDIES: 'studies',
    NOTES: 'notes',
  },
};
