/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import CustomSelect from '../../components/select';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import { addObservationSchema, studiesSchema } from '../../utils/schema';
import { updateObservation, updateStudy } from './studiesSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { filterEmptyValues } from '../../utils';
import { ThemeButton } from '../../components/button';

const SingleStudyNestedRow = ({
  row,
  closeNestedRow,
  setNestedCollapsedRows,
  handleOpenBackdrop,
  handleCloseBackdrop,
  nestedRowProps,
}) => {
  const [hourLabel, setHourLabel] = useState('');
  const [timeslotLabel, setTimeslotLabel] = useState('');
  const { updateStudyLoading } = useSelector((state) => state.studies);
  const ratingOptions = useMemo(() => {
    const array = [];
    for (let i = 40; i <= 135; i += 5) {
      array.push({ label: i, value: i.toString() });
    }
    array.push({ label: 'Not Rated', value: 'Not Rated' });
    return array;
  }, []);
  const dispatch = useDispatch();
  const { tasks } = useSelector((state) => state.tasks);
  const form = useForm({
    defaultValues: {
      areaID: get(row, 'areaID', ''),
      elementID: get(row, 'elementID', ''),
      taskID: get(row, 'taskID', ''),
      roleID: get(row, 'roleID', ''),
      rating: get(row, 'rating', null),
      frequency: get(row, 'frequency', null),
      notes: get(row, 'notes', ''),
      duration: get(row, 'duration', ''),
    },
    resolver: yupResolver(addObservationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const {
    taskSelectOptions = [],
    rolesSelectOptions = [],
    areasOptions = [],
    elementSelectOptions = [],
    setisModalopen,
  } = nestedRowProps;
  // const handleSubmit = async () => {};
  const currentTask = watch('taskID');
  const currentTaskElements = useMemo(() => {
    if (!currentTask || !tasks?.length || !elementSelectOptions?.length) {
      return [];
    }
    const currentTaskObject = tasks?.find((el) => el?._id === currentTask);

    let filteredElements = [];
    if (currentTaskObject) {
      filteredElements = elementSelectOptions?.filter((op) =>
        currentTaskObject?.projectSetting?.elements?.some((el) => el === op?.value)
      );
    }
    return filteredElements;
  }, [currentTask, elementSelectOptions, tasks]);

  const { id: projectID } = useParams();
  const onSubmit = (values) => {
    const payload = { ...values };
    if (row?.studyType && row?.studyType === 1 && row?.roundID) {
      payload.roundID = row?.roundID;
    }
    const filteredPayload = filterEmptyValues(payload);
    if (get(row, '_id')) {
      dispatch(
        updateObservation({
          _id: get(row, '_id'),
          studyType: get(row, 'studyType'),
          studyID: get(row, 'studyID'),
          projectID,
          ...filteredPayload,
        })
      )
        .then((response) => {
          if (response.payload?.success) {
            setNestedCollapsedRows([]);
            // nestedRowProps?.fetchStudies();
            if (nestedRowProps?.nestedType === 'singleStudy') {
              nestedRowProps?.fetchStudy();
            } else {
              nestedRowProps?.fetchDataSummaries();
            }
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Study Updated Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');

  if (row?._id) {
    return (
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        {/* <TableCell padding="checkbox" /> */}
        <TableCell padding="checkbox" />
        <TableCell colSpan={nestedRowProps?.nestedType === 'singleStudy' ? 12 : 13} sx={{ verticalAlign: 'top' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DevTool control={control} />
            <Stack gap={4} direction="row">
              <Stack gap={2} width="70%" direction="row" justifyContent="space-between">
                <Grid container columnSpacing={8} px={8}>
                  {row?.studyType !== 1 ? (
                    <Grid item xs={12} md={4}>
                      <label style={{ fontSize: '0.8600rem' }}>Task</label>
                      <CustomSelect
                        name="taskID"
                        label="Select Task"
                        control={control}
                        options={taskSelectOptions}
                        error={!!errors?.taskID}
                        helperText={errors?.taskID && errors?.taskID?.message}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={4}>
                      <label style={{ fontSize: '0.8600rem' }}>Role</label>
                      <CustomSelect
                        name="roleID"
                        label="Select Role"
                        control={control}
                        options={rolesSelectOptions}
                        error={!!errors?.roleID}
                        helperText={errors?.roleID && errors?.roleID?.message}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <label style={{ fontSize: '0.8600rem' }}>Area</label>
                    <CustomSelect
                      name="areaID"
                      label="Select Area"
                      control={control}
                      options={areasOptions}
                      error={!!errors?.areaID}
                      helperText={errors?.areaID && errors?.areaID?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label style={{ fontSize: '0.8600rem' }}>Rating</label>
                    <CustomSelect
                      name="rating"
                      label="Select Rating"
                      control={control}
                      options={ratingOptions}
                      error={!!errors?.rating}
                      helperText={errors?.rating && errors?.rating?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack width="100%" direction="column">
                      <Stack alignItems="center" direction="row">
                        <label style={{ fontSize: '0.8600rem' }}>Element</label>
                        <ThemeButton onClick={() => setisModalopen && setisModalopen(true)}>Create Element</ThemeButton>
                      </Stack>
                      <CustomSelect
                        name="elementID"
                        label="Select Element"
                        control={control}
                        options={row?.studyType !== 1 ? currentTaskElements : elementSelectOptions}
                        error={!!errors?.elementID}
                        helperText={errors?.elementID && errors?.elementID?.message}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormInput
                      label="Notes"
                      placeholder="Write your notes"
                      InputProps={{ ...register('notes') }}
                      error={!!errors?.notes}
                      helperText={errors?.notes && errors?.notes?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormInput
                      label="Frequency"
                      type="number"
                      placeholder="Write frequency"
                      InputProps={{
                        name: frequencyInputFields.name,
                        onblur: frequencyInputFields.onBlur,
                        ref: frequencyInputFields.ref,
                        value: frequencyValue,
                        onChange: (event) => {
                          const newValue = event.target.value;
                          if (newValue === '' || Number(newValue) >= 0) {
                            return frequencyInputFields.onChange(event);
                          }
                          return null;
                        },
                      }}
                      error={!!errors?.frequency}
                      helperText={errors?.frequency && errors?.frequency?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack alignItems="center" justifyContent="center" direction="row">
                <BootstrapTooltip enterDelay={500} title="Save">
                  {updateStudyLoading ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <IconButton type="submit" sx={{ p: 0.5, color: 'primary.main' }}>
                      <Iconify icon="tabler:check" />
                    </IconButton>
                  )}
                </BootstrapTooltip>
                <BootstrapTooltip enterDelay={500} title="Cancel">
                  <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                    <Iconify icon="tabler:x" />
                  </IconButton>
                </BootstrapTooltip>
              </Stack>
            </Stack>
          </form>
        </TableCell>
        {/* <TableCell>
          <Stack alignItems="center" justifyContent="center" direction="row">
            <BootstrapTooltip enterDelay={500} title="Save">
              <IconButton onClick={handleSubmit} sx={{ p: 0.5, color: 'primary.main' }}>
                <Iconify icon="tabler:check" />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip enterDelay={500} title="Cancel">
              <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                <Iconify icon="tabler:x" />
              </IconButton>
            </BootstrapTooltip>
          </Stack>
        </TableCell> */}
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Typography sx={{ ml: 5, color: '#4444448f' }} variant="subtitle2">
          No Elements found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

SingleStudyNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  closeNestedRow: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  setNestedCollapsedRows: PropTypes.func,
  nestedRowProps: PropTypes.object,
};

export default SingleStudyNestedRow;
