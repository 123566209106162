import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  sectors: [],
  isLoading: false,
  isAdding: false,
};
export const getSectors = createAsyncThunk('sectors/getSectors', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'sectors/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

export const addSector = createAsyncThunk('sectors/addSectors', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'sectors/add',
      method: 'POST',
      data: payload,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const editSector = createAsyncThunk('sectors/editSectors', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `sectors/update/${payload?._id}`,
      method: 'PATCH',
      data: payload,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

// export const addIndustry = createAsyncThunk('industry/addIndustry', async (data, { rejectWithValue }) => {
//   try {
//     const response = await axiosInstance({
//       url: 'industries/add',
//       method: 'POST',
//       data,
//     });
//     const responseData = await response.data;
//     return responseData;
//   } catch (error) {
//     if (!error.response) {
//       throw error;
//     }
//     return rejectWithValue(error.response.data);
//   }
// });
// export const editIndustry = createAsyncThunk('industry/editIndustry', async (data, { rejectWithValue }) => {
//   if (data?._id) {
//     try {
//       const response = await axiosInstance({
//         url: `industries/update/${data?._id}`,
//         method: 'PATCH',
//         data,
//       });
//       const responseData = await response.data;
//       return responseData;
//     } catch (error) {
//       if (!error.response) {
//         throw error;
//       }
//       return rejectWithValue(error.response.data);
//     }
//   } else return '';
// });
const sectorsSlice = createSlice({
  name: 'sector',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSectors.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSectors.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sectors = action.payload.data;
    });
    builder.addCase(getSectors.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addSector.pending, (state, action) => {
      state.isAdding = true;
    });
    builder.addCase(addSector.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addSector.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editSector.pending, (state, action) => {
      state.isAdding = true;
    });
    builder.addCase(editSector.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(editSector.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export default sectorsSlice.reducer;
