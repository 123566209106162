import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  tags: [],
  isLoading: false,
  isAdding: false,
};
export const getTags = createAsyncThunk('tags/getTags', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `tags/get?status=${get(payload, 'status', '')}` : 'tags/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
// export const addLocation = createAsyncThunk('tags/addLocation', async (data, { rejectWithValue }) => {
//   try {
//     const response = await axiosInstance({
//       url: 'tags/add',
//       method: 'POST',
//       data,
//     });
//     const responseData = await response.data;
//     return responseData;
//   } catch (error) {
//     if (!error.response) {
//       throw error;
//     }
//     return rejectWithValue(error.response.data);
//   }
// });
// export const updateLocation = createAsyncThunk('location/updateLocation', async (data, { rejectWithValue }) => {
//   if (data?._id) {
//     try {
//       const response = await axiosInstance({
//         url: `tags/update/${data?._id}`,
//         method: 'PATCH',
//         data,
//       });
//       const responseData = await response.data;
//       return responseData;
//     } catch (error) {
//       if (!error.response) {
//         throw error;
//       }
//       return rejectWithValue(error.response.data);
//     }
//   } else return '';
// });
const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state, actions) => {
      state.isLoading = true;
      state.tags = [];
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tags = action.payload.data;
    });
    builder.addCase(getTags.rejected, (state, action) => {
      state.isLoading = false;
    });
    // builder.addCase(addLocation.pending, (state, actions) => {
    //   state.isAdding = true;
    // });
    // builder.addCase(addLocation.fulfilled, (state, action) => {
    //   state.isAdding = false;
    // });
    // builder.addCase(addLocation.rejected, (state, action) => {
    //   state.isAdding = false;
    // });
    // builder.addCase(updateLocation.pending, (state, actions) => {
    //   state.isAdding = true;
    // });
    // builder.addCase(updateLocation.fulfilled, (state, action) => {
    //   state.isAdding = false;
    // });
    // builder.addCase(updateLocation.rejected, (state, action) => {
    //   state.isAdding = false;
    // });
  },
});

export default tagsSlice.reducer;
