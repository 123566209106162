import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  reminders: [],
  isLoading: false,
  isAdding: false,
};
export const getReminders = createAsyncThunk('reminders/getReminders', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `reminders/get?status=${get(payload, 'status', '')}` : 'reminders/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addReminder = createAsyncThunk('reminders/addReminder', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'reminders/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateReminder = createAsyncThunk('reminders/updateReminder', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `reminders/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
const reminderslice = createSlice({
  name: 'reminders',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getReminders.pending, (state, actions) => {
      state.isLoading = true;
      state.reminders = [];
    });
    builder.addCase(getReminders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reminders = action.payload.data;
    });
    builder.addCase(getReminders.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addReminder.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addReminder.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addReminder.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateReminder.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(updateReminder.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateReminder.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export default reminderslice.reducer;
