import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // role: '1', // Super admin
  // role: "2",    // Admin
  //   role: "3",    // Field User
  role: '', // Logout

  isNavExpanded: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleNav: (state) => {
      state.isNavExpanded = !state.isNavExpanded;
    },
  },
});

export const { increment, toggleNav } = dashboardSlice.actions;

export default dashboardSlice.reducer;
