import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isGroupViewToggled: true,
  isLoading: false,
  isAdding: false,
  projects: [],
  selectedRows: [],
  singleProjectData: null,
  isSingleProjectLoading: false,
};
export const getProjects = createAsyncThunk('project/getProjects', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/get',
      method: 'POST',
      data: { isRequestFromApp: false },
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

export const addProject = createAsyncThunk('project/addProject', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'projects/add',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const updateProject = createAsyncThunk('project/updateProject', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `projects/update/${payload?._id}`,
      method: 'PATCH',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

export const getSingleProject = createAsyncThunk('project/getSingleProject', async (id, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `projects/get/${id}`,
      method: 'GET',
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    toggleGroupView: (state, action) => {
      // state.isGroupViewToggled = !state.isGroupViewToggled;
      state.isGroupViewToggled = action?.payload;
    },
    updateSelectedRow: (state, action) => {
      // state.isGroupViewToggled = !state.isGroupViewToggled;
      const rowId = action.payload;
      if (state.selectedRows.indexOf(rowId) === -1) {
        state.selectedRows.push(rowId);
      } else {
        state.selectedRows = state.selectedRows.filter((id) => id !== rowId);
      }
      state.isGroupViewToggled = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projects = action.payload.data;
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getSingleProject.pending, (state, action) => {
      state.isSingleProjectLoading = true;
      state.singleProjectData = null;
    });
    builder.addCase(getSingleProject.rejected, (state, action) => {
      state.isSingleProjectLoading = false;
    });
    builder.addCase(getSingleProject.fulfilled, (state, action) => {
      state.singleProjectData = get(action, 'payload.data') && get(action, 'payload.data');
      state.isSingleProjectLoading = false;
    });
    builder.addCase(addProject.pending, (state, action) => {
      state.isAdding = true;
    });
    builder.addCase(addProject.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addProject.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateProject.pending, (state, action) => {
      state.isAdding = true;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.isAdding = false;
    });
  },
});

export const { toggleGroupView, updateSelectedRow } = projectSlice.actions;
export default projectSlice.reducer;
