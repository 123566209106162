import { useEffect, useState } from 'react';
import { Link, NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
//
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { getRole } from '../../pages/login/loginSlice';

// ----------------------------------------------------------------------

// const cookies = new Cookies();
// const jwtToken = cookies.get('token');
// const roles = jwtToken && jwtDecode(jwtToken);
// // const role = String(Role.role)
// const role = roles?.role?.toString();

const NavSection = ({ data = [], ...other }) => {
  const { role } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!role) {
      dispatch(getRole());
    }
  }, [dispatch, role]);

  const userRole = role !== null && role?.toString();
  const { isNavExpanded } = useSelector((state) => state.dashboard);

  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{
          p: isNavExpanded ? (1, 1, 1, 0) : 1,
          ml: isNavExpanded ? 0.5 : 1,
        }}
      >
        {data.map(
          (item) => item?.allowedRoles && item?.allowedRoles.includes(userRole) && <NavItem key={item.title} item={item} />
        )}
      </List>
    </Box>
  );
};

// ----------------------------------------------------------------------

export const NavItem = ({ item }) => {
  const { title, path, icon, info } = item;
  const location = useLocation();
  const [menuName, setMenuName] = useState('');
  const { role } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!role) {
      dispatch(getRole());
    }
  }, [dispatch, role]);

  const userRole = role !== null && role?.toString();
  const handleNavClicked = (event) => {
    if (item.children) {
      if (isNavExpanded) {
        setMenuName(menuName === title ? '' : title);
      } else {
        handleClickListItem(event);
      }
    }
  };
  const isNavExpanded = useSelector((state) => state.dashboard.isNavExpanded);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledNavItem
        onClick={handleNavClicked}
        component={path && RouterLink}
        // to={!item.children && path}
        to={path}
        sx={{
          '&.active': {
            color: !item.children && 'text.primary',
            bgcolor: !item.children && '#00c0f333',
            fontWeight: 'fontWeightBold',
            // bgcolor: !item.children && 'action.selected',
            // bgcolor: !item.children && '#00c0f333#00c0f31a',
          },
          '&:hover': {
            backgroundColor: '#00c0f31a',
          },
          ...(!isNavExpanded &&
            location.pathname.startsWith(item?.shadowPath) && {
              color: 'text.primary',
              bgcolor: '#00c0f333',
              fontWeight: 'fontWeightBold',
            }),
          ...(menuName !== item.title &&
            isNavExpanded &&
            location?.pathname?.startsWith(item?.shadowPath) && {
              color: 'text.primary',
              bgcolor: '#00c0f333',
              fontWeight: 'fontWeightBold',
            }),
          ...(!isNavExpanded
            ? {
                borderRadius: '50%',
                width: 58,
                height: 58,
              }
            : { borderRadius: '59px 0 0 59px', pl: 0.5 }),

          '&.active .MuiListItemIcon-root .svg-color': {
            color: '#00C0F3',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        {isNavExpanded && (
          <>
            <ListItemText disableTypography primary={title} />
            {item.children && (
              <Stack
              //  sx={{marginRight:15}}
              >
                {menuName !== item.title ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
              </Stack>
            )}
            {info && info}
          </>
        )}
      </StyledNavItem>
      {item.children && isNavExpanded ? (
        <Collapse in={menuName === item.title} unmountOnExit>
          <NavSection data={item.children} />
        </Collapse>
      ) : (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {item?.children?.map(
            (option, index) =>
              option?.allowedRoles.includes(userRole) && (
                <MenuItem
                  component={Link}
                  key={option.title}
                  selected={location?.pathname === option?.path} // make a function to check if on current path || compare query with path
                  onClick={() => setAnchorEl(null)}
                  to={option?.path}
                >
                  {option.title}
                </MenuItem>
              )
          )}
        </Menu>
      )}
    </>
    // <>
    //   <StyledNavItem
    //     onClick={handleNavClicked}
    //     component={RouterLink}
    //     to={!item.children && path}
    //     sx={{
    //       '&.active': {
    //         color: !item.children && 'text.primary',
    //         bgcolor: !item.children && 'action.selected',
    //         fontWeight: 'fontWeightBold',
    //       },
    //     }}
    //   >
    //     <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

    //     <ListItemText disableTypography primary={title} />
    //     {item.children && (menuName !== item.title ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />)}
    //     {info && info}
    //   </StyledNavItem>
    //   {item.children && (
    //     <Collapse in unmountOnExit>
    //       <NavSection data={item.children} />
    //     </Collapse>
    //   )}
    // </>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    icon: PropTypes.node,
    info: PropTypes.string,
    children: PropTypes.array,
    shadowPath: PropTypes.string,
  }),
};

NavSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.node,
      info: PropTypes.string,
      children: PropTypes.array,
      shadowPath: PropTypes.string,
    })
  ),
};
export default NavSection;
