import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  customers: [],
  adminUsers: [],
  fieldUsers: [],
  isLoading: false,
  isAdding: false,
  isLoadingSingleCustomer: false,
  isAdminUsersLoading: false,
  isFieldUsersLoading: false,
  singleCustomer: null,
};

export const getCustomers = createAsyncThunk('customer/getCustomers', async (payload, { rejectWithValue }) => {
  try {
    let url = 'customers/get';
    if (payload?.status) {
      url = `customers/get?status=${payload?.status}`;
    }
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getAdminUsersByCustomer = createAsyncThunk('customer/getAdminUsers', async (customerID, { rejectWithValue }) => {
  if (customerID) {
    try {
      const response = await axiosInstance({
        url: 'users/get',
        method: 'POST',
        data: { customerID, role: 2 },
      });
      const data = await response?.data;
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response?.data);
    }
  } else return '';
});
export const getFieldUsersByCustomer = createAsyncThunk('customer/getFieldUsers', async (customerID, { rejectWithValue }) => {
  if (customerID) {
    try {
      const response = await axiosInstance({
        url: 'users/get',
        method: 'POST',
        data: { customerID, role: 3 },
      });
      const data = await response?.data;
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response?.data);
    }
  } else return '';
});

export const getSingleCustomer = createAsyncThunk('customer/getSingleCustomer', async (customerID, { rejectWithValue }) => {
  if (customerID) {
    try {
      const response = await axiosInstance({
        url: `customers/get/${customerID}`,
        method: 'GET',
      });
      const data = await response?.data;
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response?.data);
    }
  } else {
    return '';
  }
});

export const addCustomer = createAsyncThunk('customer/addCustomer', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'customers/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const uploadImage = createAsyncThunk('customer/uploadPhoto', async (data, { rejectWithValue }) => {
  if (data?.customerID) {
    try {
      const formData = new FormData();
      formData.append('customerID', data?.customerID);
      formData.append('image', data?.image);
      const response = await axiosInstance({
        url: 'customers/upload/photo',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure correct Content-Type for FormData
        },
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else {
    return '';
  }
});
export const editCustomer = createAsyncThunk('customer/addCustomer', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `customers/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = get(action, 'payload.data', []);
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addCustomer.pending, (state, action) => {
      state.isAdding = true;
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addCustomer.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(getSingleCustomer.pending, (state, action) => {
      state.isLoadingSingleCustomer = true;
    });
    builder.addCase(getSingleCustomer.fulfilled, (state, action) => {
      state.isLoadingSingleCustomer = false;
      if (get(action, 'payload.data')) {
        state.singleCustomer = action.payload.data;
      }
    });
    builder.addCase(getSingleCustomer.rejected, (state, action) => {
      state.isLoadingSingleCustomer = false;
    });
    builder.addCase(getAdminUsersByCustomer.pending, (state, action) => {
      state.isAdminUsersLoading = true;
      state.adminUsers = [];
    });
    builder.addCase(getAdminUsersByCustomer.fulfilled, (state, action) => {
      state.isAdminUsersLoading = false;
      if (get(action, 'payload.data')) {
        state.adminUsers = action.payload.data;
      }
    });
    builder.addCase(getAdminUsersByCustomer.rejected, (state, action) => {
      state.isAdminUsersLoading = false;
    });
    builder.addCase(getFieldUsersByCustomer.pending, (state, action) => {
      state.isFieldUsersLoading = true;
      state.fieldUsers = [];
    });
    builder.addCase(getFieldUsersByCustomer.fulfilled, (state, action) => {
      state.isFieldUsersLoading = false;
      if (get(action, 'payload.data')) {
        state.fieldUsers = action.payload.data;
      }
    });
    builder.addCase(getFieldUsersByCustomer.rejected, (state, action) => {
      state.isFieldUsersLoading = false;
    });
  },
});

export default customerSlice.reducer;
