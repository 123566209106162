import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import React from 'react';
import { ActionButton, ThemeButton } from '../../components/button';
import Iconify from '../../components/Iconify';
import StatusButtons from '../../components/buttonGroup';
import { Types } from '../../types';

const StudiesToolbar = (props) => {
  const { selected, handleSelectionClick, filterName, onFilterName, searchLable, toolbarProps, handleClearSelection, setPage } =
    props;
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, handleReviewClicked } = toolbarProps;
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 0,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}
      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type="archive"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            variant="outlined"
            color="error"
            // onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete', handleClearSelection)}
          >
            {`Delete ${numSelected > 1 ? 'all' : ''}`}
          </ActionButton>
          <ActionButton
            type=""
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            variant="outlined"
            // color="error"
            onClick={() => handleReviewClicked && handleReviewClicked(selected, handleClearSelection)}
          >
            Mark as Reviewed
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
          <Grid item lg={4} xl={3} md={4} xs={12}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{
                  fontSize: '0.86rem',
                  borderRadius: 25,
                  lineHeight: '1.9375em',
                  '.MuiInputBase-input': {
                    height: '0.860em',
                    py: '15px',
                  },
                }}
                fullWidth
                value={filterName}
                onChange={onFilterName}
                placeholder={searchLable || placeholder || 'Search Studies'}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={5} lg={5}>
            <Stack
              gap={3}
              width="100%"
              sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
              direction="row"
              alignItems="center"
            >
              {/* <ThemeButton size="small" startIcon={<DownloadIcon />} variant="outlined">
                Download Studies
              </ThemeButton>
              <ThemeButton size="small" startIcon={<DownloadIcon />} variant="outlined">
                Download Photos
              </ThemeButton> */}
            </Stack>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

StudiesToolbar.propTypes = {
  selected: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
};

export default StudiesToolbar;
