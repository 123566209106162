import { Alert, Box, Link, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { ReactComponent as RetimeSvg } from '../../logo/retime_logo.svg';
import OtpInput from './OtpInput';
import Timer from './Timer';
import { otpVerification, renewOtp } from './loginSlice';

// eslint-disable-next-line arrow-body-style
const OtpVerification = ({ form, setUserToken }) => {
  const { getValues } = form;
  const length = 6;
  const initialOtp = Array(length).fill('');
  const [otp, setOtp] = useState(initialOtp);
  const [time, setTime] = useState(120);

  const dispatch = useDispatch();
  const { isOtpLoading, otpError, otpErrorMessage, otpSuccess } = useSelector((state) => state.login);
  const handleOtpComplete = () => {
    const isEmpty = otp.includes('');
    const stringOtp = parseInt(otp.join(''), 10);
    if (isEmpty) {
      // alert
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Please fill all fields',
        })
      );
    } else {
      const email = getValues('email');
      if (email) {
        dispatch(otpVerification({ email, OTP: stringOtp })).then((response) => {
          if (response?.payload?.success && response?.payload?.token) {
            const userToken = response.payload?.token;
            setUserToken(userToken);
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message', 'Please fill all fields'),
              })
            );
          }
        });
        // otp API call
      }
    }
  };
  const handleResentOtp = async () => {
    const email = getValues('email');
    dispatch(renewOtp({ email }))
      .then((response) => {
        if (response.payload.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `OTP sent to ${email}`,
            })
          );
          setOtp(initialOtp);
          setTime(120);
        }
      })
      .then((error) => console.log('error=>', error));
  };
  return (
    <Box
      sx={{
        boxShadow: 3,
        borderRadius: 2,
        px: 4,
        py: 2,
        // marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 'auto',
        maxWidth: 446,
        bgcolor: '#fff',
        mx: 'auto',
      }}
    >
      <Box
        sx={{
          px: 1,
          p: 1,
          // borderRight: '1px solid #ececec',
          // pl: 0,
          // minHeight: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <img src={logo} alt="logo" className="logo otp-logo" /> */}
        <RetimeSvg style={{ width: '99px', height: '72px' }} />
      </Box>
      <Typography
        variant="h5"
        sx={{
          color: '#3c4555',
          fontWeight: '700',
          mt: 2,
        }}
      >
        OTP Verification
      </Typography>
      <Typography
        variant="label"
        sx={{
          color: '#3c4555',
          my: 2,
          textAlign: 'center',
        }}
      >
        We sent a verification code to the email address you entered
      </Typography>
      {/* <Input sx={{ mt: 2 }} placeholder="Enter OTP" /> */}
      <OtpInput setOtp={setOtp} otp={otp} length={length} onComplete={handleOtpComplete} />
      <Timer time={time} setTime={setTime} sx={{ mt: 1 }} />
      {time <= 0 && (
        <Stack my={2} gap alignContent="center" justifyContent="center" direction="row">
          <Typography>Didn't receive OTP?</Typography>
          <Link disabled={isOtpLoading} component="button" onClick={handleResentOtp} sx={{ cursor: 'pointer' }} underline="false">
            Send OTP
          </Link>
        </Stack>
      )}
      {/* {otpError && (
        <Alert sx={{ my: 1, width: '100%', justifyContent: 'center' }} icon={false} color="error">
          {otpErrorMessage}
        </Alert>
      )} */}
      <ThemeButton loading={isOtpLoading} onClick={handleOtpComplete} variant="contained">
        SUBMIT
      </ThemeButton>
    </Box>
  );
};

export default OtpVerification;
OtpVerification.propTypes = {
  form: PropTypes.any,
  setUserToken: PropTypes.func,
};
