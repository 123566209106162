import { Container, IconButton, InputAdornment, Stack } from '@mui/material';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable react-hooks/rules-of-hooks */
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import { Alert } from '@mui/lab';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link as ReactRouterLink } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { ThemeButton } from '../../components/button';
import FormInput from '../../components/formComponents/FormInput';
import { ReactComponent as RetimeSvg } from '../../logo/retime_logo.svg';
import { loginSchema } from '../../utils/schema';
import { userLogin } from './loginSlice';
import OtpVerification from './otpVerification';
import { APP_ROUTER_BASE_URL } from '../../utils';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { get } from 'lodash';

const LoginPage = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');

  const [showPassword, setShowPassword] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });
  const {
    register,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
    getValues,
    handleSubmit,
  } = form;
  const { isLoading, errorMessage } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const userToken = jwtToken && jwtDecode(jwtToken);
    if (userToken && userToken?.role) {
      if (userToken?.role === 1 || userToken?.role === 2 || userToken?.role === 3) {
        navigate('/'); // add role wise navigation
      }
    }
  }, [jwtToken, navigate]);
  const setUserToken = (userToken) => {
    if (userToken) {
      cookies.set('token', userToken, { path: '/', maxAge: 31536000 }); // change max age
    }
    // const jwtToken = cookies.get('token');
    const token = userToken && jwtDecode(userToken);
    // const role = String(token?.role);
    const role = token?.role;
    if (role) {
      // localStorage.setItem('role', role);
      navigate('/');
    }
  };
  const onSubmit = (data) => {
    if (data) {
      dispatch(userLogin(data))
        .then((response) => {
          if (response?.payload.success) {
            if (!response.payload?.token) {
              setShowOtpScreen(true);
            } else {
              const userToken = response.payload?.token;
              setUserToken(userToken);
            }
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message', 'Something went wrong'),
              })
            );
          }
          return '';
        })
        .catch((error) => console.log('error', error));
    }
  };

  return (
    <Container maxWidth="xxl" sx={{ bgcolor: '#e5e5e5' }}>
      {showOtpScreen ? (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex' }}>
          <OtpVerification setUserToken={setUserToken} form={form} />
        </Box>
      ) : (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex' }}>
          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 2,
              // marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              my: 'auto',
              maxWidth: 446,
              bgcolor: '#fff',
              mx: 'auto',
            }}
          >
            <RetimeSvg style={{ width: '99px', height: '72px', marginBottom: '24px' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <Typography component="h1" variant="h5">
                Sign in
              </Typography> */}
              <Box noValidate sx={{ mt: 1 }}>
                <FormInput
                  label="Email Address"
                  placeholder="Enter Your Email"
                  InputProps={{ ...register('email') }}
                  error={!!errors?.email}
                  helperText={errors?.email && errors?.email?.message}
                />
                <FormInput
                  label="Password"
                  placeholder="Enter Your Password"
                  InputProps={{
                    ...register('password'),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          <Icon icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors?.password}
                  helperText={errors?.password && errors?.password?.message}
                />
                {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                {/* {errorMessage && (
                  <Alert icon={false} color="error">
                    {errorMessage}
                  </Alert>
                )} */}
                <Stack direction="row-reverse" alignItems="center" justifyContent="space-between">
                  <ThemeButton loading={isLoading} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Login
                  </ThemeButton>
                  <Link component={ReactRouterLink} to={`${APP_ROUTER_BASE_URL}reset-password`} variant="body2">
                    Forgot password?
                  </Link>
                </Stack>
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default LoginPage;
