import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Stack, Typography } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { ThemeButton } from '../../../components/button';
import ThemeCheckbox from '../../../components/checkbox/themeChekbox';
import FormInput from '../../../components/formComponents/FormInput';

const FilterModal = ({ filterOptions, closeFilter, filterConfig, setFilterConfig, filterBy, sortBy }) => {
  const [filterValues, setFilterValues] = useState([]);
  const [searchKeys, setSearchKeys] = useState('');
  const [sortOrder, setSortOrder] = React.useState('');
  const handleOptionChange = (event, value) => {
    if (filterValues.includes(value)) {
      const newValues = filterValues.filter((option) => option !== value);
      setFilterValues(newValues);
    } else {
      setFilterValues((prevValues) => [...prevValues, value]);
    }
  };
  const filteredOptions = useMemo(
    () =>
      filterOptions?.length >= 0
        ? filterOptions?.filter((filterOption) =>
            filterOption?.label?.toLocaleLowerCase().includes(searchKeys.toLocaleLowerCase())
          )
        : [],
    [filterOptions, searchKeys]
  );

  useEffect(() => {
    if (filterConfig?.filterColumns?.length > 0 && filterConfig?.filterBy === filterBy) {
      setFilterValues(filterConfig?.filterColumns);
    }
    if (filterConfig?.sortOrder && filterConfig?.sortBy === sortBy) {
      setSortOrder(filterConfig.sortOrder);
    }
  }, [filterBy, filterConfig, sortBy]);

  const handleFilter = () => {
    setFilterConfig({
      filterColumns: filterValues,
      filterBy,
      sortBy,
      sortOrder,
    });
    closeFilter();
  };
  const handleInputChange = (event) => setSearchKeys(() => event?.target.value);
  const clearFilters = () => {
    setFilterValues([]);
    setSortOrder('');
    setFilterConfig({
      filterColumns: [],
      filterBy,
      sortOrder: '',
      sortBy: '',
      isLabelNumeric: false,
    });
    closeFilter();
  };
  return (
    <>
      <Stack
        direction="column"
        sx={{ p: 2, maxHeight: '440px', overflowY: 'auto', '& .MuiCheckbox-root': { py: '2px !important' } }}
      >
        <FormControl sx={{ mb: 2 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Sort</FormLabel>
          <FormGroup row>
            <ThemeCheckbox
              InputProps={{ size: 'small' }}
              label={filterConfig?.isLabelNumeric ? 'low to high' : 'A to Z'}
              onChange={(event) => setSortOrder('asc')}
              checked={sortOrder === 'asc'}
            />
            <ThemeCheckbox
              InputProps={{ size: 'small' }}
              label={filterConfig?.isLabelNumeric ? 'high to low' : 'Z to A'}
              onChange={(event) => setSortOrder('desc')}
              checked={sortOrder === 'desc'}
            />
          </FormGroup>
        </FormControl>
        <FormInput
          // width="90%"
          height={36}
          label="Filter"
          placeholder="Search..."
          InputProps={{
            value: searchKeys,
            onChange: handleInputChange,
            // ...register('name'),
          }}
        />
        {filteredOptions?.map((option) => (
          <ThemeCheckbox
            InputProps={{ size: 'small' }}
            label={option?.label}
            onChange={(event) => handleOptionChange(event, option?.value)}
            checked={filterValues.includes(option?.value)}
          />
          // <span>{option.label}</span>
        ))}
        {searchKeys && filteredOptions?.length <= 0 && (
          <Typography variant="subtitle1" textAlign="center">
            No data found for <b>{searchKeys}</b>
          </Typography>
        )}
      </Stack>

      <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ThemeButton type="button" onClick={clearFilters}>
          Clear All
        </ThemeButton>
        <ThemeButton type="button" onClick={closeFilter} color="error" variant="outlined">
          Cancel
        </ThemeButton>
        <ThemeButton onClick={handleFilter} type="button" variant="contained">
          Apply
        </ThemeButton>
      </DialogActions>
    </>
  );
};

FilterModal.propTypes = {
  filterOptions: PropTypes.array,
  closeFilter: PropTypes.func,
  filterConfig: PropTypes.any,
  setFilterConfig: PropTypes.any,
  filterBy: PropTypes.string,
  sortBy: PropTypes.string,
};

export default FilterModal;
