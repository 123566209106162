import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  addGroupLoading: false,
  groups: [],
};
export const getGroups = createAsyncThunk('groups/getGroups', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'groups/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addGroup = createAsyncThunk('groups/addGroup', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'groups/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateGroup = createAsyncThunk('group/updateGroup', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `groups/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const areasSlice = createSlice({
  name: 'groups',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state, action) => {
      state.isLoading = true;
      state.groups = [];
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groups = action.payload?.data;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addGroup.pending, (state, action) => {
      state.addGroupLoading = true;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state.addGroupLoading = false;
    });
    builder.addCase(addGroup.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateGroup.pending, (state, action) => {
      state.addAreaLoading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateGroup.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
  },
});

export default areasSlice.reducer;
