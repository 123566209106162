import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { setSnackbar } from './snackbarSlice';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function CustomSnackbar() {
  // const [snackbarState, setSnackbarState] = React.useState({
  //   vertical: 'top',
  //   horizontal: 'center',
  // })
  const dispatch = useDispatch();
  const { snackbarOpen, snackbarType, snackbarMessage, snackbarPosition } = useSelector((state) => state.snackbar);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
    const snackbarData = {
      snackbarOpen: false,
      snackbarType,
      snackbarMessage,
    };
    dispatch(setSnackbar(snackbarData));
  };

  const { vertical, horizontal } = snackbarPosition;

  return (
    <Snackbar
      sx={{ zIndex: 99999 }}
      anchorOrigin={{ vertical, horizontal }}
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackbarType} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
