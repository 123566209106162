import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const RemindersNestedRow = ({ row }) => {
  const { elements: projectElements } = useSelector((state) => state.projectElements);
  const { tasks } = useSelector((state) => state.tasks);
  const isELement =
    get(row, 'projectSetting.triggerType') === 'SHOW_IN_TASK_ELEMENT' ||
    get(row, 'projectSetting.triggerType') === 'SHOW_ON_TASK_ELEMENTS';

  return isELement ? (
    <>
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        <TableCell padding="checkbox" />
        <TableCell colSpan={5} sx={{ verticalAlign: 'top' }}>
          <Typography sx={{ fontWeight: 600 }} variant="body2">
            Task
          </Typography>
        </TableCell>
        <TableCell colSpan={7} sx={{ verticalAlign: 'top' }}>
          <Typography sx={{ fontWeight: 600 }} variant="body2">
            Elements
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        <TableCell padding="checkbox" />
        <TableCell colSpan={5} sx={{ verticalAlign: 'top' }}>
          <Typography sx={{ fontWeight: 400 }} variant="subtitle2">
            {row?.task && !!tasks?.length && tasks.find((field) => field?._id === row?.task)?.name}
          </Typography>
        </TableCell>
        <TableCell colSpan={7} sx={{ verticalAlign: 'top' }}>
          <Typography sx={{ fontWeight: 400, maxWidth: 500 }} variant="subtitle2">
            {!!projectElements?.length &&
              row?.elements &&
              row.elements
                .filter((element) => element) // Filter out falsy values
                .map(
                  (element, index, array) =>
                    `${projectElements.find((field) => field?._id === element)?.name}${index !== array.length - 1 ? ', ' : ''}`
                )}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  ) : null;
};

RemindersNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
};

export default RemindersNestedRow;
