import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';

const initialState = {
  isLoading: false,
  addElementLoading: false,
  elements: [],
  isAssignedTaskLoading: false,
  assignedTasks: [],
};
export const getElements = createAsyncThunk('elements/getElements', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const getAssignedTasks = createAsyncThunk('elements/getTasks', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/get/tasks',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const addElement = createAsyncThunk('element/addElement', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'elements/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
// eslint-disable-next-line consistent-return
export const editElement = createAsyncThunk('element/editElement', async (data, { rejectWithValue }) => {
  if (data?._id) {
    try {
      const response = await axiosInstance({
        url: `elements/update/${data?._id}`,
        method: 'PATCH',
        data,
      });
      const responseData = await response.data;
      return responseData;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  } else return '';
});
export const projectElementSlice = createSlice({
  name: 'projectElements',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getElements.pending, (state, action) => {
      state.isLoading = true;
      state.elements = [];
    });
    builder.addCase(getElements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.elements = action.payload?.data;
    });
    builder.addCase(getElements.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getAssignedTasks.pending, (state, action) => {
      state.isAssignedTaskLoading = true;
      state.assignedTasks = [];
    });
    builder.addCase(getAssignedTasks.fulfilled, (state, action) => {
      state.isAssignedTaskLoading = false;
      state.assignedTasks = action.payload?.data;
    });
    builder.addCase(getAssignedTasks.rejected, (state, action) => {
      state.isAssignedTaskLoading = false;
    });
    builder.addCase(addElement.pending, (state, actions) => {
      state.addElementLoading = true;
    });
    builder.addCase(addElement.fulfilled, (state, action) => {
      state.addElementLoading = false;
    });
    builder.addCase(addElement.rejected, (state, action) => {
      state.addElementLoading = false;
    });
    builder.addCase(editElement.pending, (state, actions) => {
      state.addElementLoading = true;
    });
    builder.addCase(editElement.fulfilled, (state, action) => {
      state.addElementLoading = false;
    });
    builder.addCase(editElement.rejected, (state, action) => {
      state.addElementLoading = false;
    });
  },
});

export default projectElementSlice.reducer;
