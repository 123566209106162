import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Unauthorized = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: 'primary.light',
    }}
  >
    <Typography textTransform="uppercase" variant="h2" style={{ color: 'white' }}>
      Unauthorized
    </Typography>
    <Typography variant="h6" style={{ color: 'white' }}>
      You do not have access to view this page.
    </Typography>
    <Link to="/">
      <Button sx={{ mt: 2 }} color="warning" variant="contained">
        Back Home
      </Button>
    </Link>
  </Box>
);

export default Unauthorized;
