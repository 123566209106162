import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Iconify from '../../components/Iconify';
import { ActionButton, ThemeButton } from '../../components/button';

const HoursMappingToolbar = (props) => {
  const { selected, filterName, onFilterName, searchLable, toolbarProps, handleClearSelection } = props;
  const numSelected = selected?.length;
  const { placeholder } = toolbarProps;

  const handleMassEdit = () => {
    console.log('rows-->', selected);
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 0,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}
      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            onClick={handleMassEdit}
          >
            Mass Edit
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={1} container gap>
          <Grid item xs={12} md={4} lg={2.5}>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{
                  fontSize: '0.86rem',
                  borderRadius: 25,
                  mt: '5px',
                  mb: '16px',
                  lineHeight: '1.9375em',
                  '.MuiInputBase-input': {
                    height: '0.860em',
                    py: '15px',
                  },
                }}
                fullWidth
                value={filterName}
                onChange={onFilterName}
                placeholder={searchLable || placeholder || 'Search customer name...'}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

HoursMappingToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
};

export default HoursMappingToolbar;
